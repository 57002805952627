import React from 'react';
import { SvgWrapper, SvgWrapperProps } from 'components/customAntd/inVision - Platinum Assets/svgs/SvgWrapper';

interface DevicesOutlinedSvgProps extends Omit<SvgWrapperProps, 'children'> { }

export const DevicesOutlinedSvg = ({
  ...svgProps
}: DevicesOutlinedSvgProps) => (
  <SvgWrapper viewBox="0 0 18 18" {...svgProps}>
    <path d="M13.5 0H7.5C6.29 0 5.28 0.86 5.05 2H4.5C3.12 2 2 3.12 2 4.5V15.5C2 16.88 3.12 18 4.5 18H10.5C11.71 18 12.72 17.14 12.95 16H13.5C14.88 16 16 14.88 16 13.5V2.5C16 1.12 14.88 0 13.5 0ZM10.5 17H4.5C3.67 17 3 16.33 3 15.5V4.5C3 3.67 3.67 3 4.5 3H5V13.5C5 14.88 6.12 16 7.5 16H11.91C11.7 16.58 11.15 17 10.5 17ZM15 13.5C15 14.33 14.33 15 13.5 15H7.5C6.67 15 6 14.33 6 13.5V2.5C6 1.67 6.67 1 7.5 1H13.5C14.33 1 15 1.67 15 2.5V13.5Z" />
    <path d="M10.5 3C9.12 3 8 4.12 8 5.5C8 6.88 9.12 8 10.5 8C11.88 8 13 6.88 13 5.5C13 4.12 11.88 3 10.5 3ZM10.5 7C9.67 7 9 6.33 9 5.5C9 4.67 9.67 4 10.5 4C11.33 4 12 4.67 12 5.5C12 6.33 11.33 7 10.5 7Z" />
    <path d="M12.5 11H8.5C8.22 11 8 11.22 8 11.5C8 11.78 8.22 12 8.5 12H12.5C12.78 12 13 11.78 13 11.5C13 11.22 12.78 11 12.5 11Z" />
  </SvgWrapper>
);
