import React from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

interface FullLoaderProps {
    text?: string;
}

const FullLoader: React.FC<FullLoaderProps> = ({ text = 'Loading Visitor...' }) => {
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                width: '100%',
                overflow: 'hidden'
            }}
        >
            <Spin indicator={<LoadingOutlined style={{ fontSize: 36 }} spin />} />
            <div style={{ marginTop: 16, fontSize: 16 }}>
                <strong>{text}</strong>
            </div>
        </div>
    );
};

export default FullLoader;
