import React, { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Select, Empty } from "antd";
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import { HourglassOutlined, LoginOutlined, LogoutOutlined, StopOutlined } from "@ant-design/icons";
import { OpPage } from 'components/customAntd/OpPage/OpPage';
import { OpRow } from 'components/customAntd/DLS/OpRow/OpRow';
import { OpCol } from 'components/customAntd/DLS/OpCol/OpCol';
import { OpCard } from 'components/customAntd/DLS/OpCard/OpCard';
import { OpSpace } from 'components/customAntd/DLS/OpSpace/OpSpace';
import { RootState, AppDispatch } from "store/store";
import { setSelectedVisit, setSelectedVisitor } from 'store/slices/visitsSlice';
import DashboardCard from './DashboardCard';
import VisitorsTrendChart from './VisitorsTrendChart';
import { describeLocation, describeLocationWorkflow, fetchLocations } from 'store/slices/locationsSlice';
import STATUS from 'constants/status';
import { DASHBOARD_TOOLTIP } from 'constants/tooltip';
import { formatFullName, hasPermission } from 'utils/utils';
import CardInfoModal from './CardInfoModal';
import VisitorsDrawer from '../visitors/drawer/VisitorsDrawer';
import { getStatusNameById } from 'utils/visitorsHelper';
import { getRequest } from 'api/apiClient';
import { DATE_TIME_FORMAT } from 'constants/dates';
import { searchVisitorsToday } from 'store/slices/visitorSearchSlice';
import { VisitorSearch } from 'types/visitorSearchTypes';
import { Visitor } from 'types/visitTypes';
import FullLoader from 'components/customAntd/FullLoader';
dayjs.extend(isBetween);

interface ModalContent {
    name: string;
    firstName?: string;
    lastName?: string;
    dateTime: string;
    statusName: string;
    visitId: number;
    visitorId: number;
}


const Dashboard: React.FC = () => {
    const dispatch: AppDispatch = useDispatch();
    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);
    const globalUserId = useSelector((state: RootState) => state.users.globalUser?.id);
    const globalLocationId = useSelector((state: RootState) => state.locations.globalLocation?.id);

    const { locations, globalLocation, fetchLocationsLoading, describeLocationLoading } = useSelector((state: RootState) => state.locations);
    const { visitorsToday } = useSelector((state: RootState) => state.visitorSearch);

    const [chartData, setChartData] = useState<VisitorSearch[]>([]);

    const [isProfileDrawerOpen, setIsProfileDrawerOpen] = useState(false);

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [modalContent, setModalContent] = useState<ModalContent[]>([]);
    const [modalTitle, setModalTitle] = useState('');

    const [isVisitorsDrawerOpen, setIsVisitorsDrawerOpen] = useState<boolean>(false);
    const [loading, setLoading] = useState(false);
    const [hasLoadedOnce, setHasLoadedOnce] = useState(false);

    const tokenScopeList = useSelector((state: RootState) => state.auth.auth.data[0]?.tokenScopeList || []);
    const hasDashRead = hasPermission(tokenScopeList, orgId, 'o', 'dash:r');
    const hasAllvisitorsRead = hasPermission(tokenScopeList, orgId, 'o', 'allvisitors:r');

    const isInitialLoading =
        fetchLocationsLoading ||
        describeLocationLoading ||
        loading ||
        !globalLocation ||
        locations.length === 0 ||
        !visitorsToday.data;

    useEffect(() => {
        if (
            !fetchLocationsLoading &&
            !describeLocationLoading &&
            !loading &&
            globalLocation &&
            locations.length > 0 &&
            visitorsToday.data
        ) {
            setHasLoadedOnce(true);
        }
    }, [fetchLocationsLoading, describeLocationLoading, loading, globalLocation, locations.length, visitorsToday.data])

    // Use memoized derived data
    const filteredVisitors = useMemo(() => {
        if (!visitorsToday.data) return [];
        return visitorsToday.data.filter(visit =>
            hasAllvisitorsRead || globalUserId === visit.hostUserId
        );
    }, [visitorsToday.data, hasAllvisitorsRead, globalUserId]);

    const visitorsComingToday = useMemo(() =>
        filteredVisitors.filter(v => v.status === STATUS.PENDING.id),
        [filteredVisitors]
    );
    const visitorsSignedInToday = useMemo(() =>
        filteredVisitors.filter(v => v.status === STATUS.SIGNED_IN.id),
        [filteredVisitors]
    );
    const visitorsSignedOutToday = useMemo(() =>
        filteredVisitors.filter(v => v.status === STATUS.SIGNED_OUT.id),
        [filteredVisitors]
    );
    const visitorsDeniedEntryToday = useMemo(() =>
        filteredVisitors.filter(v => v.status === STATUS.DENIED_ENTRY.id),
        [filteredVisitors]
    );

    useEffect(() => {
        const fetchData = async () => {
            if (orgId) {
                setLoading(true);
                try {
                    await dispatch(searchVisitorsToday({ orgId }));

                    if (orgId && locations.length === 0) {
                        dispatch(fetchLocations({ orgId, status: 1 }));
                    }
                    // Fetch chart data only when location changes
                    if (globalLocationId) {
                        const visitsLast7Days = await getRequest(`/orgs/${orgId}/search/visitors`, {
                            siteId: globalLocationId,
                            startDate: dayjs().subtract(6, 'days').startOf('day').format(DATE_TIME_FORMAT),
                            endDate: dayjs().endOf('day').format(DATE_TIME_FORMAT),
                        });
                        const filteredVisitors = visitsLast7Days.data.filter((visitor: VisitorSearch) =>
                            (visitor.status === STATUS.SIGNED_IN.id || visitor.status === STATUS.SIGNED_OUT.id)
                        );
                        setChartData(filteredVisitors);
                    }
                } catch (error) {
                    console.error("Failed to fetch data:", error);
                } finally {
                    setLoading(false);
                }
            }
        };
        fetchData();
    }, [orgId, dispatch, globalLocationId, locations.length]);

    const handleLocationChange = async (locationId: number) => {
        setLoading(true);
        try {
            await dispatch(describeLocation({ orgId, locationId, global: true }));
            await dispatch(describeLocationWorkflow({ orgId, locationId, global: true }));
            if (globalLocation?.id) {
                await dispatch(searchVisitorsToday({ orgId }));
            }
        } catch {
            console.log("Failed to fetch data.")
        } finally {
            setLoading(false);
        }
    };

    const handleClickedVisitor = async (visitId: number, visitorId: number) => {
        const selectedVisit = await getRequest(`/orgs/${orgId}/visitor/${visitId}`);
        const selectedVisitor = selectedVisit.data[0].visitors.find((v: Visitor) => Number(v.id) === Number(visitorId));

        dispatch(setSelectedVisit(selectedVisit.data[0]));
        dispatch(setSelectedVisitor(selectedVisitor));
        setIsVisitorsDrawerOpen(true);
        setIsProfileDrawerOpen(true);
        setIsModalVisible(false);
        setModalContent([]);
    }

    const handleCardClick = (visitors: VisitorSearch[], title: string) => {
        if (visitors.length === 0) {
            return;
        }

        const formattedVisitors = visitors.map((visitor) => ({
            name: formatFullName(visitor.firstName, visitor.middleName, visitor.lastName),
            firstName: visitor.firstName || undefined,
            lastName: visitor.lastName || undefined,
            dateTime: visitor.timestamp ? dayjs(visitor.timestamp).format('MMMM D, YYYY h:mm A') : 'N/A',
            statusName: getStatusNameById(visitor.status, visitor.timestamp),
            visitId: visitor.visitId,
            visitorId: visitor.id
        }));

        const sortedVisitors = formattedVisitors.sort((a, b) =>
            dayjs(b.dateTime).valueOf() - dayjs(a.dateTime).valueOf()
        );

        setModalContent(sortedVisitors);
        setModalTitle(title);
        setIsModalVisible(true);
    };

    const handleDrawerClose = () => {
        setIsVisitorsDrawerOpen(false);
        dispatch(setSelectedVisit(null));
    };

    if (isInitialLoading && !hasLoadedOnce) {
        return <FullLoader text="Loading Dashboard..." />;
    }

    return (
        <OpPage title="Dashboard" tooltip={DASHBOARD_TOOLTIP} contentRight={
            hasDashRead && (
                <Select
                    placeholder="Select Location"
                    onChange={handleLocationChange}
                    style={{
                        width: '100%',
                        minWidth: '200px'
                    }}
                    loading={fetchLocationsLoading || describeLocationLoading || loading}
                    options={
                        locations.length > 1
                            ? [
                                { label: "All Locations", value: -1 },
                                ...locations.map(location => ({
                                    label: location.name,
                                    value: location.id,
                                })),
                            ]
                            : locations.map(location => ({
                                label: location.name,
                                value: location.id,
                            }))
                    }
                    // Only set defaultValue once locations and globalLocation are loaded
                    defaultValue={
                        !fetchLocationsLoading && !describeLocationLoading && globalLocation
                            ? globalLocation.id
                            : undefined
                    }
                />
            )
        }>
            {loading ? (
                <FullLoader text="Loading Dashboard..." />
            ) : (
                <>
                    {hasDashRead ? (
                        <OpSpace
                            direction="vertical"
                            size="middle"
                            style={{ display: 'flex' }}
                        >
                            <OpCard type="inner" title="Visitors Today" extra={dayjs().format('dddd, M/D')}>

                                <OpRow gutter={[16, 16]} justify="start">
                                    <OpCol
                                        xs={24}
                                        sm={24}
                                        md={12}
                                        lg={6}
                                        style={{ padding: '8px' }}
                                    >
                                        <DashboardCard
                                            icon={
                                                <HourglassOutlined
                                                    style={{
                                                        color: "white",
                                                        backgroundColor: "rgba(19,151,213,1.0)",
                                                        borderRadius: 20,
                                                        fontSize: 24,
                                                        padding: 8,
                                                    }}
                                                />
                                            }
                                            title="COMING"
                                            value={visitorsComingToday.length}
                                            color="rgba(19,151,213,0.1)"
                                            onClick={() => handleCardClick(visitorsComingToday, 'Visitors Coming Today')}
                                        />
                                    </OpCol>
                                    <OpCol
                                        xs={24}
                                        sm={24}
                                        md={12}
                                        lg={6}
                                        style={{ padding: '8px' }}
                                    >
                                        <DashboardCard
                                            icon={
                                                <LoginOutlined
                                                    style={{
                                                        color: "white",
                                                        backgroundColor: "rgba(109,152,138,1.0)",
                                                        borderRadius: 20,
                                                        fontSize: 24,
                                                        padding: 8,
                                                    }}
                                                />
                                            }
                                            title="HERE"
                                            value={visitorsSignedInToday.length}
                                            color="rgba(109,152,138,0.1)"
                                            onClick={() => handleCardClick(visitorsSignedInToday, 'Visitors Here Today')}
                                        />
                                    </OpCol>
                                    <OpCol
                                        xs={24}
                                        sm={24}
                                        md={12}
                                        lg={6}
                                        style={{ padding: '8px' }}
                                    >
                                        <DashboardCard
                                            icon={
                                                <LogoutOutlined
                                                    style={{
                                                        color: "white",
                                                        backgroundColor: "rgba(155,154,154,1.0)",
                                                        borderRadius: 20,
                                                        fontSize: 24,
                                                        padding: 8,
                                                    }}
                                                />
                                            }
                                            title="LEFT"
                                            value={visitorsSignedOutToday.length}
                                            color="rgba(155,154,154,0.1)"
                                            onClick={() => handleCardClick(visitorsSignedOutToday, 'Visitors Left Today')}
                                        />
                                    </OpCol>
                                    <OpCol
                                        xs={24}
                                        sm={24}
                                        md={12}
                                        lg={6}
                                        style={{ padding: '8px' }}
                                    >
                                        <DashboardCard
                                            icon={
                                                <StopOutlined
                                                    style={{
                                                        color: "white",
                                                        backgroundColor: "rgba(242,103,87,1.0)",
                                                        borderRadius: 20,
                                                        fontSize: 24,
                                                        padding: 8,
                                                    }}
                                                />
                                            }
                                            title="DENIED"
                                            value={visitorsDeniedEntryToday.length}
                                            color="rgba(242,103,87,0.1)"
                                            onClick={() => handleCardClick(visitorsDeniedEntryToday, 'Visitors Denied Entry Today')}
                                        />
                                    </OpCol>
                                </OpRow>

                            </OpCard>
                            <OpCard type="inner" title="Visitors Trend" extra={`${dayjs().subtract(6, 'day').format('M/D')} - ${dayjs().format('M/D')}`}>

                                <VisitorsTrendChart visitors={chartData} />

                            </OpCard>
                        </OpSpace>
                    ) : (
                        <OpRow justify="center" align="middle" style={{ height: '100%' }}>
                            <OpCol>
                                <Empty description={
                                    <p>Additional permissions required to access this page.</p>
                                } />
                            </OpCol>
                        </OpRow>
                    )}
                </>
            )}
            {isModalVisible && (
                <CardInfoModal
                    visible={isModalVisible}
                    title={modalTitle}
                    content={modalContent}
                    onCancel={() => { setIsModalVisible(false) }}
                    onItemClick={handleClickedVisitor}
                />
            )}

            {(isVisitorsDrawerOpen) && (
                <VisitorsDrawer
                    open={isVisitorsDrawerOpen}
                    isProfileDrawerOpen={isProfileDrawerOpen}
                    onClose={handleDrawerClose}
                    setIsProfileDrawerOpen={setIsProfileDrawerOpen}
                />
            )}
        </OpPage>
    );
}

export default Dashboard;
