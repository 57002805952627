import React, { useEffect, useState } from 'react';
import { Layout, Input, Checkbox, Modal, Spin, Form, Button, Upload, message } from 'antd';
import type { UploadProps } from 'antd';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import { OpDivider } from 'components/customAntd/DLS/OpDivider/OpDivider';
import { FileOutlined, UploadOutlined } from '@ant-design/icons';
import './Register.scss';
import STATUS from 'constants/status';
import dayjs from 'dayjs';

const { Content } = Layout;

const apiUrl = process.env.REACT_APP_BACKEND_URL;

const Register: React.FC = () => {
    const [selectedFile, setSelectedFile] = useState<File | null>(null); // Manage a single file
    const [showForm, setShowForm] = useState(false);
    const [isValid, setIsValid] = useState<boolean | null>(null);
    const [fieldsConfig, setFieldsConfig] = useState<any>(null);
    const [invitationFields, setInvitationFields] = useState<any>(null);
    const [agreements, setAgreements] = useState<any[]>([]);
    const [initialValues, setInitialValues] = useState<any>({});
    const [isLoading, setIsLoading] = useState(true);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [modalContent, setModalContent] = useState('');
    const [agreementError, setAgreementError] = useState<string | null>(null);
    const [showErrorMessage, setShowErrorMessage] = useState<string>('');

    const location = useLocation(); 
    const navigate = useNavigate();
    const [form] = Form.useForm();

    useEffect(() => {
        const token = location.search.substring(1);

        if (token) {
            axios.post(`${apiUrl}/visitors/accessTokens/${token}/validate`)
                .then(response => {
                    const { isValid } = response.data.data;
                    if (!isValid) {
                        setIsValid(false);
                        setIsLoading(false);
                        return;
                    }

                    axios.get(`${apiUrl}/visitors?token=${token}`)
                        .then(response => {
                            const visitorData = response.data.data[0];
                            const visitor = visitorData.visitors[0];

                            if (!visitor) {
                                setIsValid(false);
                                setIsLoading(false);
                                return;
                            }

                            setIsValid(true);

                            const scheduleIn = dayjs(visitorData.scheduleStart);
                            const scheduleOut = dayjs(visitorData.scheduleEnd);
                            const now = dayjs();

                            if (visitor.status === STATUS.PENDING.id) {
                                if (now.isBefore(scheduleOut.add(30, 'minutes'))) {
                                    setShowForm(true);
                                } else {
                                    setIsValid(false);
                                }
                            } else if (visitor.status === STATUS.SIGNED_IN.id || visitor.status === STATUS.SIGNED_OUT.id) {
                                if (now.isBetween(scheduleIn.subtract(30, 'minutes'), scheduleOut.add(30, 'minutes'))) {
                                    navigate('/register/completed', {
                                        replace: true,
                                        state: { token }
                                    });
                                } else {
                                    setIsValid(false);
                                }
                            } else if (visitor.status === STATUS.DENIED.id) {
                                setIsValid(false);
                            }

                            if ([STATUS.PENDING.id, STATUS.SIGNED_IN.id, STATUS.SIGNED_OUT.id].includes(visitor.status)) {
                                const scheduleIn = dayjs(visitorData.scheduleStart);
                                const scheduleOut = dayjs(visitorData.scheduleEnd);
                                const now = dayjs();

                                const isPendingAllowed = visitor.status === STATUS.PENDING.id && now.isBefore(scheduleOut.add(30, 'minutes'));
                                const isSignedAllowed =
                                    (visitor.status === STATUS.SIGNED_IN.id || visitor.status === STATUS.SIGNED_OUT.id) &&
                                    now.isBetween(scheduleIn.subtract(30, 'minutes'), scheduleOut.add(30, 'minutes'));

                                if (!isPendingAllowed && !isSignedAllowed) {
                                    setShowErrorMessage("Pre-registration cannot be completed at this time.");
                                    setIsLoading(false);
                                    return;
                                }
                            }

                            setInitialValues({
                                firstName: visitor.firstName,
                                middleName: visitor.middleName !== "NONE" ? visitor.middleName : "",
                                lastName: visitor.lastName,
                                email: visitor.email,
                                visitPhone: visitor.mobilePhone,
                                visitCompany: visitorData.company,
                                purpose: visitorData.purpose,
                            });

                            setIsLoading(false);
                        })
                        .catch(error => {
                            console.error('Error fetching visitor details:', error);
                            setIsLoading(false);
                        });

                    axios.get(`${apiUrl}/visitors/workflowInFields?token=${token}`)
                        .then(response => {
                            const fieldConfig = response.data.data[0];
                            setFieldsConfig(fieldConfig);
                        })
                        .catch(error => {
                            console.error('Error fetching workflow fields:', error);
                        });

                    axios.get(`${apiUrl}/visitors/agreements?token=${token}`)
                        .then(response => {
                            setAgreements(response.data.data);
                        })
                        .catch(error => {
                            console.error('Error fetching agreements:', error);
                        });

                    axios.get(`${apiUrl}/visitors/invitationFields?token=${token}`)
                        .then(response => {
                            setInvitationFields(response.data.data[0]);
                        })
                        .catch(error => {
                            console.error('Error fetching invitation fields:', error);
                        });

                })
                .catch(error => {
                    console.error('Token validation error:', error);
                    setIsValid(false);
                    setIsLoading(false);
                });
        } else {
            setIsValid(false);
            setIsLoading(false);
        }
    }, [navigate, location.search]);

    const resizeImage = (file: File, maxSize: number, quality: number): Promise<File | null> => {
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = (event) => {
                const img = new Image();
                img.src = event.target!.result as string;
                img.onload = () => {
                    const canvas = document.createElement('canvas');
                    const ctx = canvas.getContext('2d')!;

                    let width = img.width;
                    let height = img.height;

                    if (width > height) {
                        if (width > maxSize) {
                            height *= maxSize / width;
                            width = maxSize;
                        }
                    } else {
                        if (height > maxSize) {
                            width *= maxSize / height;
                            height = maxSize;
                        }
                    }

                    canvas.width = width;
                    canvas.height = height;
                    ctx.drawImage(img, 0, 0, width, height);

                    canvas.toBlob(
                        (blob) => {
                            if (!blob) {
                                resolve(null);
                                return;
                            }
                            resolve(new File([blob], file.name, { type: 'image/jpeg', lastModified: Date.now() }));
                        },
                        'image/jpeg',
                        quality
                    );
                };
            };
        });
    };

    const handleFileChange: UploadProps['onChange'] = async (info) => {
        if (info.file.status === "removed") {
            setSelectedFile(null);
            form.setFieldsValue({ photo: null });
            return;
        }

        if (info.fileList[0]) {
            const validFile = await validateFile(info.fileList[0].originFileObj!);
            if (validFile) {
                setSelectedFile(validFile);
                message.success(`${validFile.name} file selected successfully`);
            }
        }
    };

    const onFinish = async () => {
        try {
            // Ensure the selected file is valid before submitting
            if (selectedFile && !validateFile(selectedFile)) {
                return; // Prevent submission
            }

            // Validate form fields
            const values = await form.validateFields();

            // Check for unchecked agreements
            const uncheckedAgreements = agreements.filter((_, index) => !values[`agreement_${index}`]);

            if (uncheckedAgreements.length > 0) {
                setAgreementError("Please agree to all the required agreements.");
                return;
            }

            setAgreementError(null);

            // Get the token from the URL
            const token = location.search.substring(1);

            // Prepare request body for updating visitor data
            const requestBody = {
                firstName: values.firstName || undefined,
                lastName: values.lastName || undefined,
                middleName: values.middleName || undefined,
                company: values.visitCompany || undefined,
                email: values.email || undefined,
                mobilePhone: values.visitPhone || undefined,
            };

            // Update visitor information
            await axios.patch(`${apiUrl}/visitors?token=${token}`, requestBody);

            // Upload the photo (if selected) after visitor info is updated
            if (selectedFile) {
                const formData = new FormData();
                formData.append('photoUploadFile', selectedFile); // Add the selected file

                await axios.post(`${apiUrl}/visitors/photo?token=${token}`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
            }

            // Update agreements synchronously
            for (const [index, agreement] of agreements.entries()) {
                const hasAgreed = values[`agreement_${index}`] ? "1" : "0";

                await axios.patch(`${apiUrl}/visitors/agreements?token=${token}`, {
                    documentId: agreement.id.toString(),
                    hasAgreed: hasAgreed,
                });
            }

            // Navigate to the completion page
            navigate('/register/completed', {
                replace: true,
                state: {
                    token
                }
            });
        } catch (error) {
            console.error('Error submitting form:', error);
        }
    };

    const handleAgreementClick = async (agreementId: number) => {
        const token = location.search.substring(1);
        try {
            const response = await axios.get(`${apiUrl}/visitors/documentcontent/${agreementId}?token=${token}`);
            const name = response.data.data[0].document.name;
            const content = response.data.data[0].content;
            setModalTitle(name);
            setModalContent(content);
            setIsModalVisible(true);
        } catch (error) {
            console.error('Error fetching agreement content:', error);
        }
    };

    const renderField = (name: string, label: string, required: boolean, disabled: boolean = false) => (
        <Form.Item
            label={label}
            name={name}
            rules={required ? [{ required: true, message: `Please input your ${label.toLowerCase()}.` }] : []}
        >
            <Input disabled={disabled} />
        </Form.Item>
    );


    const validateFile = async (file: File): Promise<File | null> => {
        if (!['image/png', 'image/jpeg'].includes(file.type)) {
            message.error('Invalid file type. Please upload a PNG or JPEG image.');
            return null;
        }

        if (file.size > 1048576) {
            return await resizeImage(file, 600, 0.7);
        }

        return file;
    };

    const beforeUpload = async (file: File) => {
        if (file.size > 10485760) { // 10MB = 10,485,760 bytes
            message.warning('File size exceeds 10MB. Please upload a smaller file.');
            return Upload.LIST_IGNORE;
        }

        const validFile = await validateFile(file);
        return validFile ? false : Upload.LIST_IGNORE;
    };

    return (
        <Layout className="register-layout">
            <Content className="register-content">
                <img src="/images/invisit_full.png" alt="Logo" className="logo" />
                {
                isLoading ? (
                    <div className="loading-container">
                        <Spin className="loading-message" />
                    </div>
                ) : showErrorMessage ? (
                    <div className="message-box">
                        <span>{showErrorMessage}</span>
                    </div>
                ) : isValid && showForm && fieldsConfig ? (
                    <Form
                        form={form}
                        name="exampleForm"
                        onFinish={onFinish}
                        layout="vertical"
                        initialValues={initialValues}
                    >

                        <div className="register-header">
                            <p>Pre-register for your visit for a quick onsite sign-in.</p>
                        </div>
                        <OpDivider />

                        {renderField("firstName", "First Name", true, true)}
                        {renderField("lastName", "Last Name", true, true)}

                        {fieldsConfig.middleName.included ? renderField("middleName", "Middle Name", fieldsConfig.middleName.required) : null}
                        {fieldsConfig.email.included ? renderField("email", "Email Address", fieldsConfig.email.required, true) : null}
                        {fieldsConfig.visitPhone.included ? renderField("visitPhone", "Phone", fieldsConfig.visitPhone.required) : null}
                        {fieldsConfig.visitCompany.included ? renderField("visitCompany", "Company", fieldsConfig.visitCompany.required) : null}


                        {invitationFields?.uploadPhoto?.included !== 0 && (
                            <Form.Item
                                label="Photo"
                                name="photo"
                                extra="File size cannot exceed 10MB. File format must be JPEG or PNG. Limit 1."
                                rules={[{ required: invitationFields?.uploadPhoto?.required, message: 'Photo is required.' }]}
                            >
                                <Upload
                                    listType="picture"
                                    maxCount={1}
                                    onChange={handleFileChange}
                                    beforeUpload={beforeUpload}
                                    showUploadList={{ showPreviewIcon: true }}
                                    previewFile={async (file) => {
                                        return URL.createObjectURL(file); // Create a local preview URL
                                    }}                                >
                                    <Button icon={<UploadOutlined />}>Click to Upload</Button>
                                </Upload>
                            </Form.Item>
                        )}

                        {agreements.length > 0 && (
                            <>
                                <OpDivider />
                                <p>Below are agreements required for your visits. Please check each agreement to agree.</p>
                                {agreements.map((agreement, index) => (
                                    <Form.Item
                                        name={`agreement_${index}`}
                                        key={index}
                                        valuePropName="checked"
                                        className="custom-checkbox-item"
                                        validateTrigger="onSubmit"
                                    >
                                        <Checkbox className="custom-checkbox">
                                            {agreement.name}
                                            <span
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    handleAgreementClick(agreement.id);
                                                }}
                                                className="agreement-link"
                                                style={{ cursor: 'pointer', color: '#1890ff', marginLeft: '8px' }}
                                            >
                                                <FileOutlined />
                                            </span>
                                        </Checkbox>
                                    </Form.Item>
                                ))}
                                {agreementError && (
                                    <div className="agreement-error-message">
                                        {agreementError}
                                    </div>
                                )}
                            </>
                        )}
                        <OpDivider />

                        <Form.Item>
                            <Button type="primary" htmlType="submit">
                                Save
                            </Button>
                        </Form.Item>
                    </Form>
                ) : (
                    <div className="message-box">
                        <span>Invalid or expired link.</span>
                    </div>
                )}
            </Content>

            <footer className="register-footer">
                &copy; {new Date().getFullYear()} Invisit, LLC
            </footer>

            <Modal
                title={modalTitle}
                open={isModalVisible}
                onCancel={() => setIsModalVisible(false)}
                footer={null}
                centered
            >
                <Input.TextArea
                    value={modalContent}
                    rows={20}
                    readOnly
                    style={{ resize: 'none' }}
                />
            </Modal>
        </Layout>
    );
};

export default Register;
