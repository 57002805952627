import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store/store';
import { OpFloatButton } from 'components/customAntd/DLS/OpFloatButton/OpFloatButton';
import SignOutModal from './signOut/SignOutModal';
import ScanIDModal from './signIn/ScanIDModal';
import SignInModal from './signIn/SignInModal';
import { hasPermission } from 'utils/utils';
import SignInManuallyDrawer from './signIn/SignInManuallyDrawer';
import VisitorsDrawer from 'components/pages/visitors/drawer/VisitorsDrawer';
import SignInMethodModal from './signIn/SignInMethodModal';
import InvitationDrawer from './invite/InvitationDrawer';
import ScanQRCodeModal from './signIn/ScanQRCodeModal';
import { getRequest } from 'api/apiClient';
import { setSelectedVisit } from 'store/slices/visitsSlice';
import { fetchInvitationConfig } from 'store/slices/visitorInvitationSlice';
import SelectLocationModal from './SelectLocationModal';
import { DATE_TIME_FORMAT } from 'constants/dates';
import dayjs from 'dayjs';
import { ReactComponent as VoltIcon } from 'images/icons/volt.svg';
import QuickActionsModal from 'components/layout/appHeader/quickActions/QuickActionsModal';
import { VisitorSearch } from 'types/visitorSearchTypes';

type Actions = 'SIGN_IN' | 'SIGN_OUT' | 'NEW_INVITATION';

const ActionFloatButtons: React.FC = () => {
    const dispatch: AppDispatch = useDispatch();
    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);
    const tokenScopeList = useSelector((state: RootState) => state.auth.auth.data[0]?.tokenScopeList || []);
    const { invitationConfig } = useSelector((state: RootState) => state.visitorInvitation);

    const hasInviteWrite = hasPermission(tokenScopeList, orgId, 'o', 'invite:w');
    const hasSigninWrite = hasPermission(tokenScopeList, orgId, 'o', 'signin:w');
    const hasSignoutWrite = hasPermission(tokenScopeList, orgId, 'o', 'signout:w');

    const locationIdRef = useRef<number | null>(null);
    const [isSelectLocationModalOpen, setIsSelectLocationModalOpen] = useState<boolean>(false);
    const [pendingAction, setPendingAction] = useState<Actions | null>(null);

    const [visitorsToday, setVisitorsToday] = useState<VisitorSearch[]>([]);
    const [visitorNameFilter, setVisitorNameFilter] = useState<{ firstName: string; middleName: string; lastName: string } | undefined>(undefined);
    const [newVisitorName, setNewVisitorName] = useState<{ firstName: string; middleName: string; lastName: string; dob?: string } | undefined>(undefined);
    const [loading, setLoading] = useState<boolean>(false);
    const [isQuickActionsModalOpen, setIsQuickActionsModalOpen] = useState<boolean>(false);

    // sign in
    const [isSignInMethodModalOpen, setIsSignInMethodModalOpen] = useState<boolean>(false);
    const [isSignInModalOpen, setIsSignInModalOpen] = useState<boolean>(false);
    const [isSignInManuallyDrawerOpen, setIsSignInManuallyDrawerOpen] = useState<boolean>(false);
    const [isScanIDModalOpen, setIsScanIDModalOpen] = useState<boolean>(false);
    const [isScanQRCodeModalOpen, setIsScanQRCodeModalOpen] = useState<boolean>(false);
    // sign out
    const [isSignOutModalOpen, setIsSignOutModalOpen] = useState<boolean>(false);
    // invite
    const [isNewInvitationDrawerOpen, setIsNewInvitationDrawerOpen] = useState<boolean>(false);
    // visitors drawer
    const [isVisitorsDrawerOpen, setIsVisitorsDrawerOpen] = useState<boolean>(false);
    const [isProfileDrawerOpen, setIsProfileDrawerOpen] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                if (invitationConfig === null) {
                    await dispatch(fetchInvitationConfig({ orgId }));
                }
            } catch (error) {
                console.log("Failed to fetch data.");
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, [orgId, dispatch, invitationConfig]);

    const handleFloatButtonClick = async (type: Actions) => {
        if (type === 'SIGN_IN') {
            const locationWorkflowResponse = await getRequest(`/orgs/${orgId}/sites/${locationIdRef.current}/workflows`);
            const signInWorkflowConfigResponse = await getRequest(`/orgs/${orgId}/visitorWorkflowConfig/${locationWorkflowResponse.data[0].SignInWorkflowId}`);
            if (((signInWorkflowConfigResponse.data[0]?.scan === 1) && (signInWorkflowConfigResponse.data[0]?.scanScanner === 1)) || (invitationConfig?.allowPreRegistration === 1)) {
                setIsSignInMethodModalOpen(true);
            } else {
                setIsSignInModalOpen(true);
            }
        } else if (type === 'SIGN_OUT') {
            setIsSignOutModalOpen(true);
        } else if (type === 'NEW_INVITATION') {
            setIsNewInvitationDrawerOpen(true);
        }
    };

    const handleLocationSelect = async (locationId: number) => {
        locationIdRef.current = locationId;
        setIsSelectLocationModalOpen(false);
        // Fetch visits for today for the selected location
        try {
            const response = await getRequest(`/orgs/${orgId}/search/visitors`, {
                siteId: locationIdRef.current,
                startDate: dayjs().startOf('day').format(DATE_TIME_FORMAT),
                endDate: dayjs().endOf('day').format(DATE_TIME_FORMAT)
            });
            setVisitorsToday(response.data);
        } catch (error) {
            console.error('Failed to fetch visits for today:', error);
        }
        if (pendingAction) {
            handleFloatButtonClick(pendingAction); // Perform the pending action
            setPendingAction(null); // Reset the pending action
        }
    };

    return (
        <>
            {(!loading && (hasSigninWrite || hasSignoutWrite || hasInviteWrite)) && (
                <OpFloatButton
                    type="default"
                    icon={<VoltIcon style={{ width: 18, height: 18, fill: 'var(--colorText)' }} />}
                    style={{
                        left: 26,
                        bottom: 32,
                        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.40)',
                    }}
                    onClick={() => setIsQuickActionsModalOpen(true)}
                />
            )}
            <QuickActionsModal
                open={isQuickActionsModalOpen}
                onClose={() => setIsQuickActionsModalOpen(false)}
            />
            {isSelectLocationModalOpen && (
                <SelectLocationModal
                    open={isSelectLocationModalOpen}
                    onClose={() => setIsSelectLocationModalOpen(false)}
                    handleLocationSelect={handleLocationSelect}
                />
            )}
            {isSignInMethodModalOpen && locationIdRef.current !== null && (
                <SignInMethodModal
                    open={isSignInMethodModalOpen}
                    onClose={() => setIsSignInMethodModalOpen(false)}
                    openSignInModal={() => setIsSignInModalOpen(true)}
                    openScanIDModal={() => setIsScanIDModalOpen(true)}
                    openScanQRCodeModal={() => setIsScanQRCodeModalOpen(true)}
                    locationIdRef={locationIdRef.current}
                />
            )}
            {isSignInModalOpen && locationIdRef.current !== null && (
                <SignInModal
                    open={isSignInModalOpen}
                    locationIdRef={locationIdRef.current}
                    onClose={() => {
                        setIsSignInModalOpen(false);
                        setVisitorNameFilter(undefined);
                    }}
                    openVisitorsDrawer={() => setIsVisitorsDrawerOpen(true)}
                    openSignInManuallyDrawer={() => setIsSignInManuallyDrawerOpen(true)}
                    visitorNameFilter={visitorNameFilter}
                />
            )}
            {isSignInManuallyDrawerOpen && locationIdRef.current !== null && (
                <SignInManuallyDrawer
                    open={isSignInManuallyDrawerOpen}
                    locationIdRef={locationIdRef.current}
                    onClose={() => {
                        setNewVisitorName(undefined);
                        setVisitorNameFilter(undefined);
                        setIsSignInManuallyDrawerOpen(false);
                    }}
                    newVisitorName={newVisitorName}
                    setNewVisitorName={setNewVisitorName}
                />
            )}
            {isScanIDModalOpen && (
                <ScanIDModal
                    open={isScanIDModalOpen}
                    visitorsToday={visitorsToday}
                    onClose={() => setIsScanIDModalOpen(false)}
                    openVisitorsDrawer={() => setIsVisitorsDrawerOpen(true)}
                    openSelectVisitorsModal={() => setIsSignInModalOpen(true)}
                    openSignInManuallyDrawer={() => setIsSignInManuallyDrawerOpen(true)}
                    setVisitorNameFilter={setVisitorNameFilter}
                    setNewVisitorName={setNewVisitorName}
                />
            )}
            {isScanQRCodeModalOpen && (
                <ScanQRCodeModal
                    open={isScanQRCodeModalOpen}
                    visitorsToday={visitorsToday}
                    onClose={() => setIsScanQRCodeModalOpen(false)}
                    openVisitorsDrawer={() => setIsVisitorsDrawerOpen(true)}
                />
            )}
            {isSignOutModalOpen && locationIdRef.current !== null && (
                <SignOutModal
                    open={isSignOutModalOpen}
                    locationIdRef={locationIdRef.current}
                    onClose={() => setIsSignOutModalOpen(false)}
                    openVisitorsDrawer={() => setIsVisitorsDrawerOpen(true)}
                />
            )}
            {isNewInvitationDrawerOpen && locationIdRef.current !== null && (
                <InvitationDrawer
                    open={isNewInvitationDrawerOpen}
                    onClose={() => {
                        setIsNewInvitationDrawerOpen(false);
                    }}
                    locationIdRef={locationIdRef.current}
                />
            )}
            {(isVisitorsDrawerOpen) && (
                <VisitorsDrawer
                    open={isVisitorsDrawerOpen}
                    isProfileDrawerOpen={isProfileDrawerOpen}
                    onClose={() => {
                        setNewVisitorName(undefined);
                        setVisitorNameFilter(undefined);
                        setIsVisitorsDrawerOpen(false);
                        dispatch(setSelectedVisit(null));
                    }}
                    setIsProfileDrawerOpen={setIsProfileDrawerOpen}
                />
            )}
        </>
    );
};

export default ActionFloatButtons;
