import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { notification } from 'antd';
import { AppDispatch, RootState } from 'store/store';
import { deleteRequest, getRequest, postRequest } from 'api/apiClient';
import { OpTable, IOpTableProps } from 'components/customAntd/DLS/OpTable/OpTable';
import { OpTableRawColumnType } from 'components/customAntd/DLS/OpTableCore/OpTableCore';
import { Location } from 'types/locationTypes';
import { hasPermission } from 'utils/utils';
import { TABLE_HEIGHT } from 'constants/ui';
import {
    NOTIFICATION_ERROR,
    LOCATION_DELETE_ERROR,
    NOTIFICATION_SUCCESS,
    LOCATION_DELETE_SUCCESS,
    LOCATION_DELETE_ERROR_LAST_LOCATION,
    CONFIRM_DELETE_TITLE,
    CONFIRM_DELETE_CONTENT,
    LOCATION_DELETE_ERROR_KIOSK_CONNECTED,
} from 'constants/messages';
import { LOCATION_DELETED_ID } from 'constants/userActivities';
import { describeLocation, describeLocationWorkflow, fetchLocations } from 'store/slices/locationsSlice';
import { OpModal } from 'components/customAntd/DLS/OpModal/OpModal';
import { Kiosk } from 'types/kioskTypes';

interface LocationsTableProps {
    handleEditClick: (location: Location) => void;
    handleAddClick: () => void;
}

const LocationsTable: React.FC<LocationsTableProps> = ({ handleEditClick, handleAddClick }) => {
    const dispatch: AppDispatch = useDispatch();
    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);
    const globalUserId = useSelector((state: RootState) => state.users.globalUser?.id);
    const globalLocationId = useSelector((state: RootState) => state.locations.globalLocation?.id);
    const tokenScopeList = useSelector((state: RootState) => state.auth.auth.data[0]?.tokenScopeList || []);
    const subscriptions = useSelector((state: RootState) => state.subscriptions.subscriptions);
    const { locations, fetchLocationsLoading } = useSelector((state: RootState) => state.locations);

    const [isFeatureUnavailableModalOpen, setFeatureUnavailableModalOpen] = useState(false);

    const hasLocationWrite = hasPermission(tokenScopeList, orgId, 'o', 'location:w');

    const columns: OpTableRawColumnType[] = [
        {
            dataIndex: 'name',
            label: "LOCATION",
            filter: {
                type: 'input',
            },
            sorter: (a, b) => (a.name || '').localeCompare(b.name || ''),
        },
        {
            dataIndex: 'description',
            label: "DESCRIPTION",
            filter: {
                type: 'input',
            },
            sorter: (a, b) => (a.description || '').localeCompare(b.description || ''),
        },
        {
            dataIndex: 'address',
            label: "ADDRESS",
            hidden: true,
            filter: {
                type: 'input',
            },
            sorter: (a, b) => (a.address || '').localeCompare(b.address || ''),
        },
        {
            dataIndex: 'address2',
            label: "ADDRESS 2",
            hidden: true,
            filter: {
                type: 'input',
            },
            sorter: (a, b) => (a.address2 || '').localeCompare(b.address2 || ''),
        },
        {
            dataIndex: 'city',
            label: "CITY",
            hidden: true,
            filter: {
                type: 'input',
            },
            sorter: (a, b) => (a.city || '').localeCompare(b.city || ''),
        },
        {
            dataIndex: 'region',
            label: "STATE",
            hidden: true,
            filter: {
                type: 'input',
            },
            sorter: (a, b) => (a.region || '').localeCompare(b.region || ''),
        },
        {
            dataIndex: 'postalcode',
            label: "ZIP CODE",
            hidden: true,
            filter: {
                type: 'input',
            },
            sorter: (a, b) => (a.postalcode || '').localeCompare(b.postalcode || ''),
        },
        {
            dataIndex: 'country',
            label: "COUNTRY",
            hidden: true,
            filter: {
                type: 'input',
            },
            sorter: (a, b) => (a.country || '').localeCompare(b.country || ''),
        },
        {
            dataIndex: 'phone',
            label: "PHONE",
            hidden: true,
            filter: {
                type: 'input',
            },
            sorter: (a, b) => (a.phone || '').localeCompare(b.phone || ''),
        },
    ];

    const handleDeleteLocation = async (location: Location) => {
        if (locations.length === 1) {
            notification.error({
                message: NOTIFICATION_ERROR,
                description: LOCATION_DELETE_ERROR_LAST_LOCATION,
                placement: 'bottomRight',
            });
            return;
        }
        try {
            // Fetch all kiosks
            const kiosks = await getRequest(`/orgs/${orgId}/kiosk`);
            const filteredKiosks = kiosks.data.filter(
                (kiosk: Kiosk) =>
                    kiosk.site.id === location.id
            );

            // Check if there are any connected kiosks
            if (filteredKiosks.length > 0) {
                notification.error({
                    message: NOTIFICATION_ERROR,
                    description: LOCATION_DELETE_ERROR_KIOSK_CONNECTED,
                    placement: 'bottomRight',
                });
                return;
            }

            await deleteRequest(`/orgs/${orgId}/sites/${location.id}`);
            await postRequest(`/orgs/${orgId}/userActivity`, {
                userId: globalUserId,
                activityId: LOCATION_DELETED_ID,
                details: location.name
            });

            // Re-fetch the updated list of locations
            dispatch(fetchLocations({ orgId, status: 1 }));

            if (location.id === globalLocationId) {
                const firstLocation = locations[0];
                await dispatch(describeLocation({ orgId, locationId: firstLocation.id, global: true }));
                await dispatch(describeLocationWorkflow({ orgId, locationId: firstLocation.id, global: true }));
            }
            notification.success({
                message: NOTIFICATION_SUCCESS,
                description: LOCATION_DELETE_SUCCESS,
                placement: 'bottomRight',
            });

        } catch (error) {
            notification.error({
                message: NOTIFICATION_ERROR,
                description: LOCATION_DELETE_ERROR,
                placement: 'bottomRight',
            });
        }
    };

    const handleAddLocation = () => {
        const packageCode = subscriptions?.data[0]?.package?.code;
        if (locations.length >= 1 && (packageCode === 'package-essential')) {
            setFeatureUnavailableModalOpen(true);
            return;
        }

        handleAddClick();
    }

    const opTableProps: IOpTableProps = {
        dataSource: locations,
        columns: columns,
        rowActions: {
            onEditClick: (location: Location) => handleEditClick(location),
            onDeleteClick: hasLocationWrite ? handleDeleteLocation : undefined,
            deleteModalTitle: () => CONFIRM_DELETE_TITLE("Location"),
            deleteModalContent: ({ record }) => CONFIRM_DELETE_CONTENT(record.name),
        },
        height: TABLE_HEIGHT,
        allowGlobalSearch: true,
        writeAccess: hasLocationWrite,
        allowAddition: hasLocationWrite ? {
            itemName: 'Location',
            onClick: handleAddLocation,
        } : false,
        loading: fetchLocationsLoading,
        allowExport: true,
        allowShowHideColumns: true,
    };

    return <>
        <OpTable {...opTableProps} />
        <OpModal
            open={isFeatureUnavailableModalOpen}
            onCancel={() => setFeatureUnavailableModalOpen(false)}
            onOk={() => setFeatureUnavailableModalOpen(false)}
            title="Feature Not Included"
        >
            <p>Your account does not currently include this feature. Please contact your administrator.</p>
        </OpModal>
    </>;
};

export default LocationsTable;
