export const DASHBOARD_TOOLTIP = "The InVisit dashboard provides a real-time, comprehensive view of current, and expected, visitors for the day.";
export const VISITORS_TOOLTIP = "The Visitors list view will display visitor activity (e.g. sign ins, sign outs, etc.) for your organization. Each visit will reflect its current status, and can be selected to view additional details.";
export const LOCATION_TOOLTIP = "Any area where a visitor will seek to enter your organization by way of signing in and out, is considered a “Location”.  Locations can have dedicated workflows to manage the visit process for that area.";
export const USERS_TOOLTIP = "Users refer to those individuals that can be given access to InVisit solutions.  Access is defined by the “Role” that is assigned to the user.";
export const ROLES_TOOLTIP = "Roles refers to a set of permissions that can be assigned to a user. These permissions define what a user has access to within InVisit. Users can be assigned to multiple roles.";
export const KIOSK_TOOLTIP = "A Kiosk is a self-service tablet that visitors can use to sign themselves in and out of, at a  location.  Tablets can be registered with InVisit and then assigned to a desired workflow for allowing, or denying, access to the location.";
export const INVITATIONS_TOOLTIP = "Invitation options allow a user to establish invitation preferences, manage invitation form fields and customize messaging that is automatically included within invitation emails.";
export const WORKFLOW_TOOLTIP = "Workflow configurations allow a user to establish desired preferences for visitor signs ins, and sign outs.  Multiple workflows can be set up to support multiple scenarios.";
export const REDFLAG_TOOLTIP = "A Red Flag is a visitor that has been marked as a potential concern  based on your organization’s internal watchlist or blocklist.";
export const AGREEMENTS_TOOLTIP = "Agreements refers to any document that a visitor needs to acknowledge as part of the sign in, or pre-registration, processes. Within InVisit, users can create, modify, and delete agreements.";
export const VISITORREPORTRS_TOOLTIP = "The Visitor Report will show the total number of visitors within each status for the selected date range.";
export const VISITORSHOURLYREPORTS_TOOLTIP = "The Visitors by Hour report provides the average number of visitors by hour for the specified date range. Select an average to view additional details.";
export const REPEATVISITORS_TOOLTIP = "The Repeat Visitors Report shows any visitors that have visited multiple times within a selected date range.";
export const REDFLAGREPORTS_TOOLTIP = "The Red Flag Report displays those individuals that have been flagged to the Watchlist and/or Blocklist. The report includes the ability to drill down into the list of visitors that have associated flags, to view additional details.";
export const HOSTREPORTS_TOOLTIP = "The Host Report provide visit totals per host, for the selected date range.";
export const ACTIVITYLOG_TOOLTIP = "The Activity Log is an internal report that shows admin related activities that have occurred within the Organization, for the selected date range.";
export const ACCOUNT_TOOLTIP = "Within Account, users can access company information and license details.";
export const INTEGRATION_TOOLTIP = "App integration allows Organizations to connect third party applications to InVisit.";
export const FORMFIELDS_TOOLTIP = "Form fields are custom fields that can be used within sign in workflows, and invitations.";