import { List } from "antd";
import useFormInstance from "antd/es/form/hooks/useFormInstance";
import { OpForm } from "components/customAntd/DLS/OpForm/OpForm";

// eslint-disable-next-line
const OPTIONS_BEFORE_AFTER = [
    { label: "0 minutes", value: 0 },
    { label: "15 minutes", value: 15 },
    { label: "30 minutes", value: 30 },
    { label: "60 minutes", value: 60 },
    { label: "120 minutes", value: 120 },
];

interface OptionsFormProps {
    entryIdOptions: { value: number; label: string }[];
}


const OptionsForm: React.FC<OptionsFormProps> = ({ entryIdOptions }) => {
    const form = useFormInstance();
    const qrEnabled = OpForm.useWatch('qrEnabled', form) || false;
    const entryEnabled = OpForm.useWatch('entryEnabled', form) || false;

    return (
        <>
            <List
                header={<div>READER OPTIONS</div>}
                bordered
                style={{ marginBottom: "16px", padding: "12px", borderRadius: "8px" }}
            >
                <List.Item style={{ width: "100%" }}>
                    <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                        <OpForm.Switch
                            name="qrEnabled"
                            checked={qrEnabled}
                            onChange={(checked) => {
                                if (!checked) {
                                    form.setFieldsValue({
                                        entryEnabled: false,
                                        autoSignIn: false
                                    });
                                }
                            }} />
                        <span style={{ marginLeft: 8 }}>Recognize Visitor QR Code</span>
                    </div>
                </List.Item>


                <List.Item style={{ width: "100%", flexDirection: "column", alignItems: "flex-start" }}>
                    <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
                        <OpForm.Switch
                            name="entryEnabled"
                            disabled={!qrEnabled}
                            onChange={(checked) => {
                                if (!checked) {
                                    form.setFieldsValue({
                                        autoSignIn: false
                                    });
                                }
                            }}
                        />
                        <span style={{ marginLeft: 8 }}>Open Entry on Valid QR Code Scan</span>
                    </div>
                    <div style={{ width: "100%", marginTop: "8px" }}>
                        <OpForm.Select
                            name="entryId"
                            placeholder="Select Entry ID"
                            disabled={!entryEnabled}
                            options={entryIdOptions}
                        />
                    </div>
                </List.Item>

                {/* <List.Item style={{ width: "100%", flexDirection: "column", alignItems: "flex-start" }}>
                    <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
                        <span>Minutes BEFORE Schedule to Allow Entry</span>
                    </div>
                    <div style={{ width: "100%", marginTop: "8px" }}>
                        <OpForm.Select
                            name="entryMinsBefore"
                            placeholder="Select Minutes Before"
                            options={OPTIONS_BEFORE_AFTER}
                            disabled={!entryEnabled}
                            filterSort={(optionA, optionB) => {
                                const aValue = Number(optionA.value ?? 0);
                                const bValue = Number(optionB.value ?? 0);
                                return aValue - bValue;
                            }}
                        />
                    </div>
                </List.Item>

                <List.Item style={{ width: "100%", flexDirection: "column", alignItems: "flex-start" }}>
                    <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
                        <span>Minutes AFTER Schedule to Allow Entry</span>
                    </div>
                    <div style={{ width: "100%", marginTop: "8px" }}>
                        <OpForm.Select
                            name="entryMinsAfter"
                            placeholder="Select Minutes After"
                            options={OPTIONS_BEFORE_AFTER}
                            disabled={!entryEnabled}
                            filterSort={(optionA, optionB) => {
                                const aValue = Number(optionA.value ?? 0);
                                const bValue = Number(optionB.value ?? 0);
                                return aValue - bValue;
                            }}
                        />
                    </div>
                </List.Item> */}

                <List.Item style={{ width: "100%" }}>
                    <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                        <OpForm.Switch
                            name="autoSignIn"
                            disabled={!entryEnabled}
                        />
                        <span style={{ marginLeft: 8 }}>Auto Sign In on Valid QR Code Scan</span>
                    </div>
                </List.Item>
            </List>
        </>
    );
};

export default OptionsForm;
