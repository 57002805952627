import React from "react";
import { List, Typography } from "antd";
import { QrCodeScanLog } from "types/qrCodeTypes";
import dayjs from 'dayjs';
import { DATE_TIME_AM_PM_FORMAT } from "constants/dates";

interface QrCodeTabProps {
    qrCodeScanLogs: QrCodeScanLog[];
}

const QrCodeTab: React.FC<QrCodeTabProps> = ({ qrCodeScanLogs }) => {
    return (
        <List
            header={<Typography.Text>QR CODE SCAN LOG</Typography.Text>}
            bordered
            dataSource={qrCodeScanLogs}
            renderItem={(item) => (
                <List.Item>
                    <Typography.Text>
                        {dayjs.utc(item.scannedUtc).local().format(DATE_TIME_AM_PM_FORMAT)}
                    </Typography.Text>
                    <Typography.Text>{item.source}</Typography.Text>
                </List.Item>
            )}
        />
    );
};

export default QrCodeTab;
