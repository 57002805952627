import React, { useCallback, useEffect, useState } from 'react';
import { OpFormDrawer } from 'components/customAntd/DLS/OpFormDrawer/OpFormDrawer';
import { DRAWER_WIDTH } from 'constants/ui';
import { IOnSubmitArgs, OpForm } from 'components/customAntd/DLS/OpForm/OpForm';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store/store';
import { VisitorType } from 'types/VisitorTypeTypes';
import { getRequest, postRequest } from 'api/apiClient';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { User } from 'types/userTypes';
import { VisitorInvite } from 'types/visitorInvitationTypes';
import VisitorList from '../formComponents/VisitorList';
import { formatFullName, hasPermission, transformNullToEmptyString } from 'utils/utils';
import { notification } from 'antd';
import STATUS from 'constants/status';
import { VISITOR_INVITATION_CREATED_ID } from 'constants/userActivities';
import { DATE_TIME_AM_PM_FORMAT, DATE_TIME_FORMAT } from 'constants/dates';
import { fetchVisits } from 'store/slices/visitsSlice';
import { fetchInvitationConfig } from 'store/slices/visitorInvitationSlice';
import { searchVisitors, searchVisitorsToday } from 'store/slices/visitorSearchSlice';
import { fetchLocations } from 'store/slices/locationsSlice';
import { Location } from 'types/locationTypes';

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

const now = dayjs();
const defaultScheduleIn = now.minute(0).add(1, 'hour');
const defaultScheduleOut = defaultScheduleIn.add(1, 'hour');

interface InvitationDrawerProps {
    open: boolean;
    onClose: () => void;
    locationIdRef: number;
}

const InvitationDrawer: React.FC<InvitationDrawerProps> = ({ open, onClose, locationIdRef }) => {
    const dispatch: AppDispatch = useDispatch();
    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);
    const globalUserId = useSelector((state: RootState) => state.users.globalUser?.id);
    const globalLocationId = useSelector((state: RootState) => state.locations.globalLocation?.id);
    const { invitationConfig } = useSelector((state: RootState) => state.visitorInvitation);
    const tokenScopeList = useSelector((state: RootState) => state.auth.auth.data[0]?.tokenScopeList || []);
    const lastSearchParams = useSelector((state: RootState) => state.visitorSearch.lastSearchParams);
    const { locations } = useSelector((state: RootState) => state.locations);

    const [hosts, setHosts] = useState<User[]>([]);
    const [visitorTypes, setVisitorTypes] = useState<VisitorType[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const [form] = OpForm.useForm();

    const hasAllVisitorsWrite = hasPermission(tokenScopeList, orgId, 'o', 'allvisitors:w');

    const [initialValues, setInitialValues] = useState({
        siteId: globalLocationId,
        visitorTypeId: visitorTypes[0]?.id,
        scheduleIn: defaultScheduleIn,
        scheduleOut: defaultScheduleOut,
        visitors: [],
    });

    useEffect(() => {
        if (open) {
            const now = dayjs();
            const defaultScheduleIn = now.minute(0).add(1, 'hour');
            const defaultScheduleOut = defaultScheduleIn.add(1, 'hour');

            setInitialValues({
                siteId: locationIdRef,
                visitorTypeId: visitorTypes[0]?.id,
                scheduleIn: defaultScheduleIn,
                scheduleOut: defaultScheduleOut,
                visitors: [],
            });

            form.resetFields(); // Reset form fields to the updated initial values
        }
    }, [open, globalLocationId, visitorTypes, form, locationIdRef]);

    // Fetch visits and red flags
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                if (invitationConfig === null) {
                    await dispatch(fetchInvitationConfig({ orgId }));
                }

                if (orgId && locations.length === 0) {
                    dispatch(fetchLocations({ orgId, status: 1 }));
                }

                const hosts = await getRequest(`/orgs/${orgId}/users`);
                const filteredHosts = hosts.data.filter((user: User) => user.status === 1 && (user.hostExclude === 0 || user.hostExclude === null));
                setHosts(filteredHosts);

                const visitorTypes = await getRequest(`/orgs/${orgId}/visitorTypes`);
                setVisitorTypes(visitorTypes.data);
            } catch (error) {
                console.log("Failed to fetch data.");
            } finally {
                setLoading(false);
            }
        };
        fetchData();
        // eslint-disable-next-line
    }, [orgId]);


    const handleSubmit = useCallback(async ({ values }: IOnSubmitArgs) => {
        setIsSubmitting(true);
        try {
            if (values.visitors.length === 0) {
                notification.error({
                    message: 'Error',
                    description: 'Please add at least one visitor.',
                    placement: 'bottomRight',
                });
                setIsSubmitting(false);
                return;
            }

            const transformedValues = transformNullToEmptyString(values);
            let { scheduleIn, scheduleOut, ...payload } = transformedValues;
            if (scheduleOut && scheduleIn && scheduleOut.isSameOrBefore(scheduleIn)) {
                scheduleOut = scheduleOut.add(1, 'day');
            }
            await postRequest(`/orgs/${orgId}/visitorInvitation`, {
                ...payload,
                scheduleIn: scheduleIn?.format(DATE_TIME_FORMAT),
                scheduleOut: scheduleOut?.format(DATE_TIME_FORMAT),
                visitors: transformedValues.visitors.map((visitor: VisitorInvite) => ({
                    // ...visitor,
                    firstName: visitor.firstName?.trim(),
                    middleName: visitor.middleName?.trim(),
                    lastName: visitor.lastName?.trim(),
                    email: visitor.email?.trim(),
                    mobilePhone: visitor.mobilePhone?.trim(),
                    status: STATUS.PENDING.id,
                })),
            });

            const fullNameList = values.visitors.map((visitor: VisitorInvite) =>
                formatFullName(visitor.firstName, null, visitor.lastName,)).join(', ');

            await postRequest(`/orgs/${orgId}/userActivity`, {
                userId: globalUserId,
                activityId: VISITOR_INVITATION_CREATED_ID,
                details: fullNameList
            });
            notification.success({
                message: 'Success',
                description: 'Visitor invitation created successfully.',
                placement: 'bottomRight',
            });

            await dispatch(fetchVisits({ orgId }));
            await dispatch(searchVisitorsToday({ orgId }));
            if (Object.keys(lastSearchParams).length !== 0) {
                await dispatch(searchVisitors({
                    ...lastSearchParams,
                    orgId,
                    siteId: lastSearchParams.siteId || globalLocationId! // 사이트 우선순위 적용
                }));
            }

            form.resetFields();
            onClose();
        } catch (error) {
            notification.error({
                message: 'Error',
                description: 'An error occurred during the submission.',
                placement: 'bottomRight',
            });
            console.error("Form submission failed:", error);
        } finally {
            setIsSubmitting(false);
        }
    }, [orgId, globalUserId, form, dispatch, onClose, globalLocationId, lastSearchParams]);

    const handleFieldsChange = (changedFields: any) => {
        const changedField = changedFields[0];
        if (changedField) {
            const { name, value } = changedField;
            const [field] = name;
            if (field === 'scheduleIn') {
                const scheduleIn = value;
                if (scheduleIn) {
                    const startMoment = dayjs(scheduleIn);
                    const newScheduleOut = startMoment.add(1, 'hour');
                    form.setFieldsValue({ scheduleOut: newScheduleOut });
                }
            } else if (field === 'scheduleOut') {
                const scheduleOut = value;
                const scheduleIn = form.getFieldValue('scheduleIn');

                if (scheduleIn && scheduleOut) {
                    const startMoment = dayjs(scheduleIn);
                    const endMoment = dayjs(scheduleOut);

                    if (startMoment.isSameOrAfter(endMoment)) {
                        form.setFieldsValue({ scheduleIn: endMoment.subtract(1, 'hour') });
                    }
                }
            }
        }
    };

    const filteredHosts = hasAllVisitorsWrite
        ? hosts
        : hosts.filter(host => host.id === globalUserId);

    return (
        <OpFormDrawer
            title={'New Invitation'}
            width={DRAWER_WIDTH}
            open={open}
            isFormLoading={loading || isSubmitting}
            onClose={() => {
                form.resetFields();
                onClose();
            }}
            form={form}
            formComponent={
                <OpForm
                    form={form}
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                    onFieldsChange={handleFieldsChange}
                    hasError={false}
                    defaultButtons={false}
                >
                    <VisitorList form={form} isInviting={true} />
                    <OpForm.Select
                        label="Visitor Type" name="visitorTypeId" rules={[{ required: true, message: 'Please select a visitor type.' }]}
                        placeholder="Select Visitor Type"
                        options={visitorTypes.map(type => ({
                            label: type.name,
                            value: type.id
                        }))}
                    />
                    <OpForm.Select
                        label="Locations" name="siteId" rules={[{ required: true, message: 'Please select a location.' }]}
                        placeholder="Select Locations"
                        options={locations.map((location: Location) => ({
                            label: location.name,
                            value: location.id
                        }))}
                        disabled={true}
                    />
                    <OpForm.DatePicker
                        format={DATE_TIME_AM_PM_FORMAT}
                        showTime
                        minuteStep={5}
                        name="scheduleIn"
                        label="Schedule Start Date / Time"
                        rules={[{ required: true, message: 'Please select a date' }]}
                    />
                    <OpForm.DatePicker
                        format={DATE_TIME_AM_PM_FORMAT}
                        showTime
                        minuteStep={5}
                        name="scheduleOut"
                        label="Schedule End Date / Time"
                        rules={[{ required: true, message: 'Please select a date' }]}
                    />
                    {(invitationConfig?.visitCompany.included === 1) ? (
                        <OpForm.Input
                            label="Company" name="company"
                            rules={[{ required: invitationConfig.visitCompany.required === 1, message: 'Please enter company.' }]}
                        />
                    ) : undefined}
                    {(invitationConfig?.host.included === 1) ? (
                        <OpForm.Select
                            label="Host" name="hostId"
                            rules={[{ required: invitationConfig.host.required === 1 && hosts.length > 0, message: 'Please select a host.' }]}
                            showSearch
                            filterOption={(input, option) =>
                                (option?.label ?? '').toString().toLowerCase().includes(input.toLowerCase())
                            }
                            placeholder={filteredHosts.length === 0 ? "No hosts are available" : "Select Host"}
                            disabled={filteredHosts.length === 0}
                            options={filteredHosts.map(host => ({
                                label: host.identity?.firstName || host.identity?.lastName
                                    ? formatFullName(host.identity.firstName, host.identity.middleName, host.identity.lastName)
                                    : host.identity?.email,
                                value: host.id
                            }))}
                        />
                    ) : undefined}
                    {(invitationConfig?.visitPurpose.included === 1) ? (
                        <OpForm.TextAreaInput
                            label="Purpose" name="purpose" rows={4}
                            rules={[{ required: invitationConfig.visitPurpose.required === 1, message: 'Please enter purpose.' }]}
                        />
                    ) : undefined}
                    {(invitationConfig?.visitNotes?.included === 1) ? (
                        <OpForm.TextAreaInput
                            label="Note" name="note" rows={4}
                            rules={[{ required: invitationConfig.visitNotes.required === 1, message: 'Please enter note.' }]}
                        />
                    ) : undefined}
                </OpForm>
            }
        />
    );
};

export default InvitationDrawer;