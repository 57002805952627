import React from 'react';
import { SvgWrapper, SvgWrapperProps } from 'components/customAntd/inVision - Platinum Assets/svgs/SvgWrapper';

interface UserGroupOutlineSvgProps extends Omit<SvgWrapperProps, 'children'> { }

export const UserGroupOutlineSvg = ({
  ...svgProps
}: UserGroupOutlineSvgProps) => (
  <SvgWrapper viewBox="0 0 18 18" {...svgProps}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 6C10 7.2582 9.41908 8.38073 8.51082 9.11398C10.5094 9.5727 12 11.3623 12 13.5V14.5C12 15.3284 11.3284 16 10.5 16H1.5C0.671573 16 0 15.3284 0 14.5V13.5C0 11.3623 1.49063 9.5727 3.48918 9.11398C2.58092 8.38073 2 7.2582 2 6C2 3.79086 3.79086 2 6 2C8.20914 2 10 3.79086 10 6ZM6 9C7.65685 9 9 7.65685 9 6C9 4.34315 7.65685 3 6 3C4.34315 3 3 4.34315 3 6C3 7.65685 4.34315 9 6 9ZM4.5 10H7.5C9.433 10 11 11.567 11 13.5V14.5C11 14.7761 10.7761 15 10.5 15H1.5C1.22386 15 1 14.7761 1 14.5V13.5C1 11.567 2.567 10 4.5 10Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.5001 16H12.5002C12.7186 15.7093 12.8751 15.3694 12.95 15H19.5001C19.7762 15 20.0001 14.7761 20.0001 14.5V13.5C20.0001 11.567 18.4331 10 16.5001 10H13.5001C12.9632 10 12.4546 10.1209 12 10.3368C11.8021 10.0558 11.5786 9.7942 11.3328 9.55534C11.6913 9.35795 12.0796 9.20801 12.4892 9.11399C11.5809 8.38073 11 7.25821 11 6C11 3.79086 12.7909 2 15 2C17.2091 2 19 3.79086 19 6C19 7.2582 18.4191 8.38071 17.5108 9.11397C19.5094 9.57267 21.0001 11.3623 21.0001 13.5V14.5C21.0001 15.3284 20.3285 16 19.5001 16ZM15 9C16.6569 9 18 7.65685 18 6C18 4.34315 16.6569 3 15 3C13.3431 3 12 4.34315 12 6C12 7.65685 13.3431 9 15 9Z"
    />
  </SvgWrapper>
);
