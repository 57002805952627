import React, { useRef, useState, useEffect } from "react";
import Sider from "antd/es/layout/Sider";
import clsx from "clsx";
import { OpNavMenu } from "./components/OpNavMenu/OpNavMenu";
import { OpNavProps } from "./types";
import "./OpNav.scss";
import { OpButton } from "components/customAntd/DLS/OpButton/OpButton";
import { CloseOutlined } from '@ant-design/icons';

export const OpNav = ({
  logo,
  items,
  onLogoClick = () => { },
  defaultPrimaryKey,
  defaultSecondaryKey,
  footer,
  onClose, // 닫기 핸들러 추가
  isMobile, // 모바일 여부 추가
}: OpNavProps) => {
  const navMenuRef = useRef<HTMLDivElement>(null);
  const [isScrollingUp, setIsScrollingUp] = useState(false);
  const [isScrollingDown, setIsScrollingDown] = useState(false);

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const { scrollTop, clientHeight, scrollHeight } = e.currentTarget;

    // 상단 그림자 표시 조건
    setIsScrollingUp(scrollTop > 0);

    // 하단 그림자 표시 조건
    setIsScrollingDown(scrollTop + clientHeight < scrollHeight);
  };

  // 스크롤 상태 업데이트 함수
  const updateScrollState = () => {
    if (navMenuRef.current) {
      const { scrollTop, clientHeight, scrollHeight } = navMenuRef.current;
      setIsScrollingUp(scrollTop > 0);
      setIsScrollingDown(scrollTop + clientHeight < scrollHeight);
    }
  };

  // 초기 스크롤 상태 설정
  useEffect(() => {
    updateScrollState();
  }, []);

  // 창 크기 변경 시 스크롤 상태 업데이트
  useEffect(() => {
    const handleResize = () => {
      updateScrollState();
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div id="op-nav" className="op-nav" data-testid="op-nav">
      <Sider className="op-nav__sider" width={104}>
        <div className="op-nav__header-group">
          {isMobile && (
            <div className="op-nav__close-button-container">
              <OpButton
                icon={<CloseOutlined />}
                onClick={onClose}
                data-testid="op-nav-close-button"
              />
            </div>
          )}

          {logo && (
            <div
              className={clsx("op-nav__logo", {
                "op-nav__logo--clickable": !!onLogoClick,
              })}
              data-testid="op-nav-logo"
              role="button"
              tabIndex={0}
              onClick={onLogoClick}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  onLogoClick();
                }
              }}
            >
              <div className="op-nav__logo">{logo}</div>
              <div className="op-nav__branding-text">VISITOR</div>
            </div>
          )}
        </div>
        
        {/* 외부 컨테이너 (그림자 고정용) */}
        <div
          className={clsx("op-nav-menu-container", {
            "scrolling-up": isScrollingUp,
            "scrolling-down": isScrollingDown,
          })}
        >
          {/* 내부 스크롤 영역 */}
          <div
            ref={navMenuRef}
            className="op-nav-menu-scroll"
            onScroll={handleScroll}
          >
            <OpNavMenu
              items={items}
              defaultPrimaryKey={defaultPrimaryKey || items[0]?.key}
              defaultSecondaryKey={
                defaultSecondaryKey || items[0]?.children?.[0]?.key
              }
            />
          </div>
        </div>

        {/* 푸터 (항상 하단 고정) */}
        <div className="op-nav__footer">{footer}</div>
      </Sider>
    </div>
  );
};