import { useEffect, FunctionComponent, useCallback, useMemo, useState } from 'react';
import { Routes, Route, useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { ConfigProvider, notification } from 'antd';
import { THEME } from './op-themes';
import LogIn from './components/auth/LogIn';
import Register from './components/auth/register/Register';
import { RootState, AppDispatch, persistor } from './store/store';
import { clearAuthState, setAuthData } from './store/slices/authSlice';
import RegisterCompleted from 'components/auth/register/RegisterCompleted';
import SignUp from 'components/auth/signUp/SignUp';
import GuestPass from 'components/guestPass/GuestPass';
import ResetPassword from 'components/auth/resetPassword/ResetPassword';
import './scss/main.scss';
import AppLayout from 'components/layout/AppLayout';
import { clearAgreementsState } from 'store/slices/agreementsSlice';
import { clearDenyState } from 'store/slices/denySlice';
import { clearGlobalOrgState, setGlobalOrgId } from 'store/slices/globalOrgSlice';
import { clearKiosksState } from 'store/slices/kiosksSlice';
import { clearLocationsState, describeLocation, describeLocationWorkflow, fetchLocations } from 'store/slices/locationsSlice';
import { clearRedFlagState, fetchRedFlags } from 'store/slices/redFlagSlice';
import { clearRolesState } from 'store/slices/rolesSlice';
import { clearScopeResourcesState } from 'store/slices/scopeResourcesSlice';
import { clearUserActivityState } from 'store/slices/userActivitySlice';
import { clearVisitorInvitationState } from 'store/slices/visitorInvitationSlice';
import { clearVisitorsState } from 'store/slices/visitorsSlice';
import { clearVisitsState } from 'store/slices/visitsSlice';
import { clearVisitorSearchState } from 'store/slices/visitorSearchSlice';
import { clearVisitorTypesState } from 'store/slices/visitorTypesSlice';
import { clearVisitorWorkflowsState } from 'store/slices/visitorWorkflowsSlice';
import { clearUsersState, describeUser } from 'store/slices/usersSlice';
import { setDarkMode } from 'store/slices/themeSlice';
import FullLoader from 'components/customAntd/FullLoader';
import apiClient from 'api/apiService';
import { fetchSubscriptions } from 'store/slices/subscriptionsSlice';
import { hasPermission } from 'utils/utils';

const apiUrl = process.env.REACT_APP_BACKEND_URL;
const appVersion = process.env.REACT_APP_VERSION;

const App: FunctionComponent = () => {
    const location = useLocation();

    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    const isDarkMode = useSelector((state: RootState) => state.theme.isDarkMode);
    const auth = useSelector((state: RootState) => state.auth.auth);
    const authData = auth?.data && auth.data.length > 0 ? auth.data[0] : null;
    const { globalUser } = useSelector((state: RootState) => state.users);
    const { globalLocation } = useSelector((state: RootState) => state.locations);

    const [isLoadingViaAlta, setIsLoadingViaAlta] = useState(false);

    const [searchParams] = useSearchParams();
    const hasAltaParams = searchParams.get('token') && searchParams.get('orgId');

    const isInitializing =
        hasAltaParams &&
        (!authData || !globalUser || !globalLocation);

    // const isAuthenticated = useMemo(() => {
    //     return !!authData?.token && !!globalUser && !!globalLocation;
    // }, [authData, globalUser, globalLocation]);

    // Determine if the current route is non-themed
    const isNonThemedRoute = useMemo(
        () =>
            ['/resetPassword', '/register', '/register/completed', '/guestpass', '/guestPass/:token'].some((path) =>
                location.pathname.toLowerCase().startsWith(path.toLowerCase())
            ),
        [location.pathname]
    );

    const isLoginRoute = useMemo(
        () => location.pathname.toLowerCase() === '/login' || location.pathname.toLowerCase() === '/signup',
        [location.pathname]
    );

    // Define public routes (accessible without login)
    const publicRoutes = useMemo(() => [
        '/login',
        '/resetPassword',
        '/register',
        '/register/completed',
        '/guestPass',
        '/guestPass/:token',
        '/signUp'
    ], []);

    const isPublicRoute = useMemo(
        () => publicRoutes.some(path => location.pathname.toLowerCase().startsWith(path.toLowerCase())),
        [location.pathname, publicRoutes]
    );

    // useEffect(() => {
    //     if (isAuthenticated && !isPublicRoute) {
    //         navigate('/dashboard', { replace: true });
    //     } else if (!isAuthenticated && !isPublicRoute) {
    //         navigate('/login', { replace: true });
    //     }
    // }, [isAuthenticated, isPublicRoute, navigate]);

    const clearAuthDataFromState = useCallback(async () => {
        await Promise.all([
            dispatch(clearAgreementsState()),
            dispatch(clearAuthState()),
            dispatch(clearDenyState()),
            dispatch(clearGlobalOrgState()),
            dispatch(clearKiosksState()),
            dispatch(clearLocationsState()),
            dispatch(clearRedFlagState()),
            dispatch(clearRolesState()),
            dispatch(clearScopeResourcesState()),
            dispatch(clearUserActivityState()),
            dispatch(clearVisitorInvitationState()),
            dispatch(clearVisitorSearchState()),
            dispatch(clearVisitorsState()),
            dispatch(clearVisitorTypesState()),
            dispatch(clearVisitorWorkflowsState()),
            dispatch(clearVisitsState()),
            dispatch(clearUsersState()),
        ]);
        localStorage.clear();

        await persistor.flush();
        await persistor.purge();

        // Remove token from localStorage
        localStorage.removeItem('authToken');
        localStorage.removeItem('identityId');
        localStorage.removeItem('createdAt');
        localStorage.removeItem('expiresAt');
        localStorage.removeItem('tokenScopeList');

        dispatch(setDarkMode(true));

        // Navigate to login page
        navigate('/login');
    }, [dispatch, navigate]);

    // Redirect unauthenticated users from protected routes
    useEffect(() => {
        if (!authData && !isPublicRoute) {
            navigate('/login', { replace: true });
        }
    }, [authData, isPublicRoute, navigate]);

    // Sync logout across multiple tabs/windows
    useEffect(() => {
        const handleStorageChange = (event: StorageEvent) => {
            if (event.key === 'authToken' && !event.newValue) {
                clearAuthDataFromState();
                console.log('handleStorageChange')

            }
        };

        window.addEventListener('storage', handleStorageChange);
        return () => window.removeEventListener('storage', handleStorageChange);
    }, [clearAuthDataFromState]);

    useEffect(() => {
        const handleThemeChange = () => {
            if (isNonThemedRoute) {
                document.documentElement.classList.add('light');
            } else {
                document.documentElement.classList.toggle('dark', isDarkMode);
            }

            window.dispatchEvent(new Event('themechange'));
        };

        handleThemeChange();
    }, [isDarkMode, location.pathname, isNonThemedRoute]);

    useEffect(() => {
        if (!authData) return;

        const expiresAt = localStorage.getItem('expiresAt');
        if (expiresAt) {
            const expireTime = new Date(expiresAt).getTime();
            const now = Date.now();

            if (now > expireTime) {
                console.warn('Token expired, logging out...');
                notification.warning({
                    message: 'Session Expired',
                    description: 'Your session has expired. Please log in again.',
                    placement: 'bottomRight',
                });
                clearAuthDataFromState();
            }
        }
    }, [authData, clearAuthDataFromState]);

    useEffect(() => {
        const checkAppVersion = () => {
            if (isNonThemedRoute) {
                return; // Do nothing for non-themed routes
            }

            const storedVersion = localStorage.getItem('appVersion') || '';
            if (!storedVersion && appVersion) {
                localStorage.setItem('appVersion', appVersion);
            } else if (storedVersion && appVersion && storedVersion !== appVersion) {
                localStorage.setItem('appVersion', appVersion);
                clearAuthDataFromState();
                notification.info({
                    message: 'Application Updated',
                    description: 'The application has been updated. Please log in again.',
                    placement: 'bottomRight',
                });
            }
        };

        checkAppVersion();
    }, [isNonThemedRoute, clearAuthDataFromState, navigate]);

    useEffect(() => {
        if (isNonThemedRoute) {
            return; // Do nothing for non-themed routes
        }
        const storedApiUrl = localStorage.getItem('apiUrl') || '';
        if (!storedApiUrl && apiUrl) {
            localStorage.setItem('apiUrl', apiUrl);
        } else if (storedApiUrl && apiUrl && storedApiUrl !== apiUrl) {
            clearAuthDataFromState();
            localStorage.setItem('apiUrl', apiUrl);
            notification.info({
                message: 'Application Updated',
                description: 'The application configuration has changed. Please log in again.',
                placement: 'bottomRight',
            });
        }
    }, [isNonThemedRoute, clearAuthDataFromState, navigate]);

    useEffect(() => {
        const altaToken = searchParams.get('token');
        const altaOrgId = searchParams.get('orgId');

        const loginViaAlta = async () => {
            try {
                if (!altaToken || !altaOrgId) return;
                setIsLoadingViaAlta(true);
                const response = await apiClient.get(
                    `${apiUrl}/auth/masterTokens/${altaToken}`,
                    { params: { msiAltaOrgId: altaOrgId } }
                );

                console.log('response', response);
                const authData = response.data.data[0];

                await dispatch(setAuthData({
                    data: [authData],
                    meta: null,
                    totalCount: 1,
                    filteredCount: 1,
                }));

                localStorage.setItem('authToken', authData.token);
                localStorage.setItem('identityId', authData.identityId);
                localStorage.setItem('createdAt', authData.createdAt);
                localStorage.setItem('expiresAt', authData.expiresAt);
                localStorage.setItem('tokenScopeList', JSON.stringify(authData.tokenScopeList));

                const orgId = authData.tokenScopeList[0].org.id;
                const userId = authData.tokenScopeList[0].user.id;

                await dispatch(setGlobalOrgId(orgId));
                await dispatch(describeUser({ orgId, userId, global: true }));
                await dispatch(fetchSubscriptions({ orgId }));

                await dispatch(fetchRedFlags({ orgId }));

                const locationsResult = await dispatch(fetchLocations({ orgId, status: 1 }));
                if (fetchLocations.fulfilled.match(locationsResult) && locationsResult.payload.data.length !== 0) {
                    const firstLocation = locationsResult.payload.data[0];
                    if (firstLocation.id) {
                        await dispatch(describeLocation({ orgId, locationId: firstLocation.id, global: true }));
                        await dispatch(describeLocationWorkflow({ orgId, locationId: firstLocation.id, global: true }));
                    }
                }

                const tokenScopeList = authData.tokenScopeList || [];
                const permissions = {
                    hasDashRead: hasPermission(tokenScopeList, orgId, 'o', 'dash:r'),
                    hasVisitorsRead: hasPermission(tokenScopeList, orgId, 'o', 'visitors:r'),
                    hasLocationRead: hasPermission(tokenScopeList, orgId, 'o', 'location:r'),
                    hasUsersRead: hasPermission(tokenScopeList, orgId, 'o', 'user:r'),
                    hasRolesRead: hasPermission(tokenScopeList, orgId, 'o', 'role:r'),
                    hasKiosksRead: hasPermission(tokenScopeList, orgId, 'o', 'kiosk:r'),
                    hasRedFlagConfRead: hasPermission(tokenScopeList, orgId, 'o', 'redflagconf:r'),
                    hasInvitationRead: hasPermission(tokenScopeList, orgId, 'o', 'invitation:r'),
                    hasWorkflow: hasPermission(tokenScopeList, orgId, 'o', 'signinconf:r') || hasPermission(tokenScopeList, orgId, 'o', 'signoutconf:r'),
                    hasFieldsRead: hasPermission(tokenScopeList, orgId, 'o', 'fields:r'),
                    hasAgreementsRead: hasPermission(tokenScopeList, orgId, 'o', 'agreements:r'),
                    hasAccountRead: hasPermission(tokenScopeList, orgId, 'o', 'account:r'),
                    hasIntegrationRead: hasPermission(tokenScopeList, orgId, 'o', 'integration:r'),
                    hasRptVisitorRead: hasPermission(tokenScopeList, orgId, 'o', 'rptvisitor:r'),
                    hasRptTrendRead: hasPermission(tokenScopeList, orgId, 'o', 'rpttrend:r'),
                    hasRptRedFlagRead: hasPermission(tokenScopeList, orgId, 'o', 'rptredflag:r'),
                    hasRptHostRead: hasPermission(tokenScopeList, orgId, 'o', 'rpthost:r'),
                    hasRptActivityRead: hasPermission(tokenScopeList, orgId, 'o', 'rptactivity:r'),
                    hasRptGuestPassRead: hasPermission(tokenScopeList, orgId, 'o', 'rptguestpass:r'),
                };

                const routes = [
                    permissions.hasDashRead && '/dashboard',
                    permissions.hasVisitorsRead && '/visitors',
                    permissions.hasLocationRead && '/locations',
                    permissions.hasUsersRead && '/users/users',
                    permissions.hasRolesRead && '/users/roles',
                    permissions.hasKiosksRead && '/kiosks',
                    permissions.hasRedFlagConfRead && '/redFlag',
                    permissions.hasInvitationRead && '/configuration/invitations',
                    permissions.hasWorkflow && '/configuration/workflow',
                    permissions.hasFieldsRead && '/configuration/formFields',
                    permissions.hasAgreementsRead && '/configuration/agreements',
                    permissions.hasAccountRead && '/administration/account',
                    permissions.hasIntegrationRead && '/administration/integration',
                    permissions.hasRptVisitorRead && '/reports/visitorReport',
                    permissions.hasRptTrendRead && '/reports/repeatVisitors',
                    permissions.hasRptRedFlagRead && '/reports/redFlagReport',
                    permissions.hasRptGuestPassRead && '/reports/guestPassReport',
                    permissions.hasRptHostRead && '/reports/hostReport',
                    permissions.hasRptActivityRead && '/reports/activityLog',
                ].filter(Boolean);

                if (routes.length > 0) {
                    navigate(routes[0], { replace: true });
                } else {
                    navigate('/support', { replace: true });
                }
            } catch (error) {
                console.error('Avigilon Alta Login Failed:', error);
                notification.error({
                    message: 'Login Failed',
                    description: 'Unable to log in via Avigilon Alta. Please try again.',
                    placement: 'bottomRight',
                });
                navigate('/login', { replace: true });
            } finally {
                setIsLoadingViaAlta(false);
            }
        };

        loginViaAlta();
    }, [auth.data, dispatch, navigate, searchParams]);

    return (
        <>
            <ConfigProvider theme={
                isLoginRoute
                    ? THEME.dark
                    : isNonThemedRoute
                        ? THEME.light
                        : isDarkMode
                            ? THEME.dark
                            : THEME.light
            }>
                <>

                    {isInitializing || isLoadingViaAlta ? (
                        <FullLoader />
                    ) : (
                        <Routes>
                            <Route path="/resetPassword" element={<ResetPassword />} />
                            <Route path="/resetPassword/:token" element={<ResetPassword />} />
                            <Route path="/register" element={<Register />} />
                            <Route path="/register/completed" element={<RegisterCompleted />} />
                            <Route path="/guestPass" element={<GuestPass />} />
                            <Route path="/guestPass/:token" element={<GuestPass />} />
                            <Route path="/signUp" element={<SignUp />} />
                            <Route path="/login" element={<LogIn />} />
                            <Route
                                path="/*"
                                element={
                                    authData ? (
                                        globalUser && globalLocation ? (
                                            <AppLayout />
                                        ) : (
                                            <FullLoader />
                                        )
                                    ) : (
                                        <LogIn />
                                    )
                                }
                            />
                        </Routes>
                    )}
                </>
            </ConfigProvider>

        </>
    );
};

export default App;