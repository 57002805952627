import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios, { AxiosError } from 'axios';
import { ApiResponse, ApiErrorResponse } from 'types/apiTypes';
import { Visit, Note, UpdateVisitPayload, ProcessVisitorExitPayload, CreateVisitNotePayload, VisitorAgreement, DocumentCheck, Visitor } from 'types/visitTypes';
import { getVisitDateTime } from 'utils/visitorsHelper';
import dayjs from 'dayjs';
import { hasPermission } from 'utils/utils';
import { AppDispatch, RootState } from 'store/store';

interface VisitsState {
    visits: Visit[];
    // visitsToday: Visit[];
    selectedVisit: Visit | null;
    selectedVisitor: Visitor | null;
    error: string | null;
    notes: ApiResponse<Note>;
    agreements: ApiResponse<VisitorAgreement>;
    documentsCheck: ApiResponse<DocumentCheck>;
    visitsStartDate: string | undefined;
    visitsEndDate: string | undefined;
    fetchVisitsLoading: boolean;
    fetchVisitsTodayLoading: boolean;
    describeVisitLoading: boolean;
    updateVisitLoading: boolean;
    updateVisitorLoading: boolean;
    fetchVisitNotesLoading: boolean;
    fetchAgreementsLoading: boolean;
    addVisitNoteLoading: boolean;
    processVisitorExitLoading: boolean;
    checkDocumentsLoading: boolean;
}

const initialState: VisitsState = {
    visits: [],
    // visitsToday: [],
    selectedVisit: null,
    selectedVisitor: null,
    error: null,
    notes: {
        data: [],
        meta: null,
        totalCount: 0,
        filteredCount: 0,
    },
    agreements: {
        data: [],
        meta: null,
        totalCount: 0,
        filteredCount: 0,
    },
    documentsCheck: {
        data: [],
        meta: null,
        totalCount: 0,
        filteredCount: 0,
    },
    visitsStartDate: undefined,
    visitsEndDate: undefined,
    fetchVisitsLoading: false,
    fetchVisitsTodayLoading: false,
    describeVisitLoading: false,
    updateVisitLoading: false,
    updateVisitorLoading: false,
    fetchVisitNotesLoading: false,
    fetchAgreementsLoading: false,
    addVisitNoteLoading: false,
    processVisitorExitLoading: false,
    checkDocumentsLoading: false
};

// Retrieve the base URL from environment variables
const apiUrl = process.env.REACT_APP_BACKEND_URL;

if (!apiUrl) {
    console.error('REACT_APP_BACKEND_URL is not set');
}

export const fetchVisits = createAsyncThunk<Visit[], { orgId: number; firstName?: string; lastName?: string; }, { rejectValue: string, state: RootState, dispatch: AppDispatch }>(
    'visitors/fetchVisits',
    async ({ orgId, firstName, lastName }, { rejectWithValue, getState, dispatch }) => {
        try {
            // Access state using getState
            const state = getState();

            // Declare variables from state
            const { selectedVisit, selectedVisitor, visitsStartDate, visitsEndDate } = state.visits;
            const globalUserId = state.users.globalUser?.id;
            const globalLocationId = state.locations.globalLocation?.id;
            const tokenScopeList = state.auth.auth.data[0]?.tokenScopeList || [];
            const hasAllVisitorsRead = hasPermission(tokenScopeList, orgId, 'o', 'allvisitors:r');

            const response = await axios.get<{ data: Visit[] }>(`${apiUrl}/orgs/${orgId}/visitor`, {
                headers: {
                    Authorization: `${localStorage.getItem('authToken')}`,
                    Accept: 'application/json',
                },
                params: {
                    siteId: globalLocationId !== -1 ? globalLocationId : undefined,
                    startDate: visitsStartDate,
                    endDate: visitsEndDate,
                    firstName,
                    lastName
                }
            });

            // Filter and sort visits based on permissions and user ID
            const filteredAndSortedVisits = response.data.data
                .filter((visit: Visit) => {
                    return hasAllVisitorsRead || (globalUserId === visit.host.userId);
                })
                .sort((a: Visit, b: Visit) => {
                    const dateA = dayjs(getVisitDateTime(a, a.visitStatus.name));
                    const dateB = dayjs(getVisitDateTime(b, b.visitStatus.name));
                    return dateB.diff(dateA);
                });

            if (selectedVisit) {
                const newVisit = filteredAndSortedVisits.find((visit: Visit) => visit.id === selectedVisit.id);
                if (newVisit) {
                    dispatch(setSelectedVisit(newVisit));
                    if (selectedVisitor) {
                        const newVisitor = newVisit?.visitors.find((visitor: Visitor) => visitor.id === selectedVisitor.id);
                        dispatch(setSelectedVisitor(newVisitor));
                    }
                } else {
                    const response = await axios.get<{ data: Visit[] }>(`${apiUrl}/orgs/${orgId}/visitor/${selectedVisit.id}`, {
                        headers: {
                            Authorization: `${localStorage.getItem('authToken')}`,
                            Accept: 'application/json',
                        }
                    });
                    dispatch(setSelectedVisit(response.data.data[0]));
                    if (selectedVisitor) {
                        const newVisitor = response.data.data[0].visitors.find((visitor: Visitor) => visitor.id === selectedVisitor.id);
                        dispatch(setSelectedVisitor(newVisitor));
                    }
                }
            }
            // Return just the filtered and sorted visits array
            return filteredAndSortedVisits;
        } catch (error) {
            return rejectWithValue('Failed to fetch visits');
        }
    }
);

// export const fetchVisitsToday = createAsyncThunk<Visit[], { orgId: number; firstName?: string; lastName?: string; }, { rejectValue: string, state: RootState, dispatch: AppDispatch }>(
//     'visitors/fetchVisitsToday',
//     async ({ orgId, firstName, lastName }, { rejectWithValue, getState, dispatch }) => {
//         try {
//             // Access state using getState
//             const state = getState();

//             // Declare variables from state
//             const { selectedVisit, selectedVisitor } = state.visits;
//             const globalUserId = state.users.globalUser?.id;
//             const globalLocationId = state.locations.globalLocation?.id;
//             const tokenScopeList = state.auth.auth.data[0]?.tokenScopeList || [];
//             const hasAllVisitorsRead = hasPermission(tokenScopeList, orgId, 'o', 'allvisitors:r');

//             const response = await axios.get<{ data: Visit[] }>(`${apiUrl}/orgs/${orgId}/visitor`, {
//                 headers: {
//                     Authorization: `${localStorage.getItem('authToken')}`,
//                     Accept: 'application/json',
//                 },
//                 params: {
//                     siteId: globalLocationId !== -1 ? globalLocationId : undefined,
//                     startDate: dayjs().startOf('day').format(DATE_TIME_FORMAT),
//                     endDate: dayjs().endOf('day').format(DATE_TIME_FORMAT),
//                     firstName,
//                     lastName
//                 }
//             });

//             // Filter and sort visits based on permissions and user ID
//             const filteredAndSortedVisits = response.data.data
//                 .filter((visit: Visit) => {
//                     return hasAllVisitorsRead || (globalUserId === visit.host.userId);
//                 })
//                 .sort((a: Visit, b: Visit) => {
//                     const dateA = dayjs(getVisitDateTime(a, a.visitStatus.name));
//                     const dateB = dayjs(getVisitDateTime(b, b.visitStatus.name));
//                     return dateB.diff(dateA);
//                 });

//             if (selectedVisit) {
//                 const newVisit = filteredAndSortedVisits.find((visit: Visit) => visit.id === selectedVisit.id);
//                 if (newVisit) {
//                     dispatch(setSelectedVisit(newVisit));
//                     if (selectedVisitor) {
//                         const newVisitor = newVisit?.visitors.find((visitor: Visitor) => visitor.id === selectedVisitor.id);
//                         dispatch(setSelectedVisitor(newVisitor));
//                     }
//                 } else {
//                     const response = await axios.get<{ data: Visit[] }>(`${apiUrl}/orgs/${orgId}/visitor/${selectedVisit.id}`, {
//                         headers: {
//                             Authorization: `${localStorage.getItem('authToken')}`,
//                             Accept: 'application/json',
//                         }
//                     });
//                     dispatch(setSelectedVisit(response.data.data[0]));
//                     if (selectedVisitor) {
//                         const newVisitor = response.data.data[0].visitors.find((visitor: Visitor) => visitor.id === selectedVisitor.id);
//                         dispatch(setSelectedVisitor(newVisitor));
//                     }
//                 }
//             }
//             // Return just the filtered and sorted visits array
//             return filteredAndSortedVisits;
//         } catch (error) {
//             return rejectWithValue('Failed to fetch visits');
//         }
//     }
// );

export const describeVisit = createAsyncThunk<ApiResponse<Visit>, { orgId: number; visitId: number }, { rejectValue: string }>(
    'visitors/describeVisit',
    async ({ orgId, visitId }, { rejectWithValue }) => {
        try {
            const response = await axios.get<ApiResponse<Visit>>(`${apiUrl}/orgs/${orgId}/visitor/${visitId}`, {
                headers: {
                    Authorization: `${localStorage.getItem('authToken')}`,
                    Accept: 'application/json',
                },
            });
            return response.data;
        } catch (error) {
            const axiosError = error as AxiosError<ApiErrorResponse>;
            return rejectWithValue(axiosError.response?.data.message || 'Failed to fetch visit details');
        }
    }
);


export const updateVisit = createAsyncThunk<void, { orgId: number; visitId: number; visitPayload: Partial<UpdateVisitPayload> }, { rejectValue: string; dispatch: any }>(
    'visitors/updateVisit',
    async ({ orgId, visitId, visitPayload }, { dispatch, rejectWithValue }) => {
        try {
            await axios.patch(`${apiUrl}/orgs/${orgId}/visitor/${visitId}`, visitPayload, {
                headers: {
                    Authorization: `${localStorage.getItem('authToken')}`,
                    Accept: 'application/json',
                },
            });
            dispatch(describeVisit({ orgId, visitId }));
            dispatch(fetchVisits({ orgId }));
        } catch (error) {
            const axiosError = error as AxiosError<ApiErrorResponse>;
            return rejectWithValue(axiosError.response?.data.message || 'Failed to update visit');
        }
    }
);

export const fetchVisitNotes = createAsyncThunk<ApiResponse<Note>, { orgId: number; visitId: number }, { rejectValue: string }>(
    'visitors/fetchVisitNotes',
    async ({ orgId, visitId }, { rejectWithValue }) => {
        try {
            const response = await axios.get<ApiResponse<Note>>(`${apiUrl}/orgs/${orgId}/visitor/${visitId}/visitorLog`, {
                headers: {
                    Authorization: `${localStorage.getItem('authToken')}`,
                    Accept: 'application/json',
                },
            });
            return response.data;
        } catch (error) {
            const axiosError = error as AxiosError<ApiErrorResponse>;
            return rejectWithValue(axiosError.response?.data.message || 'Failed to fetch visit notes');
        }
    }
);

export const createVisitNote = createAsyncThunk<void, { orgId: number; visitId: number; visitNote: CreateVisitNotePayload }, { rejectValue: string; dispatch: any }>(
    'visits/createVisitNote',
    async ({ orgId, visitId, visitNote }, { dispatch, rejectWithValue }) => {
        try {
            await axios.post(`${apiUrl}/orgs/${orgId}/visitor/${visitId}/visitorLog`, visitNote, {
                headers: {
                    Authorization: `${localStorage.getItem('authToken')}`,
                    Accept: 'application/json',
                }
            });
            dispatch(fetchVisitNotes({ orgId, visitId }));
        } catch (error) {
            const axiosError = error as AxiosError<ApiErrorResponse>;
            return rejectWithValue(axiosError.response?.data.message || 'Failed to add visit note');
        }
    }
);


export const fetchVisitorAgreements = createAsyncThunk<ApiResponse<VisitorAgreement>, { orgId: number; visitId: number, documentId?: number, visitorId?: number }, { rejectValue: string }>(
    'visitors/fetchVisitorAgreements',
    async ({ orgId, visitId, documentId, visitorId }, { rejectWithValue }) => {
        try {
            const response = await axios.get<ApiResponse<VisitorAgreement>>(`${apiUrl}/orgs/${orgId}/visitor/${visitId}/visitorAgreement`, {
                headers: {
                    Authorization: `${localStorage.getItem('authToken')}`,
                    Accept: 'application/json',
                },
                params: {
                    documentId,
                    visitorId
                }
            });
            return response.data;
        } catch (error) {
            const axiosError = error as AxiosError<ApiErrorResponse>;
            return rejectWithValue(axiosError.response?.data.message || 'Failed to fetch visitor agreements');
        }
    }
);

export const processVisitorExit = createAsyncThunk<void, { orgId: number; visitId: number; exitPayload: Partial<ProcessVisitorExitPayload> }, { rejectValue: string; dispatch: any }>(
    'visits/processVisitorExit',
    async ({ orgId, visitId, exitPayload }, { dispatch, rejectWithValue }) => {
        try {
            await axios.post(`${apiUrl}/orgs/${orgId}/visitor/${visitId}/visitorExit`, exitPayload, {
                headers: {
                    Authorization: `${localStorage.getItem('authToken')}`,
                    Accept: 'application/json',
                },
            });
            dispatch(describeVisit({ orgId, visitId }));
            dispatch(fetchVisits({ orgId }));
        } catch (error) {
            const axiosError = error as AxiosError<ApiErrorResponse>;
            return rejectWithValue(axiosError.response?.data.message || 'Failed to process visitor exit');
        }
    }
);

export const checkDocuments = createAsyncThunk<ApiResponse<DocumentCheck>, { orgId: number; visitId: number }, { rejectValue: string }>(
    'visitors/fetchVisitorDocuments',
    async ({ orgId, visitId }, { rejectWithValue }) => {
        try {
            const response = await axios.get<ApiResponse<DocumentCheck>>(`${apiUrl}/orgs/${orgId}/visitor/${visitId}/documentCheck`, {
                headers: {
                    Authorization: `${localStorage.getItem('authToken')}`,
                    Accept: 'application/json',
                },
            });
            return response.data;
        } catch (error) {
            const axiosError = error as AxiosError<ApiErrorResponse>;
            return rejectWithValue(axiosError.response?.data.message || 'Failed to check visitor documents');
        }
    }
);

const visitsSlice = createSlice({
    name: 'visitors',
    initialState,
    reducers: {
        setVisitsStartDate(state, action) {
            state.visitsStartDate = action.payload;
        },
        setVisitsEndDate(state, action) {
            state.visitsEndDate = action.payload;
        },
        setSelectedVisit(state, action) {
            state.selectedVisit = action.payload;
        },
        setSelectedVisitor(state, action) {
            state.selectedVisitor = action.payload;
        },
        clearVisits(state) {
            state.visits = initialState.visits;
        },
        clearSelectedVisit(state) {
            state.selectedVisit = initialState.selectedVisit;
        },
        clearNotes(state) {
            state.notes = initialState.notes;
        },
        clearDocumentsCheck(state) {
            state.documentsCheck = initialState.documentsCheck;
        },
        clearVisitsState() {
            return initialState;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchVisits.pending, (state) => {
                // state.fetchVisitsLoading = true;
                state.error = null;
            })
            .addCase(fetchVisits.fulfilled, (state, action) => {
                state.fetchVisitsLoading = false;
                state.visits = action.payload;
            })
            .addCase(fetchVisits.rejected, (state, action) => {
                state.fetchVisitsLoading = false;
                state.error = action.payload || 'Failed to fetch visits';
            })
            // .addCase(fetchVisitsToday.pending, (state) => {
            //     // state.fetchVisitsTodayLoading = true;
            //     state.error = null;
            // })
            // .addCase(fetchVisitsToday.fulfilled, (state, action) => {
            //     state.fetchVisitsTodayLoading = false;
            //     state.visitsToday = action.payload;
            // })
            // .addCase(fetchVisitsToday.rejected, (state, action) => {
            //     state.fetchVisitsTodayLoading = false;
            //     state.error = action.payload || 'Failed to fetch visits today';
            // })
            .addCase(describeVisit.pending, (state) => {
                state.describeVisitLoading = true;
                state.error = null;
            })
            .addCase(describeVisit.fulfilled, (state, action) => {
                state.describeVisitLoading = false;
                state.selectedVisit = action.payload.data[0];
            })
            .addCase(describeVisit.rejected, (state, action) => {
                state.describeVisitLoading = false;
                state.error = action.payload || 'Failed to fetch visit details';
            })
            .addCase(updateVisit.pending, (state) => {
                state.updateVisitLoading = true;
                state.error = null;
            })
            .addCase(updateVisit.fulfilled, (state) => {
                state.updateVisitLoading = false;
            })
            .addCase(updateVisit.rejected, (state, action) => {
                state.updateVisitLoading = false;
                state.error = action.payload || 'Failed to update visit';
            })
            .addCase(fetchVisitNotes.pending, (state) => {
                state.fetchVisitNotesLoading = true;
                state.error = null;
            })
            .addCase(fetchVisitNotes.fulfilled, (state, action) => {
                state.fetchVisitNotesLoading = false;
                state.notes = action.payload;
            })
            .addCase(fetchVisitNotes.rejected, (state, action) => {
                state.fetchVisitNotesLoading = false;
                state.error = action.payload || 'Failed to fetch visit notes';
            })
            .addCase(createVisitNote.pending, (state) => {
                state.addVisitNoteLoading = true;
                state.error = null;
            })
            .addCase(createVisitNote.fulfilled, (state) => {
                state.addVisitNoteLoading = false;
            })
            .addCase(createVisitNote.rejected, (state, action) => {
                state.addVisitNoteLoading = false;
                state.error = action.payload || 'Failed to add visit note';
            })
            .addCase(fetchVisitorAgreements.pending, (state) => {
                state.fetchAgreementsLoading = true;
                state.error = null;
            })
            .addCase(fetchVisitorAgreements.fulfilled, (state, action) => {
                state.fetchAgreementsLoading = false;
                state.agreements = action.payload;
            })
            .addCase(fetchVisitorAgreements.rejected, (state, action) => {
                state.fetchAgreementsLoading = false;
                state.error = action.payload || 'Failed to fetch visitor agreements';
            })
            .addCase(processVisitorExit.pending, (state) => {
                state.processVisitorExitLoading = true;
                state.error = null;
            })
            .addCase(processVisitorExit.fulfilled, (state) => {
                state.processVisitorExitLoading = false;
            })
            .addCase(processVisitorExit.rejected, (state, action) => {
                state.processVisitorExitLoading = false;
                state.error = action.payload || 'Failed to process visitor exit';
            })
            .addCase(checkDocuments.pending, (state) => {
                state.checkDocumentsLoading = true;
                state.error = null;
            })
            .addCase(checkDocuments.fulfilled, (state, action) => {
                state.checkDocumentsLoading = false;
                state.documentsCheck = action.payload;
            })
            .addCase(checkDocuments.rejected, (state, action) => {
                state.checkDocumentsLoading = false;
                state.error = action.payload || 'Failed to fetch visitor documents';
            });
    },
});

export const { setVisitsStartDate, setVisitsEndDate, setSelectedVisit, setSelectedVisitor,
    clearVisits, clearSelectedVisit, clearNotes, clearDocumentsCheck, clearVisitsState } = visitsSlice.actions;
export default visitsSlice.reducer;
