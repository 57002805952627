import React, { useEffect, useState } from 'react';
import { List, notification, Spin } from 'antd';
import { IOnSubmitArgs, OpForm } from 'components/customAntd/DLS/OpForm/OpForm';
import { OpSection } from 'components/customAntd/DLS/OpSection/OpSection';
import { OpSteps } from 'components/customAntd/DLS/OpSteps/OpSteps';
import { OpButton } from 'components/customAntd/DLS/OpButton/OpButton';
import { Role } from 'types/roleTypes';
import { AppDispatch, RootState } from 'store/store';
import { useDispatch, useSelector } from 'react-redux';
import { getRequest, postRequest } from 'api/apiClient';
import axios from 'axios';
import { LoadingOutlined } from '@ant-design/icons';
import { useConfirmModal } from 'utils/customHooks/useConfirmModal';
import { triggerAppIntegrationUpdate } from 'store/slices/appIntegrationSlice';

interface ConnectFormData {
    msiAltaToken: string;
    msiAltaTokenExpUtc: string;
}

interface OrgFormData {
    allowMsiAltaLogin?: number;
    roleId?: number;
    msiAltaUserId?: number;
    importUsers?: number;
    msiAltaOrgId?: number;
    syncHours?: number;
}

interface IntegrationInstallProps {
    setIsStepsOpen: (open: boolean) => void;
    setIsInstalled: (open: boolean) => void;
}

const IntegrationInstall: React.FC<IntegrationInstallProps> = ({ setIsStepsOpen, setIsInstalled }) => {
    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);
    const dispatch: AppDispatch = useDispatch();

    const [current, setCurrent] = useState(0);
    const [orgOptions, setOrgOptions] = useState<{ value: string; label: string, userId: number }[]>([]);
    const [entryIdOptions, setEntryIdOptions] = useState<{ value: string; label: string }[]>([]);
    // eslint-disable-next-line
    const [roles, setRoles] = useState<Role[]>([]);

    const [connectFormData, setConnectFormData] = useState<ConnectFormData>({ msiAltaToken: '', msiAltaTokenExpUtc: '' });
    const [orgFormData, setOrgFormData] = useState<OrgFormData | undefined>(undefined);;

    // eslint-disable-next-line
    const [isImportUsersEnabled, setIsImportUsersEnabled] = useState(false);
    const [isGuestPassGenerationEnabled, setIsGuestPassGenerationEnabled] = useState(false);

    const [loading, setLoading] = useState(false);

    const confirmModal = useConfirmModal();

    const [connectForm] = OpForm.useForm();
    const [orgForm] = OpForm.useForm();
    const [visitorForm] = OpForm.useForm();

    useEffect(() => {
        const fetchRoles = async () => {
            setLoading(true);
            try {
                const roles = await getRequest(`/orgs/${orgId}/roles`);
                const filteredRoles = roles.data.filter((role: Role) => role.status === 1);
                setRoles(filteredRoles);
            } catch (error) {
                console.log("Failed to fetch roles.");
            } finally {
                setLoading(false);
            }
        };
        fetchRoles();
    }, [orgId]);

    // Monitor the update of connectFormData
    useEffect(() => {
        console.log("Updated connectFormData:", connectFormData);
    }, [connectFormData]);

    // Monitor the update of orgFormData
    useEffect(() => {
        console.log("Updated orgFormData:", orgFormData);
    }, [orgFormData]);

    const next = async () => {
        if (current === 0) {
            try {
                await connectForm.validateFields();
                connectForm.submit();
            } catch (error) {
                return; // Do not proceed if validation fails
            }
        } else if (current === 1) {
            try {
                await orgForm.validateFields();
                orgForm.submit();
            } catch (error) {
                return; // Do not proceed if validation fails
            }
        } else if (current === 2) {
            try {
                await visitorForm.validateFields();
                visitorForm.submit();
            } catch (error) {
                return; // Do not proceed if validation fails
            }
        }
    };

    const prev = () => {
        setCurrent(current - 1);
    };

    const handleCancel = () => {
        confirmModal({
            title: ('Cancel Integration Installation'),
            content: ('Are you sure you want to cancel the integration installation?'),
            okText: ('Yes'),
            cancelText: ('No'),
            onOk: () => {
                setIsStepsOpen(false); // Go back to the main menu
            },
        });
    };

    // eslint-disable-next-line
    const handleBackToMain = () => {
        setIsStepsOpen(false); // Go back to the main menu
    };

    // eslint-disable-next-line
    const handleImportUsersChange = (checked: boolean) => {
        setIsImportUsersEnabled(checked);
        if (checked) {
            console.log("orgForm.getFieldValue('syncHours')", orgForm.getFieldValue('syncHours'));
            if (orgForm.getFieldValue('syncHours') === undefined) {
                orgForm.setFieldsValue({ syncHours: 24 });
            }
        }
    };

    const handleGuestPassEnabled = (checked: boolean) => {
        setIsGuestPassGenerationEnabled(checked);
        if (checked) {
            if (visitorForm.getFieldValue('guessPassBeforeMins') === undefined) {
                visitorForm.setFieldsValue({ guessPassBeforeMins: -1 });
            }
            if (visitorForm.getFieldValue('guestPassAfterMins') === undefined) {
                visitorForm.setFieldsValue({ guestPassAfterMins: -1 });
            }
        }
    };

    const handleConnectSubmit = (async ({ values }: IOnSubmitArgs) => {
        setLoading(true);
        try {
            const response = await axios.post('https://api.openpath.com/auth/login', values);
            const orgList = response.data.data.tokenScopeList
                .filter((scope: any) => scope.org.id !== null)
                .map((scope: any) => ({
                    value: scope.org.id,
                    label: scope.org.name,
                    userId: scope.user.id
                }));
            setOrgOptions(orgList);
            setConnectFormData({ msiAltaToken: response.data.data.token, msiAltaTokenExpUtc: response.data.data.expiresAt });
            setCurrent(1); // Proceed to Org step after successful connection
            if (orgList.length > 0) {
                setOrgFormData({
                    msiAltaOrgId: orgList[0].value,
                    msiAltaUserId: orgList[0].userId,
                });
            }
        } catch (error) {
            notification.error({
                message: 'Connection Failed',
                description: 'Failed to connect. Please check your credentials.',
                placement: 'bottomRight'
            });
        } finally {
            setLoading(false);
        }
    });

    const handleOrgSubmit = (async ({ values }: IOnSubmitArgs) => {
        setLoading(true);
        try {
            const response = await axios.get(`https://api.openpath.com/orgs/${orgForm.getFieldValue('msiAltaOrgId')}/entries`, {
                headers: {
                    Authorization: `${connectFormData.msiAltaToken}`,
                },
            });
            const defaultEntryList = response.data.data
                .map((defaultEntry: any) => ({
                    value: defaultEntry.id,
                    label: defaultEntry.name,
                }));
            setEntryIdOptions(defaultEntryList);

            const formattedValues = {
                allowMsiAltaLogin: values.allowMsiAltaLogin && values.allowMsiAltaLogin === true ? 1 : 0,
                roleId: values.roleId,
                importUsers: values.importUsers && values.importUsers === true ? 1 : 0,
                msiAltaOrgId: values.msiAltaOrgId,
                msiAltaUserId: orgForm.getFieldValue('msiAltaUserId'),
                syncHours: values.syncHours ? values.syncHours : 0,
            };
            setOrgFormData(formattedValues);
            setCurrent(2);
        } catch (error) {
            notification.error({
                message: 'Connection Failed',
                description: 'Failed to connect. Please try again.',
                placement: 'bottomRight'
            });
        } finally {
            setLoading(false);
        }
    });

    const handleVisitorSubmit = (async ({ values }: IOnSubmitArgs) => {
        setLoading(true);
        const formattedValues = {
            msiAltaEntrieIds: values.msiAltaEntrieIds,
            guestPassEnabled: values.guestPassEnabled && values.guestPassEnabled === true ? 1 : 0,
            guessPassBeforeMins: values.guessPassBeforeMins === undefined ? -1 : values.guessPassBeforeMins,
            guestPassAfterMins: values.guestPassAfterMins === undefined ? -1 : values.guestPassAfterMins,
        };

        console.log("formattedValues", formattedValues);
        try {
            const combinedData = {
                ...connectFormData,
                ...orgFormData,
                ...formattedValues
            };
            console.log(combinedData);
            await postRequest(`/orgs/${orgId}/integrationMsiAlta`, combinedData);
            // setCurrent(3);
            setIsStepsOpen(false);

            dispatch(triggerAppIntegrationUpdate());

            notification.success({
                message: 'Success',
                description: 'Integration created successfully.',
                placement: 'bottomRight',
            });
            setIsInstalled(true);
        } catch {
            notification.error({
                message: 'Error',
                description: 'An error occurred during the submission.',
                placement: 'bottomRight',
            });
        } finally {
            setLoading(false);
        }
    });

    const steps = [
        {
            title: 'Connect',
            content: (
                <OpSection>
                    <OpForm
                        form={connectForm}
                        onSubmit={handleConnectSubmit}
                        initialValues={{}}
                        defaultButtons={false}
                        hasError={false}
                    >
                        <OpForm.Input
                            name="email"
                            label="Email (User must have admin credentials)"
                            rules={[{ required: true, message: 'Please enter your email.' }]}
                            placeholder="Enter your email"
                        />
                        <OpForm.PasswordInput
                            name="password"
                            label="Password"
                            rules={[{ required: true, message: 'Please enter your password.' }]}
                            placeholder="Enter your password"
                        />
                    </OpForm>
                </OpSection>
            ),
        },
        {
            title: 'Org',
            content: (
                <OpForm
                    form={orgForm}
                    onSubmit={handleOrgSubmit}
                    defaultButtons={false}
                    hasError={false}
                    initialValues={orgFormData}
                >
                    <List
                        header={"ORGANIZATION"}
                        dataSource={[
                            { name: 'msiAltaOrgId', label: 'Select the organization to connect with.' }
                        ]}
                        bordered
                        style={{ marginBottom: '16px' }}
                        renderItem={item => (
                            <List.Item>
                                <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                    <OpForm.Select
                                        name={item.name}
                                        label={item.label}
                                        placeholder={'Select Organization'}
                                        options={orgOptions}
                                        rules={[{ required: true, message: 'Please select an organization.' }]}
                                        onChange={(value) => {
                                            const selectedOrg = orgOptions.find((org) => org.value === value);
                                            if (selectedOrg) {
                                                orgForm.setFieldsValue({
                                                    msiAltaUserId: selectedOrg.userId, // Store the userId in the form
                                                });
                                            }
                                        }}
                                    />
                                </div>
                            </List.Item>
                        )}
                    />
                    {/* <List
                        header={"USERS"}
                        dataSource={[
                            { label: 'Sync Users', name: 'importUsers' },
                            ...(isImportUsersEnabled ? [
                                { label: 'Sync Interval', name: 'syncHours' },
                                { label: 'Default User Role', name: 'roleId' },
                                { label: 'Enable Avigilon Alta log in', name: 'allowMsiAltaLogin' },
                            ] : []),
                        ]}
                        bordered
                        renderItem={item => (
                            <List.Item style={{ width: '100%' }}>
                                {item.name === 'syncHours' ? (
                                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                        <OpForm.Select
                                            name={item.name}
                                            label={item.label}
                                            placeholder={'Select Interval'}
                                            options={[
                                                { value: 24, label: 'Daily' },
                                                { value: 48, label: 'Every 2 Days' },
                                                { value: 168, label: 'Weekly' },
                                            ]}
                                        />
                                    </div>
                                ) : item.name === 'roleId' ? (
                                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                        <OpForm.Select
                                            name={item.name}
                                            label={item.label}
                                            placeholder={'Select User Role'}
                                            options={roles.map((role: Role) => ({ label: role.name, value: role.id }))}
                                        />
                                    </div>
                                ) : (
                                    <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                        <OpForm.Switch
                                            name={item.name}
                                            onChange={
                                                item.name === 'importUsers'
                                                    ? handleImportUsersChange : undefined
                                            }
                                        />
                                        <span style={{ marginLeft: 8 }}>{item.label}</span>
                                    </div>
                                )}
                            </List.Item>
                        )}
                    /> */}
                </OpForm>
            ),
        },
        {
            title: 'Visitor',
            content: (
                <OpForm
                    form={visitorForm}
                    onSubmit={handleVisitorSubmit}
                    defaultButtons={false}
                    hasError={false}
                >
                    <List
                        header={"VISITOR"}
                        dataSource={[
                            { label: 'Generate Guest Passes', name: 'guestPassEnabled' },
                            ...(isGuestPassGenerationEnabled ? [
                                // { label: 'Automatic Guest Pass Generation', name: 'guessPassBeforeMins' },
                                // { label: 'Automatic Guest Pass Expiration', name: 'guestPassAfterMins' },
                                { label: 'Default Entries', name: 'msiAltaEntrieIds' }
                            ] : []),
                        ]}
                        bordered
                        renderItem={item => (
                            <List.Item style={{ width: '100%' }}>
                                {item.name === 'guessPassBeforeMins' ? (
                                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                        <OpForm.Select
                                            name={item.name}
                                            label={item.label}
                                            placeholder={'Select Interval'}
                                            options={[
                                                { value: -1, label: 'Manual' },
                                                { value: 0, label: 'At Sign-In' },
                                                { value: 10, label: '10 Minutes before schedule' },
                                                { value: 60, label: '1 Hour before schedule' },
                                                { value: 1440, label: '1 Day before schedule' },
                                            ]}
                                        />
                                    </div>
                                ) : item.name === 'guestPassAfterMins' ? (
                                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                        <OpForm.Select
                                            name={item.name}
                                            label={item.label}
                                            placeholder={'Select Interval'}
                                            options={[
                                                { value: -1, label: 'Manual' },
                                                { value: 10, label: '10 Minutes after schedule' },
                                                { value: 60, label: '1 Hour after schedule' },
                                                { value: 1440, label: '1 Day after schedule' },
                                            ]}
                                        />
                                    </div>
                                ) : item.name === 'msiAltaEntrieIds' ? (
                                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                        <OpForm.Select
                                            name={item.name}
                                            label={item.label}
                                            placeholder={'Select Entries'}
                                            mode="multiple"
                                            options={entryIdOptions}
                                        />
                                    </div>
                                ) : (
                                    <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                        <OpForm.Switch
                                            name={item.name}
                                            onChange={handleGuestPassEnabled}
                                        />
                                        <span style={{ marginLeft: 8 }}>{item.label}</span>
                                    </div>
                                )}
                            </List.Item>
                        )}
                    />
                </OpForm>
            ),
        },
    ];

    const items = steps.map((item) => ({ key: item.title, title: item.title }));

    return (
        <>
            {(loading) ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                    <Spin indicator={<LoadingOutlined spin />} />
                </div>
            ) : (
                <>
                    <OpSteps current={current} items={items} />

                    {
                        steps.map((item, index) => (
                            <div key={item.title} style={{ display: index === current ? 'block' : 'none', marginTop: 16 }}>
                                {item.content}
                            </div>
                        ))
                    }

                    <div style={{ marginTop: 24 }}>
                        {current < steps.length - 1 && (
                            <OpButton type="primary" onClick={() => next()}>
                                Next
                            </OpButton>
                        )}
                        {current === steps.length - 1 && (
                            <OpButton type="primary" htmlType="submit" onClick={() => next()}>
                                Done
                            </OpButton>
                        )}
                        {current === 0 && (
                            <OpButton style={{ margin: '0 8px' }} onClick={handleCancel}>
                                Cancel
                            </OpButton>
                        )}
                        {current > 0 ? (
                            <OpButton style={{ margin: '0 8px' }} onClick={() => prev()}>
                                Previous
                            </OpButton>
                        ) : null}
                    </div>
                </>
            )}
        </>
    );
};

export default IntegrationInstall;


