import dayjs from 'dayjs';
import { Kiosk, KioskAppearance, KioskStatus } from 'types/kioskTypes';
import { parseLocationResponse } from './locationParser';

export const parseKioskResponse = (response: any): Kiosk => ({
    id: Number(response.id),
    label: response.label,
    code: response.code,
    orgId: Number(response.orgId),
    site: parseLocationResponse(response.site),
    platform: response.platform,
    deviceType: response.deviceType,
    osVersion: response.osVersion,
    kioskVersion: response.kioskVersion,
    updatedAt: response.updatedAt ? dayjs(response.updatedAt).toISOString() : null,
    status: parseKioskStatusResponse(response.status),
    appearance: Array.isArray(response.appearance) ? response.appearance.map(parseKioskAppearanceResponse) : [],
});

export const parseKioskStatusResponse = (response: any): KioskStatus => ({
    id: Number(response.id),
    name: response.name,
});

export const parseKioskAppearanceResponse = (response: any): KioskAppearance => ({
    logoUrl: response.logoUrl,
    welcomeText: response.welcomeText,
    completedText: response.completedText,
    backgroundUrl: response.backgroundUrl,
    allowSignOut: response.allowSignOut,
    generateQR: response.generateQR,
    scanQR: response.scanQR,
    scanID: response.scanID,
    scanIdCamera: response.scanIdCamera,
    scanIdScanner: response.scanIdScanner,
    badgePrinter: response.badgePrinter,
});
