import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios, { AxiosError } from 'axios';
import { ApiErrorResponse, ApiResponse } from 'types/apiTypes';
import { VisitorSearch } from 'types/visitorSearchTypes';
import dayjs from 'dayjs';
import { DATE_TIME_FORMAT } from 'constants/dates';
import { RootState } from 'store/store';

interface VisitorSearchState {
    visitors: ApiResponse<VisitorSearch>;
    visitorsToday: ApiResponse<VisitorSearch>;
    searchVisitorsLoading: boolean;
    searchVisitorsTodayLoading: boolean;
    error: string | null;
    lastSearchParams: {
        orgId?: number;
        siteId?: number;
        searchString?: string;
        firstName?: string;
        lastName?: string;
        middleName?: string;
        startDate?: string;
        endDate?: string;
    };
}

const initialState: VisitorSearchState = {
    visitors: {
        data: [],
        meta: null,
        totalCount: 0,
        filteredCount: 0,
    },
    visitorsToday: {
        data: [],
        meta: null,
        totalCount: 0,
        filteredCount: 0,
    },
    searchVisitorsLoading: false,
    searchVisitorsTodayLoading: false,
    error: null,
    lastSearchParams: {},
};

const apiUrl = process.env.REACT_APP_BACKEND_URL;

if (!apiUrl) {
    console.error('REACT_APP_BACKEND_URL is not set');
}

export const searchVisitors = createAsyncThunk<
    ApiResponse<VisitorSearch>,
    {
        orgId: number;
        siteId: number;
        searchString?: string;
        firstName?: string;
        lastName?: string;
        middleName?: string;
        startDate?: string;
        endDate?: string;
    },
    { rejectValue: string }
>(
    'visitorSearch/searchVisitors',
    async ({ orgId, siteId, searchString, firstName, lastName, middleName, startDate, endDate }, { rejectWithValue }) => {
        try {
            const response = await axios.get<ApiResponse<VisitorSearch>>(
                `${apiUrl}/orgs/${orgId}/search/visitors`,
                {
                    params: { siteId, searchString, firstName, lastName, middleName, startDate, endDate },
                    headers: {
                        Authorization: `${localStorage.getItem('authToken')}`,
                        Accept: 'application/json',
                    },
                }
            );
            return response.data;
        } catch (error) {
            const axiosError = error as AxiosError<ApiErrorResponse>;
            return rejectWithValue(axiosError.response?.data.message || 'Failed to search visitors');
        }
    }
);

export const searchVisitorsToday = createAsyncThunk<ApiResponse<VisitorSearch>, { orgId: number }, { rejectValue: string, state: RootState }>(
    'visitorSearch/searchVisitorsToday',
    async ({ orgId }, { rejectWithValue, getState }) => {
        try {
            const state = getState();

            const globalLocationId = state.locations.globalLocation?.id;

            const response = await axios.get<ApiResponse<VisitorSearch>>(
                `${apiUrl}/orgs/${orgId}/search/visitors`,
                {
                    params: {
                        siteId: globalLocationId,
                        startDate: dayjs().startOf('day').format(DATE_TIME_FORMAT), // Start of today
                        endDate: dayjs().endOf('day').format(DATE_TIME_FORMAT),   // End of today
                    },
                    headers: {
                        Authorization: `${localStorage.getItem('authToken')}`,
                        Accept: 'application/json',
                    },
                }
            );
            return response.data;
        } catch (error) {
            const axiosError = error as AxiosError<ApiErrorResponse>;
            return rejectWithValue(axiosError.response?.data.message || 'Failed to search visitors');
        }
    }
);

const visitorSearchSlice = createSlice({
    name: 'visitorSearch',
    initialState,
    reducers: {
        clearVisitors(state) {
            state.visitors = initialState.visitors;
        },
        clearVisitorSearchState() {
            return initialState;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(searchVisitors.pending, (state) => {
                state.searchVisitorsLoading = true;
                state.error = null;
            })
            .addCase(searchVisitors.fulfilled, (state, action) => {
                state.searchVisitorsLoading = false;
                state.visitors = action.payload;
                state.lastSearchParams = action.meta.arg;
            })
            .addCase(searchVisitors.rejected, (state, action) => {
                state.searchVisitorsLoading = false;
                state.error = action.payload || 'Failed to search visitors';
            })
            .addCase(searchVisitorsToday.pending, (state) => {
                state.searchVisitorsTodayLoading = true;
                state.error = null;
            })
            .addCase(searchVisitorsToday.fulfilled, (state, action) => {
                state.searchVisitorsTodayLoading = false;
                state.visitorsToday = action.payload;
            })
            .addCase(searchVisitorsToday.rejected, (state, action) => {
                state.searchVisitorsTodayLoading = false;
                state.error = action.payload || "Failed to search today's visitors";
            });
    },
});

export const { clearVisitors, clearVisitorSearchState } = visitorSearchSlice.actions;
export default visitorSearchSlice.reducer;
