import React from "react";

const InvisitLogo: React.FC<{ width?: number; height?: number }> = ({
    width = 50,
    height = 50,
}) => {
    return (
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 213.5 214"
            width={width}
            height={height}
            style={{ display: "block" }}
        >
            <g>
                <path
                    d="M70.4,128.4c9.5,2.5,11.3,12.1,25,32c5,7.2,8.7,12.6,15.1,18.9c4.3,4.3,11.3,11,22.3,16.3c8.8,4.2,16.3,5.6,22.1,6.6
              c9.3,1.7,16.8,1.7,20.7,1.6c22.4-0.6,37.6-8.9,38-7.8c0.5,1.2-12.9,10.2-30.8,14.8c-30.7,8-57.2-1.3-65-4.1
              C94,198,79.9,184.2,73.7,178c-3.8-3.8-27.7-28.4-21.8-41.5c2.2-4.8,6.4-7.1,6.4-7.1C63.5,126.6,69.1,128.1,70.4,128.4z"
                    fill="currentColor"
                />
                <path
                    d="M138.7,157.9c-2.6-9.5,4.8-15.8,15.2-37.6c3.8-7.9,6.6-13.8,8.8-22.5
              c1.5-5.9,3.9-15.3,2.9-27.4c-0.8-9.7-3.3-16.9-5.3-22.5c-3.2-8.9-6.9-15.4-9-18.8C139.7,10,124.9,1,125.6,0.1
              c0.8-1,15.3,6.1,28.2,19.2c22.2,22.6,27.5,50.2,28.9,58.4c4.4,24.9-0.5,44-2.9,52.5c-1.4,5.2-10.7,38.2-25,39.6
              c-5.2,0.5-9.4-2-9.4-2C140.6,164.7,139,159.1,138.7,157.9z"
                    fill="currentColor"
                />
                <path
                    d="M131.2,81.7c-7,7-16.1,3.7-40.2,5.6c-8.7,0.7-15.3,1.2-23.9,3.6
              c-5.9,1.6-15.2,4.2-25.2,11.2c-8,5.5-13,11.3-16.8,15.8c-6.1,7.2-9.9,13.7-11.8,17.2C2.6,154.7,2.2,172,1.1,171.9
              c-1.3-0.2-2.4-16.2,2.5-34.1c8.4-30.5,29.7-48.9,36.1-54.2C59,67.3,78.1,62.1,86.6,59.8c5.2-1.4,38.4-9.8,46.8,1.8
              c3.1,4.3,2.9,9.1,2.9,9.1C136.2,76.6,132.1,80.7,131.2,81.7z"
                    fill="currentColor"
                />
            </g>
        </svg>
    );
};

export default InvisitLogo;
