import React, { useRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store/store';
import { fetchVisits, setVisitsEndDate, setVisitsStartDate } from 'store/slices/visitsSlice';
import { OpSpace } from 'components/customAntd/DLS/OpSpace/OpSpace';
import { OpPage } from 'components/customAntd/OpPage/OpPage';
import { Bar } from 'react-chartjs-2';
import dayjs from 'dayjs';
import { DATE_TIME_FORMAT } from 'constants/dates';
import { VISITORSHOURLYREPORTS_TOOLTIP } from 'constants/tooltip';
import 'chart.js/auto';
import STATUS from 'constants/status';
import { OpTable } from 'components/customAntd/DLS/OpTable/OpTable';
import { OpCard } from 'components/customAntd/DLS/OpCard/OpCard';
import { HourlyDetailsModal } from './HourlyDetailsModal';
import { OpTableRawColumnType } from 'components/customAntd/DLS/OpTableCore/OpTableCore';
import DateRangeLocationFilter2 from 'components/customAntd/DateRangeLocationFilter2';
import Loader from 'components/customAntd/Loader';

const VisitorsHourlyReport: React.FC = () => {
    const dispatch: AppDispatch = useDispatch();
    const globalLocationId = useSelector((state: RootState) => state.locations.globalLocation?.id);
    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);
    const { visits } = useSelector((state: RootState) => state.visits);

    const selectedLocationIdRef = useRef<number>(globalLocationId!);
    const startDateRef = useRef(dayjs().startOf('week').format(DATE_TIME_FORMAT));
    const endDateRef = useRef(dayjs().endOf('week').format(DATE_TIME_FORMAT));

    const [hourlyAverages, setHourlyAverages] = useState<number[]>(Array(24).fill(0));
    const [totalDailyAverage, setTotalDailyAverage] = useState<number>(0);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedHour, setSelectedHour] = useState<number | null>(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        processVisitorData();
        // eslint-disable-next-line
    }, [visits]);

    useEffect(() => {
        fetchVisitsData();
        // eslint-disable-next-line
    }, [dispatch, orgId]);

    const fetchVisitsData = async () => {
        setIsLoading(true);
        try {
            const yesterdayEnd = dayjs().subtract(1, 'day').endOf('day');

            if (dayjs(endDateRef.current).isAfter(yesterdayEnd)) {
                endDateRef.current = yesterdayEnd.format(DATE_TIME_FORMAT);
            }

            await dispatch(setVisitsStartDate(startDateRef.current));
            await dispatch(setVisitsEndDate(endDateRef.current));
            await dispatch(fetchVisits({ orgId }));
        } finally {
            setIsLoading(false);
        }
    };

    const handleDateRangeLocationFilter = (locationId: number, startDate: string, endDate: string) => {
        selectedLocationIdRef.current = locationId;
        startDateRef.current = startDate;

        const yesterdayEnd = dayjs().subtract(1, 'day').endOf('day');

        endDateRef.current = dayjs(endDate).isAfter(yesterdayEnd) ? yesterdayEnd.format(DATE_TIME_FORMAT) : endDate;

        fetchVisitsData();
    };


    const processVisitorData = () => {
        const startDate = dayjs(startDateRef.current);
        const endDate = dayjs(endDateRef.current);
        const totalDays = endDate.diff(startDate, 'day') + 1;

        const newHourlyTotals = Array(24).fill(0);
        let totalVisitors = 0;
        visits.forEach(visit => {
            visit.visitors.forEach(visitor => {
                if (
                    (visitor.status === STATUS.SIGNED_IN.id ||
                        visitor.status === STATUS.SIGNED_OUT.id) &&
                    visitor.signIn
                ) {
                    const signInTime = dayjs(visitor.signIn);

                    if (signInTime.isBetween(startDate, endDate, 'day', '[]')) {
                        const hour = signInTime.hour();
                        newHourlyTotals[hour]++;
                        totalVisitors++;
                    }
                }
            });
        });

        const newAverages = newHourlyTotals.map(total =>
            totalDays > 0 ? total / totalDays : 0
        );

        const totalDailyAverage = totalVisitors / totalDays;

        setHourlyAverages(newAverages);
        setTotalDailyAverage(totalDailyAverage);
    };

    const activeHours: number[] = hourlyAverages.reduce<number[]>(
        (acc, val, index) => (val > 0 ? [...acc, index] : acc),
        []
    ); const minHour = activeHours.length > 0 ? Math.max(0, Math.min(...activeHours) - 1) : 0;
    const maxHour = activeHours.length > 0 ? Math.min(23, Math.max(...activeHours) + 1) : 23;

    const chartData = {
        labels: Array.from({ length: maxHour - minHour + 1 }, (_, i) => {
            return dayjs().startOf('day').add(i + minHour, 'hour').format('h A');
        }),
        datasets: [{
            label: 'Average Visitors per Hour',
            data: hourlyAverages.slice(minHour, maxHour + 1),
            backgroundColor: 'rgba(54, 162, 235, 0.6)',
            borderColor: 'rgba(54, 162, 235, 1)',
            borderWidth: 1,
        }]
    };


    const chartOptions = {
        maintainAspectRatio: false,
        scales: {
            y: {
                beginAtZero: true,
                ticks: {
                    stepSize: 0.1,
                    callback: function (value: any) {
                        return Number(value).toFixed(1);
                    }
                },
            },
            x: {
                ticks: {
                    autoSkip: false,
                    maxRotation: 45,
                    minRotation: 45
                }
            }
        },
        plugins: {
            tooltip: {
                callbacks: {
                    label: function (context: any) {
                        return `Average: ${context.parsed.y.toFixed(1)} visitors`;
                    }
                }
            }
        }
    };

    // Table configuration
    const columns: OpTableRawColumnType[] = [
        {
            label: 'Hour',
            dataIndex: 'hour',
            key: 'hour',
            filter: {
                type: 'input',
            },
            sorter: (a, b) => (a.hour || '').localeCompare(b.hour || ''),
        },
        {
            label: 'Daily Average',
            dataIndex: 'dailyAverage',
            key: 'dailyAverage',
            filter: {
                type: 'input',
            },
            sorter: (a, b) => (a.dailyAverage || '').localeCompare(b.dailyAverage || ''),
        },
        {
            label: '% of Daily',
            dataIndex: 'percentage',
            key: 'percentage',
            filter: {
                type: 'input',
            },
            sorter: (a, b) => (a.percentage || '').localeCompare(b.percentage || ''),
        },
    ];

    const prepareTableData = () => {
        return hourlyAverages.map((avg, hour) => {
            const startLabel = dayjs().startOf('day').add(hour, 'hour').format('h A');
            const endLabel = dayjs().startOf('day').add(hour + 1, 'hour').format('h A');

            const percentageOfDaily = totalDailyAverage > 0
                ? (avg / totalDailyAverage) * 100
                : 0;

            return {
                key: hour,
                hour: `${startLabel} - ${endLabel}`,
                dailyAverage: avg.toFixed(1),
                percentage: `${percentageOfDaily.toFixed(1)}%`
            };
        });
    };


    return (
        <OpPage title="Visitors by Hour Report" tooltip={VISITORSHOURLYREPORTS_TOOLTIP}>
            <OpSpace direction="vertical" size="middle" style={{ display: 'flex' }}>
                <DateRangeLocationFilter2
                    onDateRangeLocationFilter={handleDateRangeLocationFilter}
                    initialStartDate={startDateRef.current}
                    initialEndDate={endDateRef.current}
                />
                <OpCard type="inner" title="Visitors By Hour">
                    {isLoading ? (
                        <Loader />
                    ) : (
                        <div style={{ width: '100%', height: '300px' }}>
                            <Bar data={chartData} options={chartOptions} />
                        </div>
                    )}
                </OpCard>

                <OpTable
                    dataSource={prepareTableData()}
                    columns={columns}
                    pagination={false}
                    allowGlobalSearch={false}
                    loading={isLoading}
                    label={'Averages by Hour'}
                    rowKey="key"
                    rowActions={{
                        onEditClick: (record: any) => {
                            setSelectedHour(record.key);
                            setIsModalOpen(true);
                        }
                    }}
                />

                {(isModalOpen) && (
                    <HourlyDetailsModal
                        visible={isModalOpen}
                        onCancel={() => setIsModalOpen(false)}
                        visits={visits}
                        selectedHour={selectedHour}
                        initialStartDate={startDateRef.current}
                        initialEndDate={endDateRef.current}
                    />
                )}
            </OpSpace>
        </OpPage>
    );
};

export default VisitorsHourlyReport;
