import React from 'react';
import { SvgWrapper, SvgWrapperProps } from 'components/customAntd/inVision - Platinum Assets/svgs/SvgWrapper';

interface HomeFilledSvgProps extends Omit<SvgWrapperProps, 'children'> { }

export const HomeFilledSvg = ({ ...svgProps }: HomeFilledSvgProps) => (
  <SvgWrapper viewBox="0 0 18 18" {...svgProps}>
    <path d="M15.195 6.16197L10.617 2.11813C9.7 1.30664 8.3 1.293 7.369 2.08403L2.847 5.89601C2.308 6.35291 2 7.00756 2 7.70312V14.7952C2 15.7362 2.784 16.5 3.75 16.5H5.9C6.45229 16.5 6.9 16.0523 6.9 15.5V11.3855C6.9 10.8195 7.369 10.3626 7.95 10.3626H10.05C10.631 10.3626 11.1 10.8195 11.1 11.3855V15.5C11.1 16.0523 11.5477 16.5 12.1 16.5H14.25C15.216 16.5 16 15.7362 16 14.7952V7.92816C16 7.25305 15.706 6.61204 15.195 6.16197Z" />
  </SvgWrapper>
);
