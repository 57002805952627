import React, { useEffect, useState } from 'react';
import { OpPage } from 'components/customAntd/OpPage/OpPage';
import RolesTable from './RolesTable';
import RolesDrawer from './RolesDrawer';
import { ROLES_TOOLTIP } from 'constants/tooltip';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { Role } from 'types/roleTypes';
import { getRequest } from 'api/apiClient';

const Roles: React.FC = () => {
    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);

    const [drawerOpen, setDrawerOpen] = useState(false);
    const [roles, setRoles] = useState<Role[]>([]);
    const [selectedRole, setSelectedRole] = useState<Role | null>(null);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const roles = await getRequest(`/orgs/${orgId}/roles`);
                const filteredRoles = roles.data.filter((role: Role) => role.status === 1);
                setRoles(filteredRoles);
            } catch (error) {
                console.log("Failed to fetch data.");
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, [orgId]);

    const handleEditClick = (role: Role) => {
        setSelectedRole(role);
        setDrawerOpen(true);
    };

    const handleAddClick = () => {
        setSelectedRole(null);
        setDrawerOpen(true);
    };

    const handleDrawerClose = () => {
        setDrawerOpen(false);
        setSelectedRole(null);
    };

    return (
        <OpPage title="User Roles" tooltip={ROLES_TOOLTIP}>
            <RolesTable
                roles={roles}
                loading={loading}
                setRoles={setRoles}
                handleEditClick={handleEditClick}
                handleAddClick={handleAddClick}
            />
            <RolesDrawer
                open={drawerOpen}
                selectedRole={selectedRole}
                onClose={handleDrawerClose}
                setRoles={setRoles}
            />
        </OpPage>
    );
}

export default Roles;
