import { List, notification } from "antd";
import { Visitor } from "types/visitTypes";
import warningIcon from "images/icons/warning.svg";
import { useEffect, useState } from "react";
import { formatFullName, hasPermission } from "utils/utils";
import { getStatusColor, getStatusNameById, isVisitorRedFlagged, profileIcon } from "utils/visitorsHelper";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "store/store";
import { useConfirmModal } from "utils/customHooks/useConfirmModal";
import { MinusCircleOutlined, PlusOutlined, EyeOutlined } from '@ant-design/icons';
import { deleteRequest, postRequest } from "api/apiClient";
import STATUS from "constants/status";
import { OpButton } from "components/customAntd/DLS/OpButton/OpButton";
import VisitorListModal from "./VisitorListModal";
import { fetchVisits, setSelectedVisitor } from "store/slices/visitsSlice";
import { searchVisitorsToday } from "store/slices/visitorSearchSlice";
import { fetchRedFlags } from "store/slices/redFlagSlice";
import Loader from "components/customAntd/Loader";

interface VisitorListProps {
    openProfileDrawer: () => void;
    closeProfileDrawer: () => void;
}

const VisitorList: React.FC<VisitorListProps> = ({ openProfileDrawer, closeProfileDrawer }) => {
    const dispatch: AppDispatch = useDispatch();
    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);
    const globalUserId = useSelector((state: RootState) => state.users.globalUser?.id);
    const tokenScopeList = useSelector((state: RootState) => state.auth.auth.data[0]?.tokenScopeList || []);
    const { selectedVisit } = useSelector((state: RootState) => state.visits);
    const redFlags = useSelector((state: RootState) => state.redFlags.redFlags);

    const hasInviteWrite = hasPermission(tokenScopeList, orgId, 'o', 'invite:w');
    const hasAllvisitorsWrite = hasPermission(tokenScopeList, orgId, 'o', 'allvisitors:w');

    const [isVisitorListModalOpen, setIsVisitorListModalOpen] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const confirmModal = useConfirmModal();

    const showProfileDrawer = (visitor: Visitor) => {
        dispatch(setSelectedVisitor(visitor));
        openProfileDrawer();
    };

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                if (redFlags.length === 0) {
                    await dispatch(fetchRedFlags({ orgId }));
                }
            } catch (error) {
                console.log("Failed to fetch data.");
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, [dispatch, orgId, redFlags.length]);

    const addVisitor = (visitor: Visitor) => {
        // Add status field to the visitor object
        const visitorWithStatus = { ...visitor, status: STATUS.PENDING.id };
        const visitors = { "visitors": [visitorWithStatus] };
        const addVisitorsToVisit = async () => {
            try {
                await postRequest(`/orgs/${orgId}/visitor/${selectedVisit?.id}/visitors`, visitors);
                notification.success({
                    message: 'Success',
                    description: 'Visitor added successfully.',
                    placement: 'bottomRight',
                });
                dispatch(fetchVisits({ orgId }));
            } catch (error) {
                notification.error({
                    message: 'Error',
                    description: 'Failed to add visitor.',
                    placement: 'bottomRight',
                });
            } finally {
                setIsVisitorListModalOpen(false);
                setLoading(false);
            }
        };
        addVisitorsToVisit();
    };

    const deleteVisitor = (visitorId: number) => {
        if (selectedVisit?.visitors.length && selectedVisit?.visitors.length > 1) {
            confirmModal({
                title: 'Delete Visitor',
                content: 'Are you sure you want to delete this visitor? This can not be undone.',
                okText: ('Yes'),
                cancelText: ('No'),
                onOk: () => {
                    const removeVisitorFromVisit = async () => {
                        setLoading(true);
                        try {
                            await deleteRequest(`/orgs/${orgId}/visitor/${selectedVisit.id}/visitors/${visitorId}`);
                            notification.success({
                                message: 'Success',
                                description: 'Visitor deleted successfully.',
                                placement: 'bottomRight',
                            });
                            dispatch(fetchVisits({ orgId }));
                            dispatch(searchVisitorsToday({ orgId }));
                            closeProfileDrawer();
                        } catch (error) {
                            notification.error({
                                message: 'Error',
                                description: 'Failed to delete visitor.',
                                placement: 'bottomRight',
                            });
                        } finally {
                            setLoading(false);
                        }
                    };
                    removeVisitorFromVisit();
                },
            });
        } else {
            notification.error({
                message: 'Error',
                description: 'There must be at least one visitor in a visit.',
                placement: 'bottomRight',
            });
        }
    }

    return (
        <>
            {loading ? (
                <Loader />
            ) : (
                <>
                    <List
                        dataSource={selectedVisit?.visitors}
                        renderItem={(visitor, index) => {
                            const actions = (selectedVisit?.visitStatus.id === 1 && hasInviteWrite && (hasAllvisitorsWrite || globalUserId === selectedVisit?.host.userId))
                                ? [
                                    <EyeOutlined onClick={() => showProfileDrawer(visitor)} key={`a-${visitor.id}`} />,
                                    <MinusCircleOutlined onClick={() => deleteVisitor(visitor.id!)} key={`d-${visitor.id}`} />
                                ]
                                : [
                                    <EyeOutlined onClick={() => showProfileDrawer(visitor)} key={`a-${visitor.id}`} />
                                ];
                            return (
                                <List.Item
                                    key={visitor.id}
                                    actions={actions}
                                    onClick={() => showProfileDrawer(visitor)}
                                    style={{ cursor: 'pointer' }}
                                >
                                    <List.Item.Meta
                                        avatar={
                                            <div style={{ display: 'flex', alignItems: 'center', height: '48px' }}>
                                                {isVisitorRedFlagged(visitor, redFlags) ? (
                                                    <img src={warningIcon} alt="Warning" style={{ width: '36px', height: '36px', margin: 'auto' }} />
                                                ) : (
                                                    <div style={{ margin: 'auto' }}>
                                                        {profileIcon({ visitor, size: 36, color: getStatusColor(getStatusNameById(visitor.status!, selectedVisit?.scheduleStart)) })}
                                                    </div>
                                                )}
                                            </div>
                                        }
                                        title={<span key={`a-${visitor.id}`}>{formatFullName(visitor.firstName, visitor.middleName, visitor.lastName)}</span>}
                                        description={
                                            <span>
                                                <span style={{ color: getStatusColor(getStatusNameById(visitor.status!, selectedVisit?.scheduleStart!)) }}>
                                                    {getStatusNameById(visitor.status!, selectedVisit?.scheduleStart!)}
                                                </span>
                                                {` | Email: ${visitor.email || 'N/A'} | Mobile: ${visitor.mobilePhone || 'N/A'}`}
                                            </span>
                                        }
                                    />
                                </List.Item>
                            );
                        }}
                    />
                    {(selectedVisit?.visitStatus.id === 1 && hasInviteWrite && (hasAllvisitorsWrite || globalUserId === selectedVisit?.host.userId)) && (
                        <OpButton type="dashed" onClick={() => setIsVisitorListModalOpen(true)} block icon={<PlusOutlined />}>
                            Add Visitor
                        </OpButton>
                    )}
                </>
            )}


            {(isVisitorListModalOpen) && (
                <VisitorListModal
                    open={isVisitorListModalOpen}
                    onOk={addVisitor}
                    onClose={() => setIsVisitorListModalOpen(false)}
                />
            )}
        </>
    )
}

export default VisitorList;
