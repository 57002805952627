import { OpButton } from 'components/customAntd/DLS/OpButton/OpButton';
import STATUS from 'constants/status';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { hasPermission } from 'utils/utils';
import AccessControlModal from './AccessControlModal';

const VisitorsDrawerFooter: React.FC = () => {
    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);
    const tokenScopeList = useSelector((state: RootState) => state.auth.auth.data[0]?.tokenScopeList || []);
    const { selectedVisit } = useSelector((state: RootState) => state.visits);

    const hasSigninWrite = hasPermission(tokenScopeList, orgId, 'o', 'signin:w');
    const hasSignoutWrite = hasPermission(tokenScopeList, orgId, 'o', 'signout:w');
    const hasDenyWrite = hasPermission(tokenScopeList, orgId, 'o', 'deny:w');

    const [isAccessControlModalOpen, setIsAccessControlModalOpen] = useState(false);
    const [newStatus, setNewStatus] = useState<number>(0);

    const hasPendingVisitors = selectedVisit?.visitors.some(visitor => visitor.status === STATUS.PENDING.id);
    const hasSignedInVisitors = selectedVisit?.visitors.some(visitor => visitor.status === STATUS.SIGNED_IN.id);

    return (
        <>
            {hasPendingVisitors && (
                <>
                    {hasSigninWrite && (
                        <OpButton
                            type="primary" ghost
                            style={{ width: "100%", marginBottom: "10px" }}
                            onClick={() => {
                                setIsAccessControlModalOpen(true);
                                setNewStatus(Number(STATUS.SIGNED_IN.id));
                            }}
                        >
                            Select Visitors to Sign In
                        </OpButton>
                    )}
                    {hasDenyWrite && (
                        <OpButton
                            type="primary" danger ghost
                            style={{ width: "100%", marginBottom: "10px" }}
                            onClick={() => {
                                setIsAccessControlModalOpen(true);
                                setNewStatus(Number(STATUS.DENIED_ENTRY.id));
                            }}
                        >
                            Deny Entry
                        </OpButton>
                    )}
                </>
            )}
            {hasSignedInVisitors && hasSignoutWrite && (
                <OpButton
                    type="primary" ghost
                    style={{ width: "100%" }}
                    onClick={() => {
                        setIsAccessControlModalOpen(true);
                        setNewStatus(Number(STATUS.SIGNED_OUT.id));
                    }}
                >
                    Select Visitors to Sign Out
                </OpButton>
            )}
            <AccessControlModal
                open={isAccessControlModalOpen}
                newStatus={newStatus}
                onClose={() => setIsAccessControlModalOpen(false)}
            />
        </>
    );
}

export default VisitorsDrawerFooter;