import React from 'react';
import { SvgWrapper, SvgWrapperProps } from 'components/customAntd/inVision - Platinum Assets/svgs/SvgWrapper';

interface UserGroupFilledSvgProps extends Omit<SvgWrapperProps, 'children'> {}

export const UserGroupFilledSvg = ({
  ...svgProps
}: UserGroupFilledSvgProps) => (
  <SvgWrapper viewBox="0 0 18 18" {...svgProps}>
    <path d="M6 10C8.20914 10 10 8.20914 10 6C10 3.79086 8.20914 2 6 2C3.79086 2 2 3.79086 2 6C2 8.20914 3.79086 10 6 10Z" />
    <path d="M0 13.5C0 11.7604 0.987071 10.2514 2.43168 9.50244C3.33897 10.4267 4.60253 11 6 11C7.39746 11 8.66103 10.4267 9.56832 9.50245C11.0129 10.2514 12 11.7604 12 13.5V14.5C12 15.3284 11.3284 16 10.5 16H1.5C0.671573 16 0 15.3284 0 14.5V13.5Z" />
    <path d="M19 6C19 8.20914 17.2091 10 15 10C12.7909 10 11 8.20914 11 6C11 3.79086 12.7909 2 15 2C17.2091 2 19 3.79086 19 6Z" />
    <path d="M11.3325 9.55543C11.3653 9.53737 11.3984 9.5197 11.4317 9.50244C12.339 10.4267 13.6025 11 15 11C16.3975 11 17.661 10.4267 18.5683 9.50248C20.0129 10.2514 20.9999 11.7605 20.9999 13.5V14.5C20.9999 15.3284 20.3284 16 19.4999 16H12.5001C12.814 15.5822 12.9999 15.0628 12.9999 14.5V13.5C12.9999 11.9519 12.3602 10.5542 11.3325 9.55543Z" />
  </SvgWrapper>
);
