import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { notification } from 'antd';
import { RootState } from 'store/store';
import { patchRequest } from 'api/apiClient';
import { transformNullToEmptyString } from 'utils/utils';
import { DRAWER_WIDTH } from 'constants/ui';
import {
    NOTIFICATION_ERROR,
    NOTIFICATION_SUCCESS,
    VIDEO_READER_SAVE_ERROR,
    VIDEO_READER_UPDATE_SUCCESS,
} from 'constants/messages';
import { OpTabs } from 'components/customAntd/DLS/OpTabs/OpTabs';
import { IOnSubmitArgs, OpForm } from 'components/customAntd/DLS/OpForm/OpForm';
import { OpFormDrawer } from 'components/customAntd/DLS/OpFormDrawer/OpFormDrawer';
import ReaderForm from './tabs/ReaderForm';
import OptionsForm from './tabs/OptionsForm';
import axios from 'axios';

interface AvigilonReadersDrawerProps {
    open: boolean;
    onClose: () => void;
    entryIdOptions: { value: number; label: string }[];
    integrationData: any;
    selectedAcu: any | null;
    selectedReaderOptions: any | null;
    setAcus: (acus: any[]) => void;
}

const AvigilonReadersDrawer: React.FC<AvigilonReadersDrawerProps> = ({ open, onClose, entryIdOptions, integrationData, selectedAcu, selectedReaderOptions, setAcus }) => {
    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);

    const [activeKey, setActiveKey] = useState<string>('reader');
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const [form] = OpForm.useForm();

    useEffect(() => {
        if (open) {
            setActiveKey('reader');
        }
    }, [open]);

    const initialValues = selectedAcu && selectedReaderOptions ? {
        acuId: selectedReaderOptions.acuId || "",
        name: selectedAcu.name || "",
        description: selectedAcu.notes || "",
        status: selectedAcu.statusMessage || "",
        qrEnabled: selectedReaderOptions.qrEnabled,
        entryEnabled: selectedReaderOptions.entryEnabled,
        entryId: selectedReaderOptions.entryId,
        entryMinsBefore: selectedReaderOptions.entryMinsBefore,
        entryMinsAfter: selectedReaderOptions.entryMinsAfter,
        autoSignIn: selectedReaderOptions.autoSignIn,
    } : {};

    const tabItems = [
        {
            key: 'reader',
            label: 'Reader',
            children: <ReaderForm />,
        },
        {
            key: 'options',
            label: 'Options',
            children: <OptionsForm entryIdOptions={entryIdOptions} />,
        },
    ];

    const handleSubmit = useCallback(async ({ touchedValues }: IOnSubmitArgs) => {
        setIsSubmitting(true);
        try {
            const transformedValues = transformNullToEmptyString(touchedValues);
            if (touchedValues && Object.keys(touchedValues).length > 0) {
                await patchRequest(`/orgs/${orgId}/msiAltaReader/${selectedReaderOptions.id}`, transformedValues);
                notification.success({
                    message: NOTIFICATION_SUCCESS,
                    description: VIDEO_READER_UPDATE_SUCCESS,
                    placement: 'bottomRight',
                });

                const acus = await axios.get(`https://api.openpath.com/orgs/${integrationData.msiAltaOrgId}/acus`, {
                    headers: {
                        Authorization: `${integrationData.msiAltaToken}`,
                    },
                });
                const filteredAcus = acus.data.data.filter((acu: any) => acu.acuModel && (acu.acuModel.id === 9 || acu.acuModel.id === 10));
                setAcus(filteredAcus);
            }
            form.resetFields();
            onClose();
        } catch (error) {
            notification.error({
                message: NOTIFICATION_ERROR,
                description: VIDEO_READER_SAVE_ERROR,
                placement: 'bottomRight',
            });
            console.error("Form submission failed:", error);
        } finally {
            setIsSubmitting(false);
        }
    }, [form, integrationData?.msiAltaOrgId, integrationData?.msiAltaToken, onClose, orgId, selectedReaderOptions?.id, setAcus]);

    return (
        <OpFormDrawer
            form={form}
            title={selectedAcu?.name}
            width={DRAWER_WIDTH}
            open={open}
            onClose={onClose}
            isFormLoading={isSubmitting}
            formComponent={
                <OpForm
                    form={form}
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                    hasError={false}
                    defaultButtons={false}
                >
                    <OpTabs activeKey={activeKey} onChange={setActiveKey} items={tabItems} />
                </OpForm>
            }
        />
    );
};

export default AvigilonReadersDrawer;
