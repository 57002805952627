import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { OpCollapse } from 'components/customAntd/DLS/OpCollapse/OpCollapse';
import dayjs from 'dayjs';
import { Select, DatePicker, Button, notification } from 'antd';
import { describeLocation, describeLocationWorkflow, fetchLocations } from 'store/slices/locationsSlice';
import { RootState, AppDispatch } from 'store/store';
import type { DatePickerProps, RangePickerProps } from 'antd/es/date-picker';
import { DATE_TIME_FORMAT } from 'constants/dates';
import { OpRow } from './DLS/OpRow/OpRow';
import { OpCol } from './DLS/OpCol/OpCol';

interface DateRangeLocationFilterProps {
    onDateRangeLocationFilter: (locationId: number, startDate: string, endDate: string) => void;
    initialStartDate: string;
    initialEndDate: string;
}

const { RangePicker } = DatePicker;

const DateRangeLocationFilter: React.FC<DateRangeLocationFilterProps> = ({ onDateRangeLocationFilter, initialStartDate, initialEndDate }) => {
    const dispatch: AppDispatch = useDispatch();
    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);
    const globalLocationId = useSelector((state: RootState) => state.locations.globalLocation?.id);
    const { locations, fetchLocationsLoading } = useSelector((state: RootState) => state.locations);

    const [dateRange, setDateRange] = useState<[dayjs.Dayjs, dayjs.Dayjs]>([
        dayjs(initialStartDate),
        dayjs(initialEndDate)
    ]);

    // Fetch locations
    useEffect(() => {
        if (orgId && locations.length === 0) {
            dispatch(fetchLocations({ orgId, status: 1 }));
        }
    }, [dispatch, orgId, locations.length]);

    const handleUpdate = () => {
        if (globalLocationId && dateRange && dateRange[0] && dateRange[1]) {
            const formattedStartDate = dateRange[0].startOf('day').format(DATE_TIME_FORMAT);
            const formattedEndDate = dateRange[1].endOf('day').format(DATE_TIME_FORMAT);
            onDateRangeLocationFilter(globalLocationId, formattedStartDate, formattedEndDate);
            notification.success({
                message: 'Success',
                description: 'The visitors have been filtered successfully.',
                placement: 'bottomRight'
            });
        }
    };

    const handleLocationChange = async (locationId: number) => {
        await dispatch(describeLocation({ orgId, locationId, global: true }));
        await dispatch(describeLocationWorkflow({ orgId, locationId, global: true }));
    };

    // Disabled date by month
    const disabledByMonth: DatePickerProps['disabledDate'] = (current, { from }) => {
        if (from) {
            return current.isAfter(from.add(1, 'month'));
        }
        return false;
    };

    const rangePresets: RangePickerProps['presets'] = [
        { label: 'This Week', value: [dayjs().startOf('week'), dayjs().endOf('week')] },
        { label: 'Next Week', value: [dayjs().add(1, 'week').startOf('week'), dayjs().add(1, 'week').endOf('week')] },
        { label: 'Last Week', value: [dayjs().subtract(1, 'week').startOf('week'), dayjs().subtract(1, 'week').endOf('week')] },
        { label: 'This Month', value: [dayjs().startOf('month'), dayjs().endOf('month')] },
        { label: 'Last Month', value: [dayjs().subtract(1, 'month').startOf('month'), dayjs().subtract(1, 'month').endOf('month')] },
    ];

    return (
        <OpCollapse defaultActiveKey={['1']} items={[{
            key: '1',
            label: 'Filter',
            children: (
                <OpRow
                    gutter={[16, 16]}
                    align="middle"
                    style={{ width: '100%' }}
                >
                    <OpCol xs={24} sm={24} md={4} lg={6}>
                        <Select
                            value={
                                fetchLocationsLoading
                                    ? undefined // Show nothing if loading
                                    : globalLocationId === -1 // Check for "All Locations"
                                        ? -1
                                        : locations.find(location => location.id === globalLocationId)?.id // Ensure the location id matches
                            }
                            style={{ width: '100%' }}
                            options={
                                locations.length > 1
                                    ? [{ label: "All Locations", value: -1 }, ...locations.map(location => ({ value: location.id, label: location.name }))]
                                    : locations.map(location => ({ value: location.id, label: location.name }))
                            }
                            onChange={handleLocationChange}
                            loading={fetchLocationsLoading}
                            placeholder="Select Location"
                        />
                    </OpCol>

                    {/* Date Range Picker */}
                    <OpCol xs={24} sm={24} md={16} lg={14}>
                        <RangePicker
                            style={{ width: '100%' }}
                            value={dateRange as any}
                            onChange={(dates) => setDateRange(dates as [dayjs.Dayjs, dayjs.Dayjs])}
                            presets={rangePresets}
                            disabledDate={disabledByMonth}
                        />
                    </OpCol>
                    {/* Update Button */}
                    <OpCol xs={24} sm={24} md={4} lg={4}>
                        <Button type="primary" style={{ width: '100%' }} onClick={handleUpdate}>Update</Button>
                    </OpCol>
                </OpRow>
            )
        }]} />
    );
};

export default DateRangeLocationFilter;
