import React from 'react';
import { StopOutlined, EyeOutlined } from "@ant-design/icons";
import { OpRow } from 'components/customAntd/DLS/OpRow/OpRow';
import { OpCol } from 'components/customAntd/DLS/OpCol/OpCol';
import { OpCard } from 'components/customAntd/DLS/OpCard/OpCard';
import { OpSpace } from 'components/customAntd/DLS/OpSpace/OpSpace';
import RedFlagCard from './RedFlagCard';
import Loader from 'components/customAntd/Loader';

interface RedFlagOverViewProps {
    blockCount: number;
    watchCount: number;
    loading: boolean;
}

const RedFlagOverView: React.FC<RedFlagOverViewProps> = ({ blockCount, watchCount, loading }) => {
    return (
        <OpSpace
            direction="vertical"
            size="middle"
            style={{ display: 'flex' }}
        >

            <OpCard type="inner" title="Red Flags Overview">
                {loading ? (
                    <Loader />
                ) : (
                    <OpRow gutter={[16, 16]} justify="space-evenly">
                        <OpCol
                            xs={24}
                            sm={24}
                            md={12}
                            lg={6}
                            style={{ padding: '8px' }}
                        >
                            <RedFlagCard
                                icon={
                                    <StopOutlined
                                        style={{
                                            color: "white",
                                            backgroundColor: "rgba(242,103,87,1.0)",
                                            borderRadius: 20,
                                            fontSize: 24,
                                            padding: 8,
                                        }}
                                    />
                                }
                                title="BLOCKLIST"
                                value={blockCount}
                                color="rgba(242,103,87,0.1)"
                            />
                        </OpCol>
                        <OpCol
                            xs={24}
                            sm={24}
                            md={12}
                            lg={6}
                            style={{ padding: '8px' }}
                        >
                            <RedFlagCard
                                icon={
                                    <EyeOutlined
                                        style={{
                                            color: "white",
                                            backgroundColor: "#FFD54F",
                                            borderRadius: 20,
                                            fontSize: 24,
                                            padding: 8,
                                        }}
                                    />
                                }
                                title="WATCHLIST"
                                value={watchCount}
                                color="rgba(255, 213, 79, 0.1)"
                            />
                        </OpCol>
                    </OpRow>
                )}
            </OpCard>
        </OpSpace>

    );
}

export default RedFlagOverView;
