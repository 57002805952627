import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { OpDropdown } from 'components/customAntd/DLS/OpDropdown/OpDropdown';
import { OpButton } from 'components/customAntd/DLS/OpButton/OpButton';
import { QuestionMarkOutlinedSvg } from '../svgs/QuestionMarkOutlinedSvg/QuestionMarkOutlinedSvg';
import './HelpMenu.scss';
import FeedbackModal from 'components/layout/sideMenu/FeedbackModal';

export const HelpMenu = () => {
  const { t } = useTranslation();

  // Modal visibility states
  const [feedbackModalOpen, setFeedbackModalOpen] = useState(false);

  const items = [
    {
      key: '1',
      label: (
        <a
          href="https://commaowl.com/?83FJ6X"
          target="_blank"
          rel="noopener noreferrer"
          onClick={(e) => e.stopPropagation()}
        >
          {t('User Manual')}
        </a>
      ),
    },
    {
      key: '2',
      label: (
        <span onClick={() => setFeedbackModalOpen(true)}>
          {t('Send Feedback')}
        </span>
      ),
    },
    {
      key: '3',
      label: (
        <a
          href="https://commaowl.com/?MQHYZQ"
          target="_blank"
          rel="noopener noreferrer"
          onClick={(e) => e.stopPropagation()}
        >
          {t('Release Notes')}
        </a>
      ),
    },
  ];

  return (
    <>
      <OpDropdown
        className="help-menu"
        trigger={['click']}
        menu={{ items }}
        placement="bottomRight"
        arrow={{ pointAtCenter: true }}
        overlayClassName="help-menu-overlay"
      >
        <OpButton
          className="help-menu__button"
          icon={<QuestionMarkOutlinedSvg width={18} height={18} />}
          type="link"
        />
      </OpDropdown>

      {/* Modals */}
      <FeedbackModal open={feedbackModalOpen} onClose={() => setFeedbackModalOpen(false)} />
    </>
  );
};
