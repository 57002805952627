import React, { useEffect } from 'react';
import { DatePicker, notification, Select } from 'antd';
import dayjs from 'dayjs';
import { DATE_TIME_FORMAT } from 'constants/dates';
import { OpForm } from 'components/customAntd/DLS/OpForm/OpForm';
import { OpModal } from 'components/customAntd/DLS/OpModal/OpModal';
import { AppDispatch, RootState } from 'store/store';
import { useDispatch, useSelector } from 'react-redux';
import Loader from 'components/customAntd/Loader';
import { fetchLocations } from 'store/slices/locationsSlice';

interface AdvancedSearchModalProps {
    open: boolean;
    onCancel: () => void;
    onSearch: (values: any) => void;
}

const { RangePicker } = DatePicker;

const AdvancedSearchModal: React.FC<AdvancedSearchModalProps> = ({
    open,
    onCancel,
    onSearch,
}) => {
    const dispatch: AppDispatch = useDispatch();
    const [form] = OpForm.useForm();
    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);
    const { locations, fetchLocationsLoading } = useSelector((state: RootState) => state.locations);

    // Fetch locations
    useEffect(() => {
        if (orgId && locations.length === 0) {
            dispatch(fetchLocations({ orgId, status: 1 }));
        }
    }, [dispatch, orgId, locations.length]);

    useEffect(() => {
        if (open && locations.length > 0) {
            const defaultSiteId = locations.length > 1 ? -1 : locations[0].id;
            form.setFieldsValue({ siteId: defaultSiteId });
        }
    }, [open, locations, form]);

    const handleFinish = (values: any) => {
        const { firstName, lastName, dateRange } = values.values;

        // Add notification check
        if (!firstName && !lastName) {
            notification.error({
                message: 'Search Error',
                description: 'Please provide first name or last name of the visitor.',
                placement: 'bottomRight',
            });
            return;
        }

        const processedValues = {
            ...values.values,
            startDate: dateRange?.[0]?.startOf('day').format(DATE_TIME_FORMAT),
            endDate: dateRange?.[1]?.endOf('day').format(DATE_TIME_FORMAT),
        };
        onSearch(processedValues);
        form.resetFields();
    };

    return (
        <OpModal
            title="Advanced Search"
            open={open}
            onOk={() => form.submit()}
            onCancel={onCancel}
            okText="Search"
            cancelText="Cancel"
        >
            {fetchLocationsLoading ? (
                <Loader />
            ) : (
                <OpForm
                    form={form}
                    onSubmit={handleFinish}
                    hasError={false}
                    defaultButtons={false}
                >
                    <OpForm.Input
                        label="First Name"
                        name="firstName"
                    />

                    <OpForm.Input
                        label="Last Name"
                        name="lastName"
                    />

                    <OpForm.Input label="Middle Name" name="middleName" />

                    <OpForm.Item
                        name={"siteId"}
                        label={"Location"}
                    >
                        <Select
                            options={
                                locations.length > 1
                                    ? [
                                        { label: "All Locations", value: -1 },
                                        ...locations.map(location => ({
                                            label: location.name,
                                            value: location.id,
                                        })),
                                    ]
                                    : locations.map(location => ({
                                        label: location.name,
                                        value: location.id,
                                    }))
                            }
                        />
                    </OpForm.Item>

                    <OpForm.Item label="Date Range" name="dateRange">
                        <RangePicker
                            presets={[
                                { label: 'Last 7 Days', value: [dayjs().subtract(7, 'd'), dayjs()] },
                                { label: 'Last 30 Days', value: [dayjs().subtract(30, 'd'), dayjs()] },
                            ]}
                            style={{ width: '100%' }}
                        />
                    </OpForm.Item>
                </OpForm>
            )}
        </OpModal>
    );
};

export default AdvancedSearchModal;