import React, { useState, useEffect } from 'react';
import { Spin } from 'antd';
import { EyeOutlined, LoadingOutlined } from '@ant-design/icons';
import { isVisitorRedFlagged } from 'utils/visitorsHelper';
import { OpBanner } from 'components/customAntd/DLS/OpBanner/OpBanner';
import warningIcon from 'images/icons/warning.svg';
import { DocumentCheck, VisitorAgreement } from 'types/visitTypes';
import { OpTabs } from 'components/customAntd/DLS/OpTabs/OpTabs';
import RedFlagMatchModal from './RedFlagMatchModal';
import { OpDrawer } from 'components/customAntd/DLS/OpDrawer/OpDrawer';
import ProfileDrawerHeader from './header/ProfileDrawerHeader';
import ProfileTab from './tabs/ProfileTab';
import AgreementsTab from './tabs/AgreementsTab';
import { getRequest } from 'api/apiClient';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store/store';
import { Agreement } from 'types/agreementTypes';
import { DenyDetails } from 'types/denyTypes';
import { QrCodeScanLog } from 'types/qrCodeTypes';
import QrCodeTab from './tabs/QrCodeTab';
import { fetchRedFlags, setSelectedRedFlaggedVisitor } from 'store/slices/redFlagSlice';
import { Kiosk } from 'types/kioskTypes';

interface ProfileDrawerProps {
    open: boolean;
    onClose: () => void;
}

const ProfileDrawer: React.FC<ProfileDrawerProps> = ({ open, onClose }) => {
    const dispatch: AppDispatch = useDispatch();

    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);
    const globalLocationWorkflow = useSelector((state: RootState) => state.locations.globalLocationWorkflow);
    const { selectedVisit, selectedVisitor } = useSelector((state: RootState) => state.visits);
    const redFlags = useSelector((state: RootState) => state.redFlags.redFlags);

    const [connectedKiosks, setConnectedKiosks] = useState<Kiosk[]>([]);
    const [visitorPhotoUrl, setVisitorPhotoUrl] = useState<string | null>(null);
    const [extendedVisitorInfo, setExtendedVisitorInfo] = useState<DocumentCheck | null>(null); // Extended visitor info includes additional properties like "photo" (e.g., "3090.jpg") and "agreement" (e.g., "3090_agreement.pdf")
    const [allAgreements, setAllAgreements] = useState<Agreement[]>([]);
    const [signedAgreements, setSignedAgreements] = useState<Agreement[]>([]);
    const [denyDetails, setDenyDeatils] = useState<DenyDetails | undefined>(undefined);
    const [qrCodeScanLogs, setQrCodeScanLogs] = useState<QrCodeScanLog[]>([]);

    const [activeKey, setActiveKey] = useState<string>('profile');
    const [isRedFlagMatchModalOpen, setIsRedFlagMatchModalOpen] = useState(false);
    const [loading, setLoading] = useState<boolean>(false);

    // Reset the active tab to 'profile' when the drawer opens
    useEffect(() => {
        if (open) {
            setActiveKey('profile');
        }
    }, [open]);

    // Fetch data
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                // fetch signed agreements
                const signedVisitorAgreements = await getRequest(`/orgs/${orgId}/visitor/${selectedVisit?.id}/visitorAgreement`, {
                    visitorId: selectedVisitor?.id
                });
                const signedAgreements = signedVisitorAgreements.data.map((signedAgreement: VisitorAgreement) => signedAgreement.document);
                setSignedAgreements(signedAgreements);

                // Fetch all kiosks
                const kiosksResponse = await getRequest(`/orgs/${orgId}/kiosk`);
                const allKiosks: Kiosk[] = kiosksResponse.data || [];
                const validKiosks = allKiosks.filter(
                    (kiosk) => kiosk.status.id === 1 && kiosk.appearance?.[0]?.badgePrinter === 1
                );
                // Filter kiosks where site id matches selectedVisit.site.id
                let matchingKiosks = validKiosks.filter(kiosk => kiosk.site?.id === selectedVisit?.site.id);
                // Fetch additional kiosks based on site associations
                const additionalKiosks: Kiosk[] = [];
                await Promise.all(
                    validKiosks.map(async (kiosk) => {
                        try {
                            const siteResponse = await getRequest(`/orgs/${orgId}/kiosk/${kiosk.id}/sites`);
                            const siteData = siteResponse.data || [];

                            if (siteData.some((site: any) => site.id === selectedVisit?.site.id)) {
                                additionalKiosks.push(kiosk);
                            }
                        } catch (error) {
                            console.error(`Error fetching sites for kiosk ${kiosk.id}:`, error);
                        }
                    })
                );
                // Merge results and remove duplicates
                const finalKiosks = [...new Map([...matchingKiosks, ...additionalKiosks].map(kiosk => [kiosk.id, kiosk])).values()];
                setConnectedKiosks(finalKiosks);

                // fetch all agreements
                const workflowAgreements = await getRequest(`/orgs/${orgId}/visitorWorkflow/${globalLocationWorkflow?.SignInWorkflowId}/agreements`);
                // uniqueAgreements will contain agreements from workflowAgreements.data that are not present in signedAgreements
                const uniqueAgreements = workflowAgreements.data.filter(
                    (agreement: Agreement) => !signedAgreements.some((signedAgreement: Agreement) => signedAgreement.id === agreement.id)
                );
                const allAgreements = [...uniqueAgreements, ...signedAgreements];
                setAllAgreements(allAgreements);

                // Extended visitor info includes additional properties like "photo" (e.g., "3090.jpg") and "agreement" (e.g., "3090_agreement.pdf")
                const extendedVisitorInfo = await getRequest(`/orgs/${orgId}/visitor/${selectedVisit?.id}/documentCheck`);
                const filteredExtendedVisitorInfo = (extendedVisitorInfo.data.find((visitor: DocumentCheck) => visitor.id === selectedVisitor?.id));
                setExtendedVisitorInfo(filteredExtendedVisitorInfo);

                if (redFlags.length === 0) {
                    await dispatch(fetchRedFlags({ orgId }));
                }

                try {
                    // Fetch visitor photo
                    if (filteredExtendedVisitorInfo.photo !== null) {
                        const response = await getRequest(
                            `/orgs/${orgId}/visitor/${selectedVisit?.id}/visitors/${selectedVisitor?.id}/photo`,
                            {},
                            { responseType: 'blob' }
                        );
                        const imageBlob = response;
                        const visitorPhotoUrl = URL.createObjectURL(imageBlob);
                        setVisitorPhotoUrl(visitorPhotoUrl);
                    }
                } catch (error) {
                    console.error('Failed to fetch visitor photo:', error);
                }


                // fetch deny details
                const denyDetailsResponse = await getRequest(`/orgs/${orgId}/visitor/${selectedVisit?.id}/denyEntry`);
                const denyDetails = denyDetailsResponse?.data?.[0]?.DenyDetails || [];

                const visitorMatch = denyDetails.find((denyDetail: any) =>
                    denyDetail.visitor.some((visitor: any) => visitor.id === selectedVisitor?.id)
                );

                if (visitorMatch) {
                    setDenyDeatils(visitorMatch);
                }

                // fetch qr code log
                const qrCodeScanLog = await getRequest(`/orgs/${orgId}/visitor/${selectedVisit?.id}/qrScanLog`, {
                    visitorId: selectedVisitor?.id
                });
                setQrCodeScanLogs(qrCodeScanLog.data);
            } catch (error) {
                console.log("Failed to fetch data.");
            } finally {
                setLoading(false);
            }
        };
        fetchData();
        // eslint-disable-next-line
    }, [orgId]);

    const tabItems = [
        {
            key: 'profile',
            label: 'Profile',
            children: <ProfileTab visitorPhotoUrl={visitorPhotoUrl} denyDetails={denyDetails} connectedKiosks={connectedKiosks} />
        },
        {
            key: 'agreements',
            label: 'Agreements',
            children: <AgreementsTab extendedVisitorInfo={extendedVisitorInfo!} allAgreements={allAgreements} signedAgreements={signedAgreements} />,
        },
        {
            key: 'qrCodeScanLog',
            label: 'QR Code',
            children: <QrCodeTab qrCodeScanLogs={qrCodeScanLogs} />,
        },
    ];

    return (
        <>
            <OpDrawer
                width={700}
                title="Visitor Profile"
                placement="right"
                onClose={onClose}
                open={open}
                extra={!loading && (<ProfileDrawerHeader onClose={onClose} />)}
            >
                {loading ? (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                        <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
                    </div>
                ) : (
                    <>
                        {selectedVisitor && isVisitorRedFlagged(selectedVisitor, redFlags) && (
                            <>
                                <OpBanner
                                    type="error"
                                    style={{ marginBottom: '20px', cursor: 'pointer' }}
                                    showIcon={false}
                                    message={(
                                        <div onClick={() => {
                                            dispatch(setSelectedRedFlaggedVisitor(selectedVisitor));
                                            setIsRedFlagMatchModalOpen(true)
                                        }}
                                            style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                            <span style={{ display: 'flex', alignItems: 'center' }}>
                                                <img src={warningIcon} alt="Warning" style={{ width: '20px', height: '20px', marginRight: '8px' }} />
                                                VISITOR ON RED FLAG LIST
                                            </span>
                                            <EyeOutlined />
                                        </div>
                                    )}
                                />
                                {(isRedFlagMatchModalOpen) && (
                                    <RedFlagMatchModal visible={isRedFlagMatchModalOpen} onClose={() => setIsRedFlagMatchModalOpen(false)} />
                                )}
                            </>
                        )}
                        <OpTabs activeKey={activeKey} onChange={setActiveKey} items={tabItems} />
                    </>
                )}
            </OpDrawer>
        </>
    );
};

export default ProfileDrawer;
