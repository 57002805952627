import { Form, FormInstance, List } from "antd";
import { OpForm } from "components/customAntd/DLS/OpForm/OpForm";
import { Visitor } from "types/visitTypes";
import warningIcon from "images/icons/warning.svg";
import { useEffect, useState } from "react";
import { formatFullName } from "utils/utils";
import { getStatusColor, isVisitorRedFlagged, profileIcon } from "utils/visitorsHelper";
import { MinusCircleOutlined, PlusOutlined, EditOutlined } from '@ant-design/icons';
import VisitorListModal from "./VisitorListModal";
import RedFlagMatchModal from "components/pages/visitors/drawer/profile/RedFlagMatchModal";
import { AppDispatch, RootState } from "store/store";
import { useDispatch, useSelector } from "react-redux";
import { VisitorWorkflowFields } from "types/visitorWorkflowTypes";
import { fetchRedFlags, setSelectedRedFlaggedVisitor } from "store/slices/redFlagSlice";
import Loader from "components/customAntd/Loader";
import dayjs from 'dayjs';
import { getRequest, postRequest } from "api/apiClient";

interface VisitorListProps {
    form: FormInstance;
    signInWorkflowFields?: VisitorWorkflowFields;
    isInviting: boolean;
    newVisitorName?: {
        firstName: string;
        middleName: string;
        lastName: string;
        dob?: string;
    }
    setNewVisitorName?: (filter: { firstName: string; middleName: string; lastName: string; dob?: string; } | undefined) => void;
}

const VisitorList: React.FC<VisitorListProps> = ({ form, signInWorkflowFields, isInviting, newVisitorName, setNewVisitorName }) => {
    const dispatch: AppDispatch = useDispatch();
    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);

    const redFlags = useSelector((state: RootState) => state.redFlags.redFlags);
    const [isVisitorModalOpen, setIsVisitorModalOpen] = useState(true);
    const [isRedFlagMatchModalOpen, setIsRedFlagMatchModalOpen] = useState(false);
    const [currentVisitor, setCurrentVisitor] = useState<Visitor | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [loadingExternalRedFlags, setLoadingExternalRedFlags] = useState<boolean>(false);

    const [isCheckrInstalled, setIsCheckrInstalled] = useState(false);
    const [visitorExternalRedFlags, setVisitorExternalRedFlags] = useState<{ [key: number]: any[] }>({});
    const [selectedExternalRedFlags, setSelectedExternalRedFlags] = useState<any[]>([]);

    const handleOpenModal = (visitor?: Visitor) => {
        setCurrentVisitor(visitor || null);
        setIsVisitorModalOpen(true);
    };

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                if (redFlags.length === 0) {
                    await dispatch(fetchRedFlags({ orgId }));
                }

                const checkrIntegrationData = await getRequest(`/orgs/${orgId}/integrationCheckrTrust`);
                if (Array.isArray(checkrIntegrationData.data) && checkrIntegrationData.data.length === 0) {
                    setIsCheckrInstalled(false);
                } else {
                    setIsCheckrInstalled(true);
                }
            } catch (error) {
                console.error("Failed to fetch data.");
            } finally {
                setLoading(false);

            }
        };
        fetchData();
    }, [dispatch, orgId, redFlags.length]);


    const checkExternalRedFlags = async (visitor: Visitor) => {
        setLoadingExternalRedFlags(true);
        try {
            const dob = visitor.dob ? dayjs(visitor.dob).format('YYYYMMDD') : '';
            const response = await postRequest(
                `/orgs/${orgId}/integrationCheckrTrust/checkRecords`,
                {
                    firstName: visitor.firstName,
                    lastName: visitor.lastName,
                    dateOfBirth: dob
                }
            );

            if (response.data?.results?.length > 0) {
                setVisitorExternalRedFlags(prev => ({
                    ...prev,
                    [visitor.id]: response.data.results
                }));
                return true;
            }
            return false;
        } catch (error) {
            console.error(error);
            return false;
        } finally {
            setLoadingExternalRedFlags(false);
        }
    };

    const handleCreateEditVisitor = async (visitor: Visitor) => {
        const updatedVisitor = { ...visitor, id: visitor.id || Date.now() };

        if (isCheckrInstalled && visitor.dob) {
            await checkExternalRedFlags(updatedVisitor);
        }

        const currentVisitors = form.getFieldValue('visitors') || [];

        if (currentVisitor) {
            const updatedVisitors = currentVisitors.map((v: Visitor) =>
                v === currentVisitor ? updatedVisitor : v
            );
            form.setFieldsValue({ visitors: updatedVisitors });
        } else {
            form.setFieldsValue({ visitors: [...currentVisitors, updatedVisitor] });
        }
    };

    return (
        <>
            {(loading || loadingExternalRedFlags) ? (
                <Loader />
            ) : (
                <>
                    <Form.List name="visitors">
                        {(fields, { add, remove }) => (
                            <List
                                locale={{ emptyText: ' ' }}
                                dataSource={fields}
                                renderItem={(field, index) => (
                                    <List.Item
                                        key={field.key}
                                        actions={[
                                            <EditOutlined onClick={() => handleOpenModal(form.getFieldValue(['visitors', field.name]))} />,
                                            <MinusCircleOutlined onClick={() => remove(field.name)} />,
                                        ]}
                                    >
                                        <List.Item.Meta
                                            avatar={
                                                (() => {
                                                    const visitor = form.getFieldValue(['visitors', field.name]);
                                                    const visitorId = visitor?.id;

                                                    return isVisitorRedFlagged(visitor, redFlags, visitorExternalRedFlags[visitorId]) ? (
                                                        <div
                                                            onClick={() => {
                                                                dispatch(setSelectedRedFlaggedVisitor(visitor));
                                                                setSelectedExternalRedFlags(visitorExternalRedFlags[visitorId] || []);
                                                                setIsRedFlagMatchModalOpen(true);
                                                            }}
                                                            style={{ cursor: 'pointer' }}
                                                        >
                                                            <img src={warningIcon} alt="Warning" style={{ width: '24px', height: '24px' }} />
                                                        </div>
                                                    ) : (
                                                        profileIcon({
                                                            firstName: visitor?.firstName?.trim() || '',
                                                            lastName: visitor?.lastName?.trim() || '',
                                                            size: 24,
                                                            color: isInviting ? getStatusColor('Pending') : getStatusColor("Signed In"),
                                                            marginRight: 0,
                                                        })
                                                    );
                                                })()
                                            }
                                            title={formatFullName(
                                                form.getFieldValue(['visitors', field.name, 'firstName']),
                                                form.getFieldValue(['visitors', field.name, 'middleName']),
                                                form.getFieldValue(['visitors', field.name, 'lastName'])
                                            )}
                                            description={form.getFieldValue(['visitors', field.name, 'email'])}
                                        />
                                    </List.Item>
                                )}
                            />
                        )}
                    </Form.List>
                    <OpForm.Button type="dashed" onClick={() => handleOpenModal()} block icon={<PlusOutlined />}>
                        Add Visitor
                    </OpForm.Button>
                </>
            )}

            {(isVisitorModalOpen) && (
                <VisitorListModal
                    open={isVisitorModalOpen}
                    isInviting={isInviting}
                    isCheckrInstalled={isCheckrInstalled}
                    signInWorkflowFields={signInWorkflowFields}
                    visitor={
                        currentVisitor ??
                        (newVisitorName
                            ? {
                                firstName: newVisitorName.firstName !== "NONE" ? newVisitorName.firstName : undefined,
                                middleName: newVisitorName.middleName !== "NONE" ? newVisitorName.middleName : undefined,
                                lastName: newVisitorName.lastName !== "NONE" ? newVisitorName.lastName : undefined,
                                dob: newVisitorName.dob !== "NONE" ? newVisitorName.dob : undefined,
                            }
                            : undefined)
                    }
                    onOk={handleCreateEditVisitor}
                    onClose={() => {
                        setIsVisitorModalOpen(false);
                        if (setNewVisitorName) {
                            setNewVisitorName(undefined);
                        }
                    }}
                />
            )}

            {(isRedFlagMatchModalOpen) && (
                <RedFlagMatchModal
                    visible={isRedFlagMatchModalOpen}
                    onClose={() => {
                        setIsRedFlagMatchModalOpen(false)
                    }}
                    externalRedFlags={selectedExternalRedFlags}
                />
            )}
        </>
    )
}

export default VisitorList;
