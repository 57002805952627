import React, { useEffect, useState, useCallback } from 'react';
import { Form, Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, AppDispatch } from 'store/store';
import { alternateLogin, clearAuthState, login } from 'store/slices/authSlice';
import { setGlobalOrgId } from 'store/slices/globalOrgSlice';
import { describeLocation, describeLocationWorkflow, fetchLocations } from 'store/slices/locationsSlice';
import { describeUser } from 'store/slices/usersSlice';
import { fetchSubscriptions } from 'store/slices/subscriptionsSlice';
import { LoginPayload } from 'types/authTypes';
import { hasPermission } from 'utils/utils';
import LoginForm from './LoginForm';
import ForgotPasswordForm from './ForgotPasswordForm';
import AvigilonAltaLogin from './AvigilonAltaLogin';
import AlternateLogin from './AlternateLogin';
import './login.scss';
import { fetchRedFlags } from 'store/slices/redFlagSlice';

const appVersion = process.env.REACT_APP_VERSION;

const LogIn: React.FC = () => {
    const navigate = useNavigate();
    const dispatch: AppDispatch = useDispatch();
    const { auth, error } = useSelector((state: RootState) => state.auth);

    const [autoRedirect, setAutoRedirect] = useState(false);
    const [isForgotPassword, setIsForgotPassword] = useState(false);
    const [isAlternateLogin, setIsAlternateLogin] = useState(false);
    const [isAvigilonLogin, setIsAvigilonLogin] = useState(false);

    const handleBackToLogin = () => {
        setIsAlternateLogin(false);
        setIsAvigilonLogin(false);
    };

    const handleLearnMoreClick = () => {
        window.open("https://invisit.us", "_blank");
    };

    // Inside the LogIn component, add these selectors
    const globalUser = useSelector((state: RootState) => state.users.globalUser);
    const globalLocation = useSelector((state: RootState) => state.locations.globalLocation);

    useEffect(() => {
        if (auth.data.length > 0 && globalUser && globalLocation) {
            navigate('/dashboard', { replace: true });
        }
    }, [auth.data, globalUser, globalLocation, navigate]);

    useEffect(() => {
        const token = localStorage.getItem('authToken');
        if (token) {
            const identityId = localStorage.getItem('identityId');
            const createdAt = localStorage.getItem('createdAt');
            const expiresAt = localStorage.getItem('expiresAt');
            const tokenScopeListStr = localStorage.getItem('tokenScopeList');


            if (identityId && createdAt && expiresAt && tokenScopeListStr) {
                const storedAuthData = {
                    token,
                    identityId,
                    createdAt,
                    expiresAt,
                    tokenScopeList: tokenScopeListStr ? JSON.parse(tokenScopeListStr) : [],
                };
                console.log("1 called");
                dispatch({
                    type: 'auth/setAuthData',
                    payload: {
                        data: [storedAuthData],
                        meta: null,
                        totalCount: 1,
                        filteredCount: 1,
                    },
                });
            }
        }
    }, [dispatch]);

    const handleOrganizationSelection = useCallback(async (orgId: number, userId: number) => {
        await dispatch(setGlobalOrgId(orgId));
        await dispatch(describeUser({ orgId, userId, global: true }));
        await dispatch(fetchSubscriptions({ orgId }));

        await dispatch(fetchRedFlags({ orgId }));

        const locationsResult = await dispatch(fetchLocations({ orgId, status: 1 }));
        if (fetchLocations.fulfilled.match(locationsResult) && locationsResult.payload.data.length !== 0) {
            const firstLocation = locationsResult.payload.data[0];
            if (firstLocation.id) {
                await dispatch(describeLocation({ orgId, locationId: firstLocation.id, global: true }));
                await dispatch(describeLocationWorkflow({ orgId, locationId: firstLocation.id, global: true }));
            }
        }

        const tokenScopeList = auth.data[0].tokenScopeList || [];
        const permissions = {
            hasDashRead: hasPermission(tokenScopeList, orgId, 'o', 'dash:r'),
            hasVisitorsRead: hasPermission(tokenScopeList, orgId, 'o', 'visitors:r'),
            hasLocationRead: hasPermission(tokenScopeList, orgId, 'o', 'location:r'),
            hasUsersRead: hasPermission(tokenScopeList, orgId, 'o', 'user:r'),
            hasRolesRead: hasPermission(tokenScopeList, orgId, 'o', 'role:r'),
            hasKiosksRead: hasPermission(tokenScopeList, orgId, 'o', 'kiosk:r'),
            hasRedFlagConfRead: hasPermission(tokenScopeList, orgId, 'o', 'redflagconf:r'),
            hasInvitationRead: hasPermission(tokenScopeList, orgId, 'o', 'invitation:r'),
            hasWorkflow: hasPermission(tokenScopeList, orgId, 'o', 'signinconf:r') || hasPermission(tokenScopeList, orgId, 'o', 'signoutconf:r'),
            hasFieldsRead: hasPermission(tokenScopeList, orgId, 'o', 'fields:r'),
            hasAgreementsRead: hasPermission(tokenScopeList, orgId, 'o', 'agreements:r'),
            hasAccountRead: hasPermission(tokenScopeList, orgId, 'o', 'account:r'),
            hasIntegrationRead: hasPermission(tokenScopeList, orgId, 'o', 'integration:r'),
            hasRptVisitorRead: hasPermission(tokenScopeList, orgId, 'o', 'rptvisitor:r'),
            hasRptTrendRead: hasPermission(tokenScopeList, orgId, 'o', 'rpttrend:r'),
            hasRptRedFlagRead: hasPermission(tokenScopeList, orgId, 'o', 'rptredflag:r'),
            hasRptHostRead: hasPermission(tokenScopeList, orgId, 'o', 'rpthost:r'),
            hasRptActivityRead: hasPermission(tokenScopeList, orgId, 'o', 'rptactivity:r'),
            hasRptGuestPassRead: hasPermission(tokenScopeList, orgId, 'o', 'rptguestpass:r'),
        };

        const routes = [
            permissions.hasDashRead && '/dashboard',
            permissions.hasVisitorsRead && '/visitors',
            permissions.hasLocationRead && '/locations',
            permissions.hasUsersRead && '/users/users',
            permissions.hasRolesRead && '/users/roles',
            permissions.hasKiosksRead && '/kiosks',
            permissions.hasRedFlagConfRead && '/redFlag',
            permissions.hasInvitationRead && '/configuration/invitations',
            permissions.hasWorkflow && '/configuration/workflow',
            permissions.hasFieldsRead && '/configuration/formFields',
            permissions.hasAgreementsRead && '/configuration/agreements',
            permissions.hasAccountRead && '/administration/account',
            permissions.hasIntegrationRead && '/administration/integration',
            permissions.hasRptVisitorRead && '/reports/visitorReport',
            permissions.hasRptTrendRead && '/reports/repeatVisitors',
            permissions.hasRptRedFlagRead && '/reports/redFlagReport',
            permissions.hasRptGuestPassRead && '/reports/guestPassReport',
            permissions.hasRptHostRead && '/reports/hostReport',
            permissions.hasRptActivityRead && '/reports/activityLog',
        ].filter(Boolean);

        if (routes.length > 0) {
            navigate(routes[0], { replace: true });
        } else {
            navigate('/support', { replace: true });
        }
    }, [auth, dispatch, navigate]);

    useEffect(() => {
        if (auth.data.length === 1) {
            const tokenScopeList = auth.data[0].tokenScopeList;
            if (tokenScopeList.length === 1) {
                const singleOrg = tokenScopeList[0].org;
                const singleUser = tokenScopeList[0].user;
                setAutoRedirect(true);
                if (singleOrg.id != null && singleUser.id != null) {
                    handleOrganizationSelection(singleOrg.id, singleUser.id);
                }
            }
        }
    }, [auth, handleOrganizationSelection]);


    const handleLogin = async (values: LoginPayload) => {
        await dispatch(login(values))
    };

    const handleAlternateLogin = async (values: { email: string; password: string; code?: string }) => {
        await dispatch(alternateLogin(values));
    };

    const handleBack = () => {
        dispatch(clearAuthState());
    };

    if (autoRedirect) {
        return null;
    }

    return (
        <div className="auth-wrapper">
            <div className="auth-wrapper__left">
                <div className="auth-wrapper__left-content">
                    {/* Marketing text or other content */}
                    <div className="auth-wrapper__left-content__text">
                        <div className="auth-wrapper__left-content__headline">
                            Easily manage and monitor your visitors with InVisit
                        </div>
                        <div className="auth-wrapper__left-content__body">
                            Track visitor activity in real-time anywhere, giving you accurate insights on who is on-site and ensuring you’re always informed on the flow of your visitors.
                        </div>
                        <Button
                            onClick={handleLearnMoreClick}
                            type="primary"
                            className="button"
                            style={{ width: "110px" }}
                        >
                            Learn more
                        </Button>
                    </div>
                </div>
            </div>
            <div className="auth-wrapper__right">
                <div className="auth-wrapper__right-content">
                    <div className="auth-wrapper__right-logo">
                        <img src="/images/invisit_full.png" alt="Logo" />
                    </div>
                    <div className="login">
                        {isForgotPassword ? (
                            <ForgotPasswordForm setIsForgotPassword={setIsForgotPassword} />
                        ) : isAlternateLogin && auth.data.length === 0 ? (
                            isAvigilonLogin ? (
                                <AvigilonAltaLogin onFinish={handleAlternateLogin} onBack={handleBackToLogin} error={error} />
                            ) : (
                                <AlternateLogin onBack={handleBackToLogin} onAvigilonClick={() => setIsAvigilonLogin(true)} />
                            )
                        ) : auth.data.length === 0 ? (
                            <LoginForm
                                onFinish={handleLogin}
                                error={error}
                                setIsForgotPassword={setIsForgotPassword}
                                setIsAlternateLogin={setIsAlternateLogin}
                            />
                        ) : (
                            <>
                                {auth.data[0].tokenScopeList.map((item: any) => (
                                    <Form.Item key={item.org.id}>
                                        <Button
                                            type="primary"
                                            className="button"
                                            onClick={() => handleOrganizationSelection(item.org.id, item.user.id)}
                                        >
                                            {item.org.name}
                                        </Button>
                                    </Form.Item>
                                ))}
                                <Button onClick={handleBack} className="button">Back</Button>
                            </>
                        )}

                    </div>
                    {appVersion && (
                        <div className="version-number" style={{ textAlign: 'center', marginTop: '80px', fontSize: '12px', color: '#888' }}>
                            Build {appVersion}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default LogIn;
