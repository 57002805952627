import React from 'react';
import { OpForm } from 'components/customAntd/DLS/OpForm/OpForm';
import { DATE_FORMAT } from 'constants/dates';

const VisitorForm: React.FC = () => {
    return (
        <>
            <OpForm.Input
                label="First Name" name={['visitor', 'firstName']} rules={[{ required: true, message: 'Please enter the first name' }]}
            />

            <OpForm.Input
                label="Last Name" name={['visitor', 'lastName']} rules={[{ required: true, message: 'Please enter the last name' }]}
            />

            <OpForm.Input
                label="Middle Name" name={['visitor', 'middleName']}
            />

            <OpForm.DatePicker format={DATE_FORMAT} label="Date of Birth" name={['visitor', 'dateOfBirth']} />

            <OpForm.Input
                label="Identification" name={['visitor', 'identification']}
            />

            {/* <OpForm.Input
                label="Address" name={['visitor', 'address']}
            />

            <OpForm.Input
                label="City" name={['visitor', 'city']}
            />

            <OpForm.Input
                label="Postal Code" name={['visitor', 'postalCode']}
            /> */}
        </>
    );
};

export default VisitorForm;
