import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import { Modal, List } from 'antd';

// Import custom components
import { OpTable, IOpTableProps } from 'components/customAntd/DLS/OpTable/OpTable';
import { OpTableRawColumnType } from 'components/customAntd/DLS/OpTableCore/OpTableCore';
import { OpSpace } from 'components/customAntd/DLS/OpSpace/OpSpace';

// Import actions and types from Redux slices
import { RootState, AppDispatch } from 'store/store';
import { fetchVisits, setSelectedVisit, setVisitsStartDate, setVisitsEndDate } from 'store/slices/visitsSlice';

// Import utility functions
import { getVisitDateTime, getStatusNameById, getStatusColor, profileIcon } from 'utils/visitorsHelper';

// Import constants
import { DATE_FORMAT, DATE_TIME_FORMAT, DATE_TIME_AM_PM_FORMAT } from 'constants/dates';
import { TABLE_HEIGHT } from 'constants/ui';

import { formatFullName } from 'utils/utils';
import VisitorsDrawer from 'components/pages/visitors/drawer/VisitorsDrawer';
import DateRangeLocationFilter2 from 'components/customAntd/DateRangeLocationFilter2';
import { getRequest } from 'api/apiClient';
import { Location } from 'types/locationTypes';

// Extend dayjs with the isBetween plugin
dayjs.extend(isBetween);

interface HostVisitCount { hostName: string, userId: number, count: number, location: string, siteId: number };
interface HostData { hostName: string, dateTime?: string, visitName: string, statusName: string, visitId: number };

const HostReportTable: React.FC = () => {
    const dispatch: AppDispatch = useDispatch();
    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);
    const globalLocationId = useSelector((state: RootState) => state.locations.globalLocation?.id);
    const { visits, fetchVisitsLoading } = useSelector((state: RootState) => state.visits);
    const selectedLocationIdRef = useRef<number>(globalLocationId!);
    const startDateRef = useRef(dayjs().startOf('week').format(DATE_TIME_FORMAT));
    const endDateRef = useRef(dayjs().endOf('week').format(DATE_TIME_FORMAT));

    const [isProfileDrawerOpen, setIsProfileDrawerOpen] = useState(false);
    const [locations, setLocations] = useState<Location[]>([]);

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedHostVisitData, setSelectedHostVisitData] = useState<HostData[]>([]);
    const [hostVisitCounts, setHostVisitCounts] = useState<HostVisitCount[]>([]);
    const [hostDataArray, setHostDataArray] = useState<{ [key: number]: HostData[] }>({});

    const [isVisitorsDrawerOpen, setIsVisitorsDrawerOpen] = useState<boolean>(false);

    const fetchVisitsData = async () => {
        await dispatch(setVisitsStartDate(startDateRef.current));
        await dispatch(setVisitsEndDate(endDateRef.current));
        await dispatch(fetchVisits({ orgId }));
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const locations = await getRequest(`/orgs/${orgId}/sites`, { status: 1 });
                setLocations(locations.data);
            } catch (error) {
                console.log("Failed to fetch data.");
            }
        };
        fetchVisitsData();
        fetchData();
        // eslint-disable-next-line
    }, [orgId]);

    // Filter visits based on location and date range
    useEffect(() => {
        if (visits) {
            // Aggregate visits by host userId
            const hostVisitMap: { [key: number]: HostVisitCount } = {};
            const hostDataMap: { [key: number]: HostData[] } = {};
            visits.forEach((visit) => {
                if (!visit.host.userId) return;
                const userId = visit.host.userId;
                const hostName = formatFullName(visit.host.firstName, null, visit.host.lastName) || visit.host.email || "";
                const dateTime = getVisitDateTime(visit, getStatusNameById(visit.visitStatus.id, visit.scheduleStart!), DATE_TIME_AM_PM_FORMAT);
                const visitName = formatFullName(visit.visitors[0]?.firstName, visit.visitors[0]?.middleName, visit.visitors[0]?.lastName) +
                    (visit.visitors.length > 1 ? ` (+${visit.visitors.length - 1})` : '');
                const statusName = getStatusNameById(visit.visitStatus.id, visit.scheduleStart);
                const visitId = visit.id;

                if (hostVisitMap[userId]) {
                    hostVisitMap[userId].count++;
                } else {
                    hostVisitMap[userId] = { hostName, userId: userId, count: 1, location: visit.site.name || '', siteId: visit.site.id! };
                }

                if (hostDataMap[userId]) {
                    hostDataMap[userId].push({ hostName, dateTime, visitName, statusName, visitId });
                } else {
                    hostDataMap[userId] = [{ hostName, dateTime, visitName, statusName, visitId }];
                }
            });

            // Convert to array of objects for the table and sort by hostName
            const hostVisitArray = Object.keys(hostVisitMap)
                .map((userId) => ({
                    userId: Number(userId),
                    hostName: hostVisitMap[Number(userId)].hostName,
                    count: hostVisitMap[Number(userId)].count,
                    location: hostVisitMap[Number(userId)].location,
                    siteId: hostVisitMap[Number(userId)].siteId,
                }))
                .sort((a, b) => a.hostName.localeCompare(b.hostName)); // Sort by hostName

            setHostVisitCounts(hostVisitArray);
            setHostDataArray(hostDataMap);
        }
    }, [visits, locations]);


    const handleDateRangeLocationFilter = (locationId: number, startDate: string, endDate: string) => {
        selectedLocationIdRef.current = locationId;
        startDateRef.current = startDate;
        endDateRef.current = endDate;
        fetchVisitsData();
    };

    // Table columns definition
    const columns: OpTableRawColumnType[] = [
        {
            dataIndex: 'hostName',
            label: 'Host Name',
            filter: { type: 'input' },
            sorter: (a, b) => a.hostName.localeCompare(b.hostName),
        },
        {
            dataIndex: 'count',
            label: 'Count',
            filter: { type: 'input' },
            sorter: (a, b) => a.count - b.count,
        },
        {
            dataIndex: 'location',
            label: 'Location',
            filter: {
                type: 'multiSelect',
                options: locations.map(location => ({
                    label: location.name,
                    value: location.id,
                })),
            },
            defaultFilteredValue: locations.map(location => String(location.id)),
            sorter: (a, b) => a.location.localeCompare(b.location),
            onFilter: (value, record) => {
                return String(record.siteId) === String(value); // Compare siteId directly as a string
            },
            hidden: true
        },
    ];

    // Table properties
    const opTableProps: IOpTableProps = {
        dataSource: hostVisitCounts,
        label: `${dayjs(startDateRef.current).format(DATE_FORMAT)} - ${dayjs(endDateRef.current).format(DATE_FORMAT)}`,
        columns: columns,
        rowActions: {
            onEditClick: (hostVisit: HostVisitCount) => {
                setSelectedHostVisitData(hostDataArray[hostVisit.userId]);
                setIsModalVisible(true);
            },
        },
        height: TABLE_HEIGHT,
        allowGlobalSearch: true,
        loading: fetchVisitsLoading,
        allowExport: true,
        allowShowHideColumns: true,
        gtm: 'dashboard-table-gtm',
        rowKey: 'userId',
    };

    const handleClickedVisitor = (visitId: number) => {
        const selectedVisit = visits.find(v => Number(v.id) === Number(visitId));
        dispatch(setSelectedVisit(selectedVisit!));
        setIsModalVisible(false);
        setIsVisitorsDrawerOpen(true);
        setSelectedHostVisitData([]);
    }

    const handleDrawerClose = () => {
        setIsVisitorsDrawerOpen(false);
        dispatch(setSelectedVisit(null));
    };

    return (
        <OpSpace direction="vertical" size="middle" style={{ display: 'flex' }}>
            <DateRangeLocationFilter2
                onDateRangeLocationFilter={handleDateRangeLocationFilter}
                initialStartDate={startDateRef.current}
                initialEndDate={endDateRef.current}
            />
            <OpTable {...opTableProps} />

            <Modal
                title="Host Visits"
                open={isModalVisible}
                onCancel={() => setIsModalVisible(false)}
                footer={null}
                centered
            >
                <List
                    itemLayout="horizontal"
                    dataSource={selectedHostVisitData}
                    renderItem={item => {
                        const colorCode = getStatusColor(item.statusName);
                        return (
                            <List.Item
                                onClick={() => handleClickedVisitor(item.visitId)}
                                style={{ cursor: 'pointer', transition: 'background-color 0.3s' }}
                            >
                                <List.Item.Meta
                                    avatar={profileIcon({ fullName: item.visitName.replace(/\s\(\+\d+\)$/, ''), size: 40, color: colorCode })}
                                    title={item.visitName}
                                    description={`${item.dateTime} - ${item.statusName}`}
                                />
                            </List.Item>
                        );
                    }}
                    style={{ overflowY: 'auto', maxHeight: '50vh' }}
                />
            </Modal>
            {(isVisitorsDrawerOpen) && (
                <VisitorsDrawer
                    open={isVisitorsDrawerOpen}
                    isProfileDrawerOpen={isProfileDrawerOpen}
                    onClose={handleDrawerClose}
                    setIsProfileDrawerOpen={setIsProfileDrawerOpen}
                />
            )}
        </OpSpace>
    );
}

export default HostReportTable;
