import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { getRequest, postRequest } from 'api/apiClient';
import dayjs from 'dayjs';
import { OpSpace } from 'components/customAntd/DLS/OpSpace/OpSpace';
import { DATE_FORMAT } from 'constants/dates';
import { OpButton } from 'components/customAntd/DLS/OpButton/OpButton';
import { OpFormDrawer } from 'components/customAntd/DLS/OpFormDrawer/OpFormDrawer';
import { IOnSubmitArgs, OpForm } from 'components/customAntd/DLS/OpForm/OpForm';
import { DRAWER_WIDTH } from 'constants/ui';
import { Subscription } from 'types/subscriptionTypes';
import { Col, notification, Row, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { NOTIFICATION_ERROR, NOTIFICATION_SUCCESS } from 'constants/messages';
import { formatFullName, hasPermission } from 'utils/utils';

const LicenseTab: React.FC = () => {
    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);
    const { globalUser } = useSelector((state: RootState) => state.users);

    const [subscription, setSubscription] = useState<Subscription | null>(null);
    const [isNewSubscriptionDrawerOpen, setIsNewSubscriptionDrawerOpen] = useState(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [selectedPackageId, setSelectedPackageId] = useState<String>("");

    const tokenScopeList = useSelector((state: RootState) => state.auth.auth.data[0]?.tokenScopeList || []);
    const hasAccountWrite = hasPermission(tokenScopeList, orgId, 'o', 'account:w');

    const [form] = OpForm.useForm();

    const availablePackages = [
        { name: 'Essential', code: 'package-essential', id: '100' },
        { name: 'Pro', code: 'package-pro', id: '200' },
        { name: 'Enterprise', code: 'package-enterprise', id: '300' }
    ];

    const initialValues = globalUser ? {
        name: formatFullName(globalUser.identity.firstName, globalUser.identity.middleName, globalUser.identity.lastName),
        email: globalUser.identity.email,
        phone: globalUser.identity.mobilePhone,
        packageId: selectedPackageId
    } : undefined;

    useEffect(() => {
        const fetchPackages = async () => {
            setLoading(true);
            try {
                const response = await getRequest(`/orgs/${orgId}/subscriptions`);
                setSubscription(response.data[0]);
            } catch (error) {
                console.error('Failed to fetch subscriptions:', error);
            } finally {
                setLoading(false);
            }
        };
        fetchPackages();
    }, [orgId]);


    const handleSubmit = useCallback(async ({ values }: IOnSubmitArgs) => {
        if (!selectedPackageId) return;
        // Map packageId to numeric values
        let updatedValues = { ...values };
        updatedValues.packageId = values.packageId;

        setIsSubmitting(true);
        try {
            await postRequest(`/orgs/${orgId}/subscriptionRequest`, updatedValues);
            notification.success({
                message: NOTIFICATION_SUCCESS,
                description: "Thank you for your change request. Our team will contact you shortly.",
                placement: 'bottomRight',
            });
            form.resetFields();
            setIsNewSubscriptionDrawerOpen(false);
        } catch (error) {
            notification.error({
                message: NOTIFICATION_ERROR,
                description: 'Failed to submit request.',
                placement: 'bottomRight',
            });
            console.error("Form submission failed:", error);
        } finally {
            setIsSubmitting(false);
        }
    }, [form, selectedPackageId, orgId]);

    return (
        <div>
            {(loading || isSubmitting) ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                    <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
                </div>
            ) : (
                <Row gutter={[16, 16]}>
                    {availablePackages.map((pkg) => (
                        <Col xs={24} sm={24} md={24} lg={24} xl={24} key={pkg.code}>
                            <Row
                                align="middle"
                                style={{
                                    padding: '10px',
                                    border: '1px solid',
                                    borderRadius: '16px',
                                    borderColor: subscription?.package.code === pkg.code ? '#1890ff' : '#ccc',
                                }}
                            >
                                <Col
                                    xs={24}
                                    sm={24}
                                    md={20}
                                    lg={20}
                                    xl={20}
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '8px',
                                        flexWrap: 'wrap',
                                        padding: '0 16px'
                                    }}
                                >
                                    <strong>{pkg.name}</strong>
                                    {subscription?.package.code === pkg.code && (
                                        <span style={{
                                            color: '#1890ff',
                                            fontSize: '12px'
                                        }}>
                                            (Current Package: {subscription?.paying ? "Renews" : "Expires"} on{" "}
                                            {dayjs(subscription?.endDate).local().format(DATE_FORMAT)})
                                        </span>
                                    )}
                                </Col>

                                <Col
                                    xs={24}
                                    sm={24}
                                    md={4}
                                    lg={4}
                                    xl={4}
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                        alignItems: 'center',
                                        padding: '0 16px'
                                    }}
                                >
                                    {subscription?.package.code === pkg.code ? (
                                        <OpButton
                                            type="primary"
                                            block
                                            style={{
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                minWidth: '200px'
                                            }}
                                            onClick={() => {
                                                setSelectedPackageId(pkg.id);
                                                setIsNewSubscriptionDrawerOpen(true);
                                            }}
                                            disabled={!hasAccountWrite}
                                        >
                                            Renew Now
                                        </OpButton>
                                    ) : (
                                        <OpButton
                                            type="default"
                                            block
                                            style={{
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                minWidth: '200px'
                                            }}
                                            onClick={() => {
                                                setSelectedPackageId(pkg.id);
                                                setIsNewSubscriptionDrawerOpen(true);
                                            }}
                                            disabled={!hasAccountWrite}
                                        >
                                            Request Package
                                        </OpButton>
                                    )}
                                </Col>
                            </Row>
                        </Col>
                    ))}
                </Row>
            )}

            <OpFormDrawer
                form={form}
                title={'Package Request'}
                width={DRAWER_WIDTH}
                open={isNewSubscriptionDrawerOpen}
                onClose={() => {
                    form.resetFields();
                    setIsNewSubscriptionDrawerOpen(false);
                }}
                submitButtonLabel={"Send Request"}
                formComponent={
                    <OpForm
                        form={form}
                        initialValues={initialValues}
                        onSubmit={handleSubmit}
                        hasError={false}
                        defaultButtons={false}
                    >
                        <OpSpace direction="vertical" size="middle">
                            <div style={{ marginBottom: '20px', lineHeight: '1.6', color: '#4A4A4A' }}>
                                <p style={{ marginBottom: '10px' }}>
                                    Please provide any additional requirements for your license and our team will reach out to you regarding your account changes.
                                </p>
                                <p style={{ marginBottom: '10px' }}>
                                    You can also contact us and our team will assist you with your account changes.
                                </p>
                            </div>
                            <OpForm.Select
                                label="Select Package"
                                name="packageId"
                                options={availablePackages
                                    .map(pkg => ({
                                        label: pkg.name,
                                        value: pkg.id
                                    }))}
                                filterSort={(optionA, optionB) => (optionA.value as number) - (optionB.value as number)}
                                rules={[{ required: true, message: 'Please select a package.' }]}
                            />
                            <OpForm.Input
                                label="Name"
                                name="name"
                                rules={[{ required: true, message: 'Please enter your name.' }]}
                            />
                            <OpForm.Input
                                label="Email"
                                name="email"
                                rules={[
                                    { required: true, message: 'Please enter your email.' },
                                    { type: 'email', message: 'Please enter a valid email.' }
                                ]}
                            />
                            <OpForm.Input
                                label="Phone"
                                name="phone"
                                rules={[{ required: true, message: 'Please enter your phone number.' }]}
                            />
                            <OpForm.TextAreaInput
                                label="Additional Changes Requested"
                                name="notes"
                                rows={4}
                            />
                        </OpSpace>
                    </OpForm>
                }
            />
        </div>
    );
};

export default LicenseTab;
