import React, { useEffect, useState } from 'react';
import { Modal, notification, Spin } from 'antd';
import { OpForm } from 'components/customAntd/DLS/OpForm/OpForm';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store/store';
import { formatFullName, hasPermission } from 'utils/utils';
import dayjs from 'dayjs';
import { User } from 'types/userTypes';
import { getRequest, patchRequest, postRequest } from 'api/apiClient';
import { VisitorType } from 'types/VisitorTypeTypes';
import { DATE_TIME_AM_PM_FORMAT, DATE_TIME_FORMAT } from 'constants/dates';
import STATUS from 'constants/status';
import { fetchVisits } from 'store/slices/visitsSlice';
import { FormFieldWorkflow, VisitData } from 'types/formFieldTypes';
import { OpTabs } from 'components/customAntd/DLS/OpTabs/OpTabs';
import { LoadingOutlined } from '@ant-design/icons';
import { fetchInvitationConfig } from 'store/slices/visitorInvitationSlice';
import { VisitorWorkflowFields } from 'types/visitorWorkflowTypes';
import { searchVisitorsToday } from 'store/slices/visitorSearchSlice';

interface ModalFormProps {
    open: boolean;
    onClose: () => void;
    setLoading: (loading: boolean) => void;
}

const VisitorsEditModal: React.FC<ModalFormProps> = ({ open, onClose, setLoading }) => {
    const dispatch: AppDispatch = useDispatch();
    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);
    const globalUserId = useSelector((state: RootState) => state.users.globalUser?.id);
    const { selectedVisit } = useSelector((state: RootState) => state.visits);
    const { invitationConfig } = useSelector((state: RootState) => state.visitorInvitation);
    const tokenScopeList = useSelector((state: RootState) => state.auth.auth.data[0]?.tokenScopeList || []);

    const [hosts, setHosts] = useState<User[]>([]);
    const [isHostAvailable, setIsHostAvailable] = useState(false);
    const [visitorTypes, setVisitorTypes] = useState<VisitorType[]>([]);
    const [customFieldsWorkflow, setCustomFieldsWorkflow] = useState<FormFieldWorkflow[]>([]);
    const [visitData, setVisitData] = useState<VisitData[]>([]);
    const [activeKey, setActiveKey] = useState<string>('details');
    const [isModalLoading, setIsModalLoading] = useState<boolean>(false);

    const [signInWorkflowFields, setSignInWorkflowFields] = useState<VisitorWorkflowFields>();

    const hasAllVisitorsWrite = hasPermission(tokenScopeList, orgId, 'o', 'allvisitors:w');

    useEffect(() => {
        if (open) {
            setActiveKey('details');
        }
    }, [open]);

    const [form] = OpForm.useForm();

    useEffect(() => {
        const fetchData = async () => {
            setIsModalLoading(true);
            try {
                const locationWorkflowResponse = await getRequest(`/orgs/${orgId}/sites/${selectedVisit?.site.id}/workflows`);

                const users = await getRequest(`/orgs/${orgId}/users`);
                const filteredUsers = users.data.filter((user: User) => user.status === 1 && (user.hostExclude === 0 || user.hostExclude === null));

                if (selectedVisit?.host?.userId && (filteredUsers.length === 0 || !filteredUsers.some((user: User) => user.id === selectedVisit?.host.userId))) {
                    try {
                        const response = await getRequest(`/orgs/${orgId}/users/${selectedVisit?.host.userId}`);
                        const hostUser = response.data;

                        // Combine filteredUsers and hostUser
                        const updatedHosts = [...filteredUsers, ...hostUser];

                        // Apply permission filtering
                        const filteredHosts = hasAllVisitorsWrite
                            ? updatedHosts
                            : updatedHosts.filter(host => host.id === globalUserId);

                        setHosts(filteredHosts);
                        setIsHostAvailable(filteredHosts.length > 0);
                    } catch (error) {
                        console.error("Failed to fetch host user:", error);
                    }
                } else {
                    const filteredHosts = hasAllVisitorsWrite
                        ? filteredUsers
                        : filteredUsers.filter((host: User) => host.id === globalUserId);

                    setHosts(filteredHosts);
                    setIsHostAvailable(filteredUsers.length > 0);
                }

                const visitorTypes = await getRequest(`/orgs/${orgId}/visitorTypes`);
                setVisitorTypes(visitorTypes.data);

                const customFieldsWorkflow = await getRequest(`/orgs/${orgId}/visitorWorkflow/${locationWorkflowResponse.data[0].SignInWorkflowId}/fieldCustom`);
                setCustomFieldsWorkflow(customFieldsWorkflow.data);

                const visitData = await getRequest(`/orgs/${orgId}/visitor/${selectedVisit?.id}/visitorData`);
                // const filteredVisitData = visitData.data.filter((visitData: VisitData) => visitData.formField.status === 1);
                setVisitData(visitData.data);

                if (invitationConfig === null) {
                    await dispatch(fetchInvitationConfig({ orgId }));
                }
                const signInWorkflowConfigResponse = await getRequest(`/orgs/${orgId}/visitorWorkflow/${locationWorkflowResponse.data[0].SignInWorkflowId}/fieldConfig`);
                setSignInWorkflowFields(signInWorkflowConfigResponse.data[0]);
            } catch (error) {
                console.log("Failed to fetch data.");
            } finally {
                setIsModalLoading(false);
            }
        };
        fetchData();
    }, [orgId, selectedVisit?.id, dispatch, globalUserId, hasAllVisitorsWrite, invitationConfig, selectedVisit?.site.id, selectedVisit?.host.userId]);

    const initialValues: { [key: string]: any } = {
        company: selectedVisit?.company,
        siteId: selectedVisit?.site?.id,
        visitorType: selectedVisit?.visitorType?.id,
        hostId: selectedVisit?.host?.userId,
        purpose: selectedVisit?.purpose,
        signIn: dayjs(selectedVisit?.signIn),
        signOut: dayjs(selectedVisit?.signOut),
        scheduleStart: dayjs(selectedVisit?.scheduleStart),
        scheduleEnd: dayjs(selectedVisit?.scheduleEnd),
    };

    // Add visitData fields to initialValues
    visitData.forEach((data) => {
        const { formField, value } = data;
        initialValues[formField.id] = value;
    });

    const handleFieldsChange = (changedFields: any) => {
        const changedField = changedFields[0];
        if (changedField) {
            const { name, value } = changedField;
            const [field] = name;

            if (field === 'scheduleStart') {
                const scheduleStart = value;
                if (scheduleStart) {
                    const startMoment = dayjs(scheduleStart);
                    const newEndTime = startMoment.add(1, 'hour');
                    form.setFieldsValue({ scheduleEnd: newEndTime });
                }
            } else if (field === 'scheduleEnd') {
                const scheduleEnd = value;
                const scheduleStart = form.getFieldValue('scheduleStart');

                if (scheduleStart && scheduleEnd) {
                    const startMoment = dayjs(scheduleStart);
                    const endMoment = dayjs(scheduleEnd);

                    if (startMoment.isSameOrAfter(endMoment)) {
                        form.setFieldsValue({ scheduleStart: endMoment.subtract(1, 'hour') });
                    }
                }
            }
        }
    };

    const editVisit = async (updatedVisit: any) => {
        if (updatedVisit.scheduleStart && updatedVisit.scheduleEnd && updatedVisit.scheduleEnd.isSameOrBefore(updatedVisit.scheduleStart)) {
            notification.error({
                message: 'Error',
                description: 'End date cannot be before start date.',
                placement: 'bottomRight',
            });
            return;
        }

        if (updatedVisit.signIn && updatedVisit.signOut && updatedVisit.signOut.isSameOrBefore(updatedVisit.signIn)) {
            notification.error({
                message: 'Error',
                description: 'End date cannot be before start date.',
                placement: 'bottomRight',
            });
            return;
        }

        setLoading(true);


        try {
            const filteredUpdatedVisit = Object.fromEntries(
                Object.entries(updatedVisit).filter(([key]) => isNaN(Number(key)))
            );

            await patchRequest(`/orgs/${orgId}/visitor/${selectedVisit?.id}`, {
                visitorType: filteredUpdatedVisit.visitorType,
                company: filteredUpdatedVisit.company,
                hostId: filteredUpdatedVisit.hostId,
                purpose: filteredUpdatedVisit.purpose,
                signIn: updatedVisit.signIn ? dayjs(updatedVisit.signIn).format(DATE_TIME_FORMAT) : undefined,
                signOut: updatedVisit.signOut ? dayjs(updatedVisit.signOut).format(DATE_TIME_FORMAT) : undefined,
                scheduleIn: updatedVisit.scheduleStart?.format(DATE_TIME_FORMAT),
                scheduleOut: updatedVisit.scheduleEnd?.format(DATE_TIME_FORMAT)
            });

            for (const key in updatedVisit) {
                const field = updatedVisit[key];
                const initialField = initialValues[key];

                // Check if the field is a custom field (custom fields have numeric keys)
                const isCustomField = !isNaN(Number(key));
                if (isCustomField && field !== initialField) {
                    // Add field if enabled
                    if (field && !initialField && !visitData.find(item => item.formField.id === Number(key))) {
                        try {
                            await postRequest(`/orgs/${orgId}/visitor/${selectedVisit?.id}/visitorData`, {
                                fieldId: key,
                                value: field,
                            });
                            console.log(`Added custom field ${key}.`);
                        } catch (error) {
                            console.log(`Failed to add custom field ${key}:`, error);
                        }
                    } else {
                        try {
                            const dataId = visitData.find((data) => Number(data.formField.id) === Number(key))?.id;
                            await patchRequest(`/orgs/${orgId}/visitor/${selectedVisit?.id}/visitorData/${dataId}`, {
                                fieldId: key,
                                value: field,
                            });
                            console.log(`Updated custom field ${key}.`);
                        } catch (error) {
                            console.log(`Failed to update custom field ${key}:`, error);
                        }
                    }
                }
            }
            await dispatch(fetchVisits({ orgId }));
            await dispatch(searchVisitorsToday({ orgId }));

            notification.success({
                message: 'Success',
                description: 'Visit updated successfully.',
                placement: 'bottomRight',
            });
        } catch (error) {
            notification.error({
                message: 'Error',
                description: 'Failed to update visit.',
                placement: 'bottomRight',
            });
        } finally {
            setLoading(false);
        }
    };

    const renderFormFields = () => {
        const customFieldIds = customFieldsWorkflow.map(field => field.id);
        const isPending = selectedVisit?.visitStatus.id === STATUS.PENDING.id;

        return (
            <>
                {customFieldsWorkflow.map((formField, index) => {
                    const rules = isPending
                        ? [] // No required rule when visit status is pending
                        : [{ required: formField.required === 1 }];

                    if (formField.values) {
                        const options = formField.values.map((option) => ({
                            label: option.display || option.name,
                            value: option.value,
                        }));

                        return (
                            <OpForm.Select
                                key={formField.id}
                                label={formField.name}
                                name={formField.id}
                                options={options}
                                rules={rules}
                                placeholder={formField.placeholder ? formField.placeholder : undefined}
                            />
                        );
                    } else {
                        return (
                            <OpForm.Input
                                key={formField.id}
                                label={formField.name}
                                name={formField.id}
                                rules={rules}
                                placeholder={formField.placeholder ? formField.placeholder : undefined}
                            />
                        );
                    }
                })}

                {visitData
                    .filter(visitField => !customFieldIds.includes(visitField.formField.id)) // Fields not in customFieldsWorkflow
                    .map(visitField => {
                        const formField = visitField.formField;

                        if (formField.values) {
                            const options = formField.values.map((option) => ({
                                label: option.display || option.name,
                                value: option.value,
                            }));

                            return (
                                <OpForm.Select
                                    key={formField.id}
                                    label={formField.name}
                                    name={formField.id}
                                    options={options}
                                    placeholder={formField.placeholder ? formField.placeholder : undefined}
                                />
                            );
                        } else {
                            return (
                                <OpForm.Input
                                    key={formField.id}
                                    label={formField.name}
                                    name={formField.id}
                                    placeholder={formField.placeholder ? formField.placeholder : undefined}
                                />
                            );
                        }
                    })}
            </>
        );
        // return customFieldsWorkflow.map((formField, index) => {
        //     if (formField.values) {
        //         const options = formField.values.map((option) => ({
        //             label: option.display || option.name,
        //             value: option.value,
        //         }));

        //         return (
        //             <OpForm.Select
        //                 label={formField.name}
        //                 name={formField.id}
        //                 options={options}
        //                 rules={[{ required: formField.required === 1 }]}
        //                 placeholder={formField.placeholder ? formField.placeholder : undefined}
        //             />
        //         );
        //     } else {
        //         return (
        //             <OpForm.Input
        //                 label={formField.name}
        //                 name={formField.id}
        //                 rules={[{ required: formField.required === 1 }]}
        //                 placeholder={formField.placeholder ? formField.placeholder : undefined}
        //             />
        //         );
        //     }
        // });
    };

    // Define the tabs with the `items` prop
    const tabItems = [
        {
            key: 'details',
            label: 'Details',
            children: (
                <>
                    {selectedVisit?.visitStatus.id === STATUS.PENDING.id && (
                        <>
                            <OpForm.Select
                                label="Visitor Type" name="visitorType" rules={[{ required: true, message: 'Please enter Visitor Type' }]}
                                placeholder="Select Visitor Type"
                                options={visitorTypes.map(type => ({
                                    label: type.name,
                                    value: type.id
                                }))}
                            />
                            <OpForm.DatePicker
                                format={DATE_TIME_AM_PM_FORMAT}
                                showTime
                                minuteStep={5}
                                name="scheduleStart"
                                label="Schedule Start Date / Time"
                                rules={[{ required: true, message: 'Please select a date' }]}
                            />
                            <OpForm.DatePicker
                                format={DATE_TIME_AM_PM_FORMAT}
                                showTime
                                minuteStep={5}
                                name="scheduleEnd"
                                label="Schedule End Date / Time"
                                rules={[{ required: true, message: 'Please select a date' }]}
                            />
                        </>
                    )}
                    {(selectedVisit?.visitStatus.id === STATUS.SIGNED_IN.id || selectedVisit?.visitStatus.id === STATUS.SIGNED_OUT.id) && (
                        <OpForm.DatePicker
                            format={DATE_TIME_AM_PM_FORMAT}
                            showTime
                            minuteStep={5}
                            name="signIn"
                            label="Sign In Date / Time"
                            rules={[{ required: true, message: 'Please select a date' }]}
                        />
                    )}
                    {selectedVisit?.visitStatus.id === STATUS.SIGNED_OUT.id && (
                        <OpForm.DatePicker
                            format={DATE_TIME_AM_PM_FORMAT}
                            showTime
                            minuteStep={5}
                            name="signOut"
                            label="Sign Out Date / Time"
                            rules={[{ required: true, message: 'Please select a date' }]}
                        />
                    )}
                    <OpForm.Input label="Company" name="company"
                        rules={[
                            {
                                required:
                                    (selectedVisit?.visitStatus.id === STATUS.PENDING.id && invitationConfig?.visitCompany.required === 1) ||
                                    ((selectedVisit?.visitStatus.id === STATUS.SIGNED_IN.id ||
                                        selectedVisit?.visitStatus.id === STATUS.SIGNED_OUT.id ||
                                        selectedVisit?.visitStatus.id === STATUS.DENIED_ENTRY.id
                                    ) && signInWorkflowFields?.visitCompany.required === 1)
                            }
                        ]}
                    />
                    <OpForm.Select
                        label="Host" name="hostId"
                        showSearch
                        placeholder={isHostAvailable ? "Select Host" : "No hosts are available"}
                        disabled={!isHostAvailable}
                        filterOption={(input, option) => {
                            const label = option?.label;
                            return typeof label === 'string' && label.toLowerCase().includes(input.toLowerCase());
                        }}
                        rules={[
                            {
                                required:
                                    (selectedVisit?.visitStatus.id === STATUS.PENDING.id && invitationConfig?.host.required === 1) ||
                                    ((selectedVisit?.visitStatus.id === STATUS.SIGNED_IN.id ||
                                        selectedVisit?.visitStatus.id === STATUS.SIGNED_OUT.id ||
                                        selectedVisit?.visitStatus.id === STATUS.DENIED_ENTRY.id
                                    ) && signInWorkflowFields?.host.required === 1)
                            }
                        ]}
                        options={hosts.map(user => ({
                            label: user.identity.firstName || user.identity.lastName
                                ? formatFullName(user.identity.firstName, user.identity.middleName, user.identity.lastName)
                                : user.identity.email,
                            value: user.id
                        }))
                        }
                    />
                    <OpForm.TextAreaInput rows={3} label="Purpose" name="purpose"
                        rules={[
                            {
                                required:
                                    (selectedVisit?.visitStatus.id === STATUS.PENDING.id && invitationConfig?.visitPurpose.required === 1) ||
                                    ((selectedVisit?.visitStatus.id === STATUS.SIGNED_IN.id ||
                                        selectedVisit?.visitStatus.id === STATUS.SIGNED_OUT.id ||
                                        selectedVisit?.visitStatus.id === STATUS.DENIED_ENTRY.id
                                    ) && signInWorkflowFields?.visitPurpose.required === 1)
                            }
                        ]}
                    />
                </>
            ),
        },
        // Conditionally add the "Additional Info" tab only if customFieldsWorkflow has data
        ...(customFieldsWorkflow.length > 0 || visitData.length > 0
            ? [{
                key: 'additionalInfo',
                label: 'Additional Info',
                children: renderFormFields(),
            }]
            : []
        ),
    ];

    return (
        <Modal
            open={open}
            title={'Edit Visit'}
            okText="Save"
            cancelText="Cancel"
            onCancel={() => {
                form.resetFields();
                onClose();
            }}
            onOk={() => {
                form
                    .validateFields()
                    .then((values) => {
                        form.submit();
                        onClose();
                    })
                    .catch((errorInfo) => {
                        notification.error({
                            message: 'Validation Error',
                            description: 'Please fill in all required fields.',
                            placement: 'bottomRight',
                        });
                    });
            }}
            centered
        >
            {isModalLoading ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                    <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
                </div>
            ) : (
                <div style={{ overflowY: 'auto', maxHeight: '63vh', padding: '6px' }}>
                    <OpForm
                        form={form}
                        initialValues={initialValues}
                        onFieldsChange={handleFieldsChange}
                        onSubmit={({ values }) => {
                            editVisit(values);
                            form.resetFields();
                        }}
                        hasError={false}
                        defaultButtons={false}
                    >
                        <OpTabs activeKey={activeKey} onChange={setActiveKey} items={tabItems} />
                    </OpForm>
                </div>
            )}
        </Modal>
    );
};

export default VisitorsEditModal;
