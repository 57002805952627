import React, { useCallback, useEffect, useState } from 'react';
import { Layout, QRCode, Space, Spin, message } from 'antd';
import { useLocation } from 'react-router-dom';
import './RegisterCompleted.scss';
import { OpDivider } from 'components/customAntd/DLS/OpDivider/OpDivider';
import axios from 'axios';
import dayjs from 'dayjs';
import { DATE_TIME_AM_PM_FORMAT, DATE_TIME_FORMAT } from 'constants/dates';
import STATUS from 'constants/status';
import { OpButton } from 'components/customAntd/DLS/OpButton/OpButton';

const { Content } = Layout;

const apiUrl = process.env.REACT_APP_BACKEND_URL;

const RegisterCompleted: React.FC = () => {
    const location = useLocation();

    const [isValid, setIsValid] = useState<boolean | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [qrCode, setQrCode] = useState<string | null>(null);
    const [expirationTime, setExpirationTime] = useState<dayjs.Dayjs | null>(null);
    const { token } = location.state || {};
    const visitorToken = token || '';

    const registerVisitorToken = useCallback(async () => {
        if (!visitorToken) {
            message.error('No visitor token found.');
            return;
        }
        try {
            const response = await axios.post(
                `${apiUrl}/visitors/qrcode?token=${visitorToken}`,
                {
                    "visitorToken": visitorToken,
                    "expiresUtc": dayjs().utc().add(5, 'minute').format(DATE_TIME_FORMAT)
                }
            );
            const qrData = response.data?.data?.[0];

            if (qrData?.code) {
                setQrCode(qrData.code);

                const expires = dayjs(qrData.expiresUtc).utc(true).local();
                setExpirationTime(expires);
            } else {
                message.error('Failed to get QR code.');
            }
        } catch (error) {
            console.error(error);
        }
    }, [visitorToken]);


    const checkAndRefreshQrCode = useCallback(async () => {
        try {
            const response = await axios.get(`${apiUrl}/visitors/qrcode?token=${visitorToken}`);
            const qrData = response.data?.data?.[0];

            if (!qrData?.code || !qrData?.expiresUtc) {
                await registerVisitorToken();
                return;
            }

            const expires = dayjs(qrData.expiresUtc, DATE_TIME_FORMAT).utc(true).local();
            if (expires.isBefore(dayjs())) {
                await registerVisitorToken();
            } else {
                setQrCode(qrData.code);
                setExpirationTime(expires);
            }
        } catch (error) {
            console.error('QR Code refresh error:', error);
            message.error('Failed to refresh QR code');
        }
    }, [visitorToken, registerVisitorToken]);


    const validateAccess = useCallback(async () => {
        setIsLoading(true);
        try {
            const tokenValidationRes = await axios.post(`${apiUrl}/visitors/accessTokens/${token}/validate`);
            if (!tokenValidationRes.data.data.isValid) {
                setIsValid(false);
                setIsLoading(false);
                return false;
            }

            const visitorRes = await axios.get(`${apiUrl}/visitors?token=${token}`);
            const visitorData = visitorRes.data.data[0];
            const visitor = visitorData.visitors?.[0];

            if (!visitor) {
                setIsValid(false);
                setIsLoading(false);
                return false;
            }

            const scheduleIn = dayjs(visitorData.scheduleStart);
            const scheduleOut = dayjs(visitorData.scheduleEnd);
            const now = dayjs();

            let isWithinAllowedTime = false;

            if (visitor.status === STATUS.PENDING.id) {
                isWithinAllowedTime = now.isBefore(scheduleOut.add(30, 'minutes'));
            } else if ([STATUS.SIGNED_IN.id, STATUS.SIGNED_OUT.id].includes(visitor.status)) {
                isWithinAllowedTime = now.isBetween(scheduleIn.subtract(30, 'minutes'), scheduleOut.add(30, 'minutes'));
            }


            if (!isWithinAllowedTime) {
                setIsValid(false);
                setIsLoading(false);
                return false;
            }

            setIsValid(true);
            setIsLoading(false);
            return true;
        } catch (error) {
            console.error('Validation error:', error);
            setIsValid(false);
            setIsLoading(false);
            return false;
        }
    }, [token]);

    useEffect(() => {
        let timeout: NodeJS.Timeout;

        const validateAndRefresh = async () => {
            const valid = await validateAccess(); // Validate first

            if (valid) {
                if (!expirationTime) {
                    await checkAndRefreshQrCode(); // Ensure QR is generated on first load
                } else {
                    const now = dayjs();
                    const delay = expirationTime.diff(now, 'milliseconds'); // Time until expiration

                    if (delay > 0) {
                        timeout = setTimeout(checkAndRefreshQrCode, delay); // Refresh QR at expiration time
                    } else {
                        await checkAndRefreshQrCode(); // If expired, refresh immediately
                    }
                }
            }

        };

        validateAndRefresh(); // Run immediately on mount

        return () => clearTimeout(timeout); // Cleanup on unmount
    }, [expirationTime, checkAndRefreshQrCode, validateAccess]);


    return (
        <Layout className="register-completed-layout">
            <Content className="register-completed-content">
                {
                    isLoading ? (
                        <div className="loading-container">
                            <Spin className="loading-message" />
                        </div>
                    ) : !isValid ? (
                        <>
                            <img src="/images/invisit_full.png" alt="Logo" className="logo" />
                            <div className="message-box">
                                <span>Invalid or expired link.</span>
                            </div>
                        </>
                    ) : (
                        <>
                            <img src="/images/invisit_full.png" alt="Logo" className="logo" />
                            <div className="register-header">
                                <p>You are now pre-registered for your visit.</p>
                                <p>Please present the QR code below when you sign-in.</p>
                            </div>
                            <OpDivider />
                            <Space id="myqrcode" className="qr-code-container" direction="vertical">
                                {qrCode ? (
                                    <>
                                        <QRCode
                                            type="svg"
                                            value={qrCode}
                                            style={{ width: '300px', height: '300px' }}
                                            color="#000000"  // Dark QR code
                                            bgColor="#FFFFFF" // Bright background
                                            onClick={checkAndRefreshQrCode}
                                        />
                                        <div style={{
                                            fontSize: 12,
                                            color: '#2f2f2f',
                                            marginTop: 8,
                                            textAlign: 'center'
                                        }}>
                                            Expires: {expirationTime?.format(DATE_TIME_AM_PM_FORMAT)}
                                        </div>
                                    </>
                                ) : (
                                    <p>Generating QR code...</p>
                                )}
                            </Space>
                            <OpDivider />
                            <OpButton
                                type='primary'
                                className="download-button"
                                onClick={checkAndRefreshQrCode}
                            >
                                Tap to Refresh
                            </OpButton>
                        </>
                    )}
            </Content>
            <footer className="register-footer">
                &copy; {new Date().getFullYear()} Invisit, LLC
            </footer>
        </Layout>
    );
};

export default RegisterCompleted;
