import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store/store';
import { OpModal } from 'components/customAntd/DLS/OpModal/OpModal';
import { IOnSubmitArgs, OpForm } from 'components/customAntd/DLS/OpForm/OpForm';
import { OpButton } from 'components/customAntd/DLS/OpButton/OpButton';
import { patchRequest } from 'api/apiClient';
import { setAuthData } from 'store/slices/authSlice';
import { hasPermission } from 'utils/utils';
import { Col, Row } from 'antd';

const Account: React.FC = () => {
    const dispatch: AppDispatch = useDispatch();
    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);
    const auth = useSelector((state: RootState) => state.auth.auth);
    const selectedOrgName = auth.data[0].tokenScopeList.find(scope => Number(scope.org.id) === Number(orgId))?.org.name;

    const [isEditOrgNameModalOpen, setIsEditOrgNameModalOpen] = useState(false);
    const [organizationName, setOrganizationName] = useState(selectedOrgName);

    const tokenScopeList = useSelector((state: RootState) => state.auth.auth.data[0]?.tokenScopeList || []);
    const hasAccountWrite = hasPermission(tokenScopeList, orgId, 'o', 'account:w');

    const [form] = OpForm.useForm();

    const handleSubmit = useCallback(async ({ values }: IOnSubmitArgs) => {
        await patchRequest(`/orgs/${orgId}`, {
            name: values.organizationName
        });
        setOrganizationName(values.organizationName);

        // Find the matching orgId in the tokenScopeList and update the name in the Redux state
        const updatedAuthData = {
            ...auth,
            data: auth.data.map(authItem => ({
                ...authItem,
                tokenScopeList: authItem.tokenScopeList.map(scope =>
                    scope.org.id === orgId
                        ? { ...scope, org: { ...scope.org, name: values.organizationName } }
                        : scope
                )
            }))
        };
        await dispatch(setAuthData(updatedAuthData));

        setIsEditOrgNameModalOpen(false);
    }, [orgId, auth, dispatch]);


    return (
        <>
            <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Row
                        align="middle"
                        style={{
                            padding: '10px',
                            border: '1px solid',
                            borderRadius: '16px',
                            borderColor: '#ccc'
                        }}
                    >
                        <Col
                            xs={24}
                            sm={24}
                            md={20}
                            lg={20}
                            xl={20}
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '8px',
                                flexWrap: 'wrap',
                                padding: '0 16px'
                            }}
                        >
                            <div>
                                <strong>Organization Name:</strong> {organizationName}
                            </div>
                        </Col>

                        <Col
                            xs={24}
                            sm={24}
                            md={4}
                            lg={4}
                            xl={4}
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                alignItems: 'center',
                                padding: '0 16px'
                            }}
                        >
                            <OpButton
                                type="primary"
                                onClick={() => setIsEditOrgNameModalOpen(true)}
                                disabled={!hasAccountWrite}
                                block
                                style={{
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    minWidth: '200px'
                                }}
                            >
                                Edit
                            </OpButton>
                        </Col>
                    </Row>
                </Col>
            </Row>

            <OpModal
                title="Edit Organization Name"
                open={isEditOrgNameModalOpen}
                onOk={() => form.submit()}
                onCancel={() => {
                    form.resetFields();
                    setIsEditOrgNameModalOpen(false);
                }}
                okText={"Confirm"}
            >
                <OpForm
                    form={form}
                    onSubmit={handleSubmit}
                    hasError={false}
                    defaultButtons={false}
                    initialValues={{ organizationName: selectedOrgName }}
                >
                    <OpForm.Input
                        label="Organization Name"
                        name="organizationName"
                        rules={[
                            {
                                validator: (_, value) => {
                                    if (!value || value.trim() === '') {
                                        return Promise.reject(new Error('Organization name cannot be empty or just spaces.'));
                                    }
                                    return Promise.resolve();
                                }
                            }
                        ]}
                    />
                </OpForm>
            </OpModal>
        </>
    );
};

export default Account;
