import React from 'react';
import { SvgWrapper } from 'components/customAntd/inVision - Platinum Assets/svgs/SvgWrapper';

export const ReportFilledSvg = ({ ...svgProps }) => (
    <SvgWrapper {...svgProps}>
        <path
            fill="currentColor"
            d="M12.5 16H5.5C4.673 16 4 15.327 4 14.5V3.5C4 2.673 4.673 2 5.5 2H9V4.5C9 5.878 10.121 7 11.5 7H14V14.5C14 15.327 13.327 16 12.5 16Z"
        />
        <path
            fill="white"
            d="M13.293 6H11.5C10.673 6 10 5.327 10 4.5V2.707L13.293 6Z"
        />
    </SvgWrapper>
);
