import React from 'react';
import { OpTable, IOpTableProps } from 'components/customAntd/DLS/OpTable/OpTable';
import { OpTableRawColumnType } from 'components/customAntd/DLS/OpTableCore/OpTableCore';
import { Location } from 'types/locationTypes';
import { TABLE_HEIGHT } from 'constants/ui';

interface AvigilonReadersTableProps {
    acus: any[];
    setAcus: (setAcus: any[]) => void;
    handleEditClick: (acu: any) => void;
    loading: boolean;
}

const AvigilonReadersTable: React.FC<AvigilonReadersTableProps> = ({ acus, setAcus, handleEditClick, loading }) => {
    const columns: OpTableRawColumnType[] = [
        {
            dataIndex: 'name',
            label: "NAME",
            filter: {
                type: 'input',
            },
            sorter: (a, b) => (a.name || '').localeCompare(b.name || ''),
        },
        {
            dataIndex: 'notes',
            label: "DESCRIPTION",
            filter: {
                type: 'input',
            },
            sorter: (a, b) => (a.notes || '').localeCompare(b.notes || ''),
        },
        {
            dataIndex: 'statusMessage',
            label: "STATUS",
            filter: {
                type: 'input',
            },
            sorter: (a, b) => (a.statusMessage || '').localeCompare(b.statusMessage || ''),
        },
    ];

    const opTableProps: IOpTableProps = {
        dataSource: acus,
        columns: columns,
        rowActions: {
            onEditClick: (location: Location) => handleEditClick(location),
        },
        height: TABLE_HEIGHT,
        allowGlobalSearch: true,
        loading: loading,
        allowExport: true,
        allowShowHideColumns: true,
    };

    return <>
        <OpTable {...opTableProps} />
    </>;
};

export default AvigilonReadersTable;
