import React, { useRef, useEffect } from 'react';
import { List, Typography } from 'antd';
import { RedFlag } from 'types/redFlagTypes';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import dayjs from 'dayjs';
import { DATE_FORMAT, DATE_TIME_AM_PM_FORMAT } from 'constants/dates';
import { OpModal } from 'components/customAntd/DLS/OpModal/OpModal';
import { StopOutlined, EyeOutlined } from '@ant-design/icons';

const { Text } = Typography;

interface RedFlagDetailsModalProps {
    open: boolean;
    onClose: () => void;
    redFlagData: RedFlag | null;
}

const RedFlagDetailsModal: React.FC<RedFlagDetailsModalProps> = ({ open, onClose, redFlagData }) => {
    const { redFlagLogs } = useSelector((state: RootState) => state.redFlags);
    const scrollableContainerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (open && scrollableContainerRef.current) {
            scrollableContainerRef.current.scrollTop = 0;
        }
    }, [open]);

    // Sort logs in descending order by timestamp
    const sortedLogs = redFlagLogs.slice().sort((a, b) => {
        if (a.timestamp && b.timestamp) {
            return dayjs(b.timestamp).diff(dayjs(a.timestamp));
        }
        return 0;
    });

    const renderListItem = (label: string, value: any) => {
        if (value !== null && value !== undefined) {
            return (
                <List.Item>
                    <Text strong>{label}:</Text> {value}
                </List.Item>
            );
        }
        return null;
    };

    const getLevelNameDisplay = (levelName: string) => {
        if (levelName === 'Blocklist') {
            return (
                <span>
                    <StopOutlined style={{ color: 'rgba(242,103,87,1.0)' }} /> {levelName}
                </span>
            );
        } else if (levelName === 'Watchlist') {
            return (
                <span>
                    <EyeOutlined style={{ color: '#FFD54F' }} /> {levelName}
                </span>
            );
        } else {
            return levelName;
        }
    };

    const profileData = [
        { label: 'Red Flag Type', value: redFlagData ? getLevelNameDisplay(redFlagData.levelName!) : 'N/A' },
        { label: 'First Name', value: redFlagData?.firstName },
        { label: 'Last Name', value: redFlagData?.lastName },
        { label: 'Middle Name', value: redFlagData?.middleName },
    ].filter(item => item.value !== null && item.value !== undefined && item.value !== "");

    const dateData = [
        { label: 'Effective Date', value: redFlagData?.effectiveDate },
        { label: 'Expiration Date', value: dayjs(redFlagData?.expireDate).format(DATE_FORMAT) },
    ].filter(item => item.value !== null && item.value !== undefined && dayjs(item.value).isValid());

    const dobData = [
        { label: 'Date of Birth', value: dayjs(redFlagData?.dateOfBirth).format(DATE_FORMAT) },
    ].filter(item => item.value !== null && item.value !== undefined && dayjs(item.value).isValid());

    return (
        <OpModal
            title="Red Flag Details"
            open={open}
            onOk={onClose}
            onCancel={onClose}
            okText="Close"
            cancelButtonProps={{ style: { display: 'none' } }}
        >
            <div ref={scrollableContainerRef} style={{ overflowY: 'auto', maxHeight: '50vh' }}>
                {profileData.length > 0 && (
                    <List
                        bordered
                        dataSource={profileData}
                        renderItem={(item) => renderListItem(item.label, item.value)}
                    />
                )}
                {profileData.length > 0 && <div style={{ margin: '16px 0' }} />}
                {dateData.length > 0 && (
                    <List
                        bordered
                        dataSource={dateData}
                        renderItem={(item) => renderListItem(item.label, item.value)}
                    />
                )}
                {dateData.length > 0 && <div style={{ margin: '16px 0' }} />}
                {dobData.length > 0 && (
                    <List
                        bordered
                        dataSource={dobData}
                        renderItem={(item) => renderListItem(item.label, item.value)}
                    />
                )}
                {redFlagData && redFlagData.data && redFlagData.data.length > 0 && (
                    <>
                        <div style={{ margin: '16px 0' }} />
                        <List
                            bordered
                            header={<div style={{ color: 'var(--colorPrimary)' }}>Additional Information</div>}
                            dataSource={redFlagData.data}
                            renderItem={(item) => (
                                <List.Item>
                                    <Text strong>{item.data}:</Text> {item.value}
                                </List.Item>
                            )}
                        />
                    </>
                )}
                {sortedLogs.length > 0 && (
                    <>
                        <div style={{ margin: '16px 0' }} />
                        <List
                            bordered
                            header={<div style={{ color: 'var(--colorPrimary)' }}>Logs</div>}
                            dataSource={sortedLogs}
                            renderItem={(item) => (
                                <List.Item>
                                    <List.Item.Meta
                                        title={item.log}
                                        description={dayjs(item.timestamp).utc(true).local().format(DATE_TIME_AM_PM_FORMAT)}
                                    />
                                </List.Item>
                            )}
                        />
                    </>
                )}
            </div>
        </OpModal>
    );
};

export default RedFlagDetailsModal;
