import React, { useCallback } from 'react';
import { notification, Select } from 'antd';
import { IOnSubmitArgs, OpForm } from 'components/customAntd/DLS/OpForm/OpForm';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store/store';
import { postRequest } from 'api/apiClient';
import { formatFullName } from 'utils/utils';
import { Visitor } from 'types/visitTypes';
import { OpModal } from 'components/customAntd/DLS/OpModal/OpModal';
import { fetchVisits } from 'store/slices/visitsSlice';
import { SIGNED_OUT_VISITOR_ID } from 'constants/userActivities';
import { DATE_TIME_FORMAT } from 'constants/dates';
import dayjs from 'dayjs';
import { OpFormItem } from 'components/customAntd/DLS/OpForm/formComponents/OpFormItem/OpFormItem';
import { searchVisitors, searchVisitorsToday } from 'store/slices/visitorSearchSlice';

const ReturnItemsModal: React.FC<{
    open: boolean;
    checkedVisitors: Visitor[];
    onClose: () => void;
    updateVisitorStatus: (visitorId: number) => void;
}> = ({ open, checkedVisitors, onClose, updateVisitorStatus }) => {
    const dispatch: AppDispatch = useDispatch();
    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);
    const globalUserId = useSelector((state: RootState) => state.users.globalUser?.id);
    const { selectedVisit } = useSelector((state: RootState) => state.visits);
    const globalLocationId = useSelector((state: RootState) => state.locations.globalLocation?.id);
    const lastSearchParams = useSelector((state: RootState) => state.visitorSearch.lastSearchParams);

    const [form] = OpForm.useForm();

    const handleOk = async () => {
        form
            .validateFields()
            .then(() => {
                form.submit();
                onClose();
            })
            .catch((errorInfo) => {
                notification.error({
                    message: 'Validation Error',
                    description: 'Please fill in all required fields.',
                    placement: 'bottomRight',
                });
            });
    };

    const handleSubmit = useCallback(async ({ values }: IOnSubmitArgs<any>) => {
        // update status of visit and visitors
        for (const visitor of checkedVisitors) {
            if (visitor.id) {
                await updateVisitorStatus(visitor.id);
            }
        }
        await postRequest(`/orgs/${orgId}/visitor/${selectedVisit?.id}/visitorExit`, {
            signOut: dayjs().format(DATE_TIME_FORMAT),
            confirmReturnItems: values.confirmReturnItems
        });
        notification.success({
            message: 'Success',
            description: 'Signed out successfully.',
            placement: 'bottomRight',
        });

        // log activity
        const fullNameList = checkedVisitors?.map((visitor: Visitor) => formatFullName(visitor.firstName, visitor.middleName, visitor.lastName,)).join(', ');
        await postRequest(`/orgs/${orgId}/userActivity`, {
            userId: globalUserId,
            activityId: SIGNED_OUT_VISITOR_ID,
            details: fullNameList
        });
        await dispatch(fetchVisits({ orgId }));
        if (Object.keys(lastSearchParams).length !== 0) {
            await dispatch(searchVisitors({
                ...lastSearchParams,
                orgId,
                siteId: lastSearchParams.siteId || globalLocationId! // 사이트 우선순위 적용
            }));
        }

        await dispatch(searchVisitorsToday({ orgId }));

        form.resetFields();

        console.log("Visitor updates completed.");
    }, [orgId, selectedVisit, checkedVisitors, dispatch, form, globalUserId, globalLocationId, lastSearchParams, updateVisitorStatus]);

    return (
        <OpModal
            open={open}
            title="Item Return Checklist (e.g. visitor badge)"
            onOk={handleOk}
            onCancel={onClose}
            centered
        >
            <OpForm
                form={form}
                onSubmit={handleSubmit}
                defaultButtons={false}
                hasError={false}
            >
                <OpFormItem
                    label="Have items been returned?" name="confirmReturnItems"
                    style={{ width: '100%' }}
                    rules={[{ required: true, message: 'Please select an option.' }]}
                >
                    <Select
                        placeholder="Select status"
                        options={[
                            { label: 'Yes', value: 1 },
                            { label: 'No', value: 0 }
                        ]}
                    />
                </OpFormItem>
            </OpForm>
        </OpModal>
    );
};

export default ReturnItemsModal;
