import React from 'react';
import VisitorList from './formComponents/VisitorList';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'store/store';
import ProfileDrawer from '../profile/ProfileDrawer';
import { setSelectedVisitor } from 'store/slices/visitsSlice';

interface VisitorsTabProps {
    isProfileDrawerOpen: boolean;
    setIsProfileDrawerOpen: (open: boolean) => void;
}

const VisitorsTab: React.FC<VisitorsTabProps> = ({ isProfileDrawerOpen, setIsProfileDrawerOpen }) => {
    const dispatch: AppDispatch = useDispatch();

    const handleDrawerClose = () => {
        setIsProfileDrawerOpen(false);
        dispatch(setSelectedVisitor(null));
    };

    return (
        <>
            <VisitorList
                openProfileDrawer={() => setIsProfileDrawerOpen(true)}
                closeProfileDrawer={() => setIsProfileDrawerOpen(false)}
            />
            {(isProfileDrawerOpen) && (
                <ProfileDrawer
                    open={isProfileDrawerOpen}
                    onClose={handleDrawerClose}
                />
            )}
        </>
    )
};

export default VisitorsTab;
