import React from 'react';
import { Space } from "antd";
import { OpCard } from 'components/customAntd/DLS/OpCard/OpCard';

interface QuickActionCardProps {
    title: string;
    icon: React.ReactNode;
    color: string;
    onClick?: () => void;
}

const QuickActionCard: React.FC<QuickActionCardProps> = ({ title, icon, color, onClick }) => {
    return (
        <OpCard
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '8px',
                backgroundColor: color,
                cursor: 'pointer',
                transition: 'background-color 0.3s'
            }}
            onClick={onClick}
            onMouseEnter={e => {
                e.currentTarget.style.transform = 'scale(1.05)'; // Scale up on hover
            }}
            onMouseLeave={e => {
                e.currentTarget.style.transform = 'scale(1)'; // Scale back to normal
            }}
        >
            <Space direction="vertical" align="center" style={{ textAlign: 'center' }}>
                <div style={{ textAlign: 'center' }}>
                    {icon}
                </div>
                <div style={{
                    marginTop: '10px',
                    fontSize: '16px',
                    color: 'var(--colorTextSecondary)',
                    textTransform: 'uppercase',
                    letterSpacing: '1px'
                }}>
                    {title}
                </div>
            </Space>
        </OpCard>
    );
}

export default QuickActionCard;
