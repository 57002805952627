import { Modal } from 'antd';

interface ConfirmModalOptions {
	title: string; // Title of the modal
	content: string; // Content of the modal
	okText: string; // Text for the OK button
	cancelText: string; // Text for the cancel button
	onOk: () => void; // Function to call when OK is clicked
	onCancel?: () => void; // Optional: Function to call when Cancel is clicked
}

/**
 * A custom hook for creating confirmation modals.
 * @returns A function that can be called to show a confirmation modal.
 */
export const useConfirmModal = () => {
	/**
	 * Function to show the modal.
	 * @param {ConfirmModalOptions} options - The options for configuring the modal.
	 */
	const confirmModal = ({
		title,
		content,
		okText,
		cancelText,
		onOk,
		onCancel,
	}: ConfirmModalOptions) => {
		Modal.confirm({
			title,
			content,
			okText,
			onOk,
			onCancel,
			cancelText,
			centered: true
		});
	};

	return confirmModal;
};
