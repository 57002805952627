import React, { useState } from 'react';
import { OpForm } from "components/customAntd/DLS/OpForm/OpForm";
import { useSelector } from "react-redux";
import { RootState } from "store/store";
import { Button, Modal, Input, Checkbox, message, notification } from 'antd';
import { hasPermission } from 'utils/utils';
import { RedFlag, RedFlagData } from 'types/redFlagTypes';
import { DeleteOutlined } from '@ant-design/icons';
import { patchRequest } from 'api/apiClient';
import { useConfirmModal } from 'utils/customHooks/useConfirmModal';

const { Group: CheckboxGroup } = Checkbox;

interface DateFormProps {
    redFlagData: RedFlagData[];
    selectedRedFlag: RedFlag | null;
}

const DataForm: React.FC<DateFormProps> = ({ redFlagData, selectedRedFlag }) => {
    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);
    const tokenScopeList = useSelector((state: RootState) => state.auth.auth.data[0]?.tokenScopeList || []);

    const [openSelectFieldsModal, setOpenSelectFieldsModal] = useState(false);
    const [openCustomFieldModal, setOpenCustomFieldModal] = useState(false);
    const [selectedFields, setSelectedFields] = useState<string[]>([]);
    const [customFieldLabel, setCustomFieldLabel] = useState('');
    const [customFields, setCustomFields] = useState<{ id: number, label: string, value: string }[]>([]);
    const [localRedFlagData, setLocalRedFlagData] = useState<RedFlagData[]>(redFlagData); // Local state for redFlagData

    const hasRedflagWrite = hasPermission(tokenScopeList, orgId, 'o', 'redflagconf:w');
    const confirmModal = useConfirmModal();

    const handleDelete = async (item: RedFlagData | { id: number; label: string; value: string }, isCustom: boolean) => {
        if (isCustom) {
            // Handle deletion for custom fields
            setCustomFields(prevFields => prevFields.filter(field => field.id !== item.id));
            notification.success({
                message: 'Success',
                description: 'Custom field successfully deleted.',
                placement: 'bottomRight',
            });
        } else {
            // Handle deletion for database fields
            try {
                await patchRequest(`/orgs/${orgId}/redflag/${selectedRedFlag?.id}/redFlagData/${item.id}`, {
                    // name: item.data,
                    // value: item.value,
                    status: 0,
                });
                notification.success({
                    message: 'Success',
                    description: 'Field successfully deleted.',
                    placement: 'bottomRight',
                });

                setLocalRedFlagData(prevData => prevData.filter(data => data.id !== item.id));
            } catch (error) {
                console.error("Failed to delete field:", error);
                notification.error({
                    message: 'Error',
                    description: 'Failed to delete field. Please try again.',
                    placement: 'bottomRight',
                });
            }
        }
    };

    const handleDeleteConfirmation = (item: RedFlagData | { id: number; label: string; value: string }, isCustom: boolean) => {
        confirmModal({
            title: 'Are you sure you want to delete this field?',
            content: 'This action cannot be undone.',
            okText: 'Yes',
            cancelText: 'No',
            onOk: () => handleDelete(item, isCustom),
        });
    };

    const handleSelectFieldsModalOk = () => {
        const nonCustomOptions = selectedFields.filter(option => option !== 'custom');

        if (nonCustomOptions.length > 0) {
            const newFields = nonCustomOptions.map(option => ({
                id: Date.now() + Math.random(),
                label: option,
                value: ""
            }));

            setCustomFields(prevFields => [...prevFields, ...newFields]);
        }

        if (selectedFields.includes('custom')) {
            setOpenCustomFieldModal(true);
        }

        setSelectedFields([]);
        setOpenSelectFieldsModal(false);
    };

    const handleCustomModalOk = () => {
        if (!customFieldLabel.trim()) {
            message.warning("Custom field name cannot be empty.");
            return;
        }

        setCustomFields(prevFields => [
            ...prevFields,
            { id: Date.now() + Math.random(), label: customFieldLabel, value: "" }
        ]);

        setCustomFieldLabel('');
        setOpenCustomFieldModal(false);
    };

    const availableOptions = [
        'Address',
        'City',
        'State',
        'Alias',
        'Phone Number',
        'ID Type',
        'ID Number'
    ];

    return (
        <>
            {localRedFlagData.map((item) => (
                <div
                    key={`${item.data}_${item.id}`}
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        width: '100%',
                    }}
                >
                    <div style={{ flex: 1 }}>
                        <OpForm.Input
                            style={{ width: '100%' }}
                            label={item.data}
                            name={`${item.data}_${item.id}`}
                        />
                    </div>
                    {hasRedflagWrite && (
                        <Button
                            type="text"
                            danger
                            icon={<DeleteOutlined />}
                            style={{
                                marginLeft: '8px',
                            }}
                            onClick={() => handleDeleteConfirmation(item, false)}
                        />
                    )}
                </div>
            ))}
            {customFields.map((field) => (
                <div
                    key={`${field.label}_${field.id}`}
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        width: '100%',
                    }}
                >
                    <div style={{ flex: 1 }}>
                        <OpForm.Input
                            style={{ width: '100%' }}
                            label={field.label}
                            name={`${field.label}_${field.id}`}
                        />
                    </div>
                    <Button
                        type="text"
                        danger
                        icon={<DeleteOutlined />}
                        style={{
                            marginLeft: '8px',
                        }}
                        onClick={() => handleDeleteConfirmation(field, true)} // Custom field
                    />
                </div>
            ))}
            {hasRedflagWrite && (
                <Button type="dashed" onClick={() => {
                    setOpenSelectFieldsModal(true);
                }}>
                    Add Custom Field
                </Button>
            )}

            <Modal title="Select Fields" open={openSelectFieldsModal} onOk={handleSelectFieldsModalOk} onCancel={() => {
                setSelectedFields([]);
                setOpenSelectFieldsModal(false);
            }} width={300} centered>
                <CheckboxGroup
                    onChange={(checkedValues: any) => {
                        setSelectedFields(checkedValues);
                    }}
                    value={selectedFields}
                    style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}
                >
                    {availableOptions.map(option => (
                        <Checkbox key={option} value={option} style={{ marginBottom: '8px' }}>
                            {option}
                        </Checkbox>
                    ))}
                    <Checkbox value="custom" style={{ marginBottom: '8px' }}>Custom</Checkbox>
                </CheckboxGroup>
            </Modal>

            <Modal title="Enter Custom Field Name" open={openCustomFieldModal} onOk={handleCustomModalOk} onCancel={() => {
                setCustomFieldLabel('');
                setOpenCustomFieldModal(false);
            }} centered>
                <Input
                    placeholder="Enter custom field name"
                    value={customFieldLabel}
                    onChange={(e) => setCustomFieldLabel(e.target.value)}
                />
            </Modal>
        </>
    );
};

export default DataForm;
