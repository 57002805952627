import React, { useState, useEffect, useCallback } from 'react';
import { Spin, notification } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store/store';
import { IOnSubmitArgs, OpForm } from 'components/customAntd/DLS/OpForm/OpForm';
import { OpPage } from 'components/customAntd/OpPage/OpPage';
import { OpTabs } from 'components/customAntd/DLS/OpTabs/OpTabs';
import { getRequest, patchRequest } from 'api/apiClient';
import { hasPermission, transformNullToEmptyString } from 'utils/utils';
import OptionsForm from './tabs/OptionsForm';
import FieldsForm from './tabs/FieldsForm';
import CustomizationForm from './tabs/CustomizationForm';
import { InvitationDocument } from 'types/visitorInvitationTypes';
import { INVITATIONS_TOOLTIP } from 'constants/tooltip';
import { INVITATIONS_SAVE_ERROR, INVITATIONS_UPDATE_SUCCESS, NOTIFICATION_ERROR, NOTIFICATION_SUCCESS } from 'constants/messages';
import { CustomFormButtons } from './CustomFormButtons';
import { fetchInvitationConfig, updateInvitationConfig } from 'store/slices/visitorInvitationSlice';

const Invitations: React.FC = () => {
    const dispatch: AppDispatch = useDispatch();
    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);
    const tokenScopeList = useSelector((state: RootState) => state.auth.auth.data[0]?.tokenScopeList || []);
    const { invitationConfig } = useSelector((state: RootState) => state.visitorInvitation);
    const subscriptions = useSelector((state: RootState) => state.subscriptions.subscriptions);

    const hasInvitationRead = hasPermission(tokenScopeList, orgId, 'o', 'invitation:r');
    const hasInvitationWrite = hasPermission(tokenScopeList, orgId, 'o', 'invitation:w');

    const [activeKey, setActiveKey] = useState<string>('options');
    const [invitationDocuments, setInvitationDocuments] = useState<InvitationDocument[]>([]);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const [isPreRegistrationAllowed, setIsPreRegistrationAllowed] = useState<number>(0);
    const [isCustomMessageOn, setIsCustomMessageOn] = useState<number>(0);

    const [form] = OpForm.useForm();

    const [savedValues, setSavedValues] = useState<any>(null);

    useEffect(() => {
        // if (invitationDocuments.length > 0 && invitationConfig) {
        const initialValues = {
            invitationDocuments: invitationDocuments
                .filter(document => document.selected)
                .map(document => document.id),
            ...invitationConfig,
            allowPreRegistration:
                subscriptions?.data[0]?.package?.code === "package-essential"
                    ? 0
                    : invitationConfig?.allowPreRegistration,
        };
        setSavedValues(initialValues); // Update savedValues once data is ready
        form.setFieldsValue(initialValues); // Optionally set initial values for the form
        // }
    }, [invitationConfig, invitationDocuments, subscriptions, form]);

    useEffect(() => {
        setActiveKey('options');
    }, []);

    // Fetch invitation config and documents
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                await dispatch(fetchInvitationConfig({ orgId }));
                const invitationDocuments = await getRequest(`/orgs/${orgId}/visitorInvitationDocument`);
                setInvitationDocuments(invitationDocuments.data);

                setIsCustomMessageOn(invitationConfig?.customMessage!);
                setIsPreRegistrationAllowed(invitationConfig?.allowPreRegistration!);
            } catch (error) {
                console.log("Failed to fetch data.");
            } finally {
                setLoading(false);
            }
        };
        fetchData();
        // eslint-disable-next-line 
    }, [orgId, dispatch]);

    const tabItems = (invitationConfig && invitationDocuments) ? [
        {
            key: 'options',
            label: 'Options',
            children: <OptionsForm form={form} invitationDocuments={invitationDocuments}
                isPreRegistrationAllowed={isPreRegistrationAllowed} setIsPreRegistrationAllowed={setIsPreRegistrationAllowed} />,
        },
        {
            key: 'fields',
            label: 'Fields',
            children: <FieldsForm form={form} invitationConfig={invitationConfig} />,
        },
        {
            key: 'customization',
            label: 'Customization',
            children: <CustomizationForm form={form} isCustomMessageOn={isCustomMessageOn} setIsCustomMessageOn={setIsCustomMessageOn} />,
        },
    ] : [];

    const handleSubmit = useCallback(async ({ touchedValues }: IOnSubmitArgs) => {
        setIsSubmitting(true);
        try {
            if (touchedValues && Object.keys(touchedValues).length > 0) {
                const transformedValues = transformNullToEmptyString(touchedValues);

                // Extract `invitationDocuments` from transformedValues
                const { invitationDocuments, ...remainingValues } = transformedValues;

                // Dispatch without invitationDocuments
                await dispatch(updateInvitationConfig({ orgId, config: remainingValues }));

                // Handle invitationDocuments separately
                if (invitationDocuments) {
                    if (invitationDocuments.length !== 0) {
                        await patchRequest(`/orgs/${orgId}/visitorInvitationDocument`, { id: invitationDocuments });
                    } else {
                        await patchRequest(`/orgs/${orgId}/visitorInvitationDocument`, { id: [-1] });
                    }
                }

                // Update savedValues after a successful save
                setSavedValues({ ...savedValues, ...transformedValues });

                // Fetch the latest invitationDocuments to ensure updated data
                const updatedDocuments = await getRequest(`/orgs/${orgId}/visitorInvitationDocument`);
                setInvitationDocuments(updatedDocuments.data);

                notification.success({
                    message: NOTIFICATION_SUCCESS,
                    description: INVITATIONS_UPDATE_SUCCESS,
                    placement: 'bottomRight',
                });
            }
        } catch (error) {
            notification.error({
                message: NOTIFICATION_ERROR,
                description: INVITATIONS_SAVE_ERROR,
                placement: 'bottomRight',
            });
            console.error("Form submission failed:", error);
        } finally {
            setIsSubmitting(false);
        }
    }, [orgId, savedValues, dispatch]);

    // Custom Reset handler
    const handleReset = () => {
        if (savedValues && form.isFieldsTouched()) {
            form.setFieldsValue(savedValues);
            setIsPreRegistrationAllowed(Number(savedValues.allowPreRegistration!));
            setIsCustomMessageOn(savedValues.customMessage!);
        }
    };

    return (
        <OpPage title="Invitations" tooltip={INVITATIONS_TOOLTIP} subtitle="">
            <OpForm
                form={form}
                onSubmit={handleSubmit}
                hasError={false}
                isReadOnly={!hasInvitationWrite && hasInvitationRead}
                defaultButtons={false}
            >
                {(loading || isSubmitting) ? (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                        <Spin indicator={<LoadingOutlined spin />} />
                    </div>
                ) : (
                    <>
                        <OpTabs activeKey={activeKey} onChange={setActiveKey} items={tabItems} />
                        <CustomFormButtons
                            form={form}
                            isFormLoading={loading}
                            isSubmitButtonLoading={isSubmitting}
                            submitButtonLabel="Save"
                            onResetClick={handleReset}
                        />
                    </>
                )}
            </OpForm>
        </OpPage>
    );
}

export default Invitations;