import { OpCol } from "components/customAntd/DLS/OpCol/OpCol";
import { OpForm } from "components/customAntd/DLS/OpForm/OpForm";
import { OpRow } from "components/customAntd/DLS/OpRow/OpRow";

const ReaderForm: React.FC = () => {
    return (
        <>
            <OpRow gutter={16}>
                <OpCol span={12}>
                    <OpForm.Input
                        label="Acu ID"
                        name="acuId"
                        disabled
                    />
                </OpCol>
                <OpCol span={12}>
                    <OpForm.Input
                        label="Name"
                        name="name"
                        disabled
                    />
                </OpCol>
            </OpRow>
            <OpForm.Input
                label="Description"
                name="description"
                disabled
            />
            <OpForm.Input
                label="Status"
                name="status"
                disabled
            />
        </>
    );
};

export default ReaderForm;
