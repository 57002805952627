import React, { useEffect, useState } from 'react';
import { OpPage } from 'components/customAntd/OpPage/OpPage';
import VisitorSearchTable from './VisitorSearchTable';
import VisitorsDrawer from '../visitors/drawer/VisitorsDrawer';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store/store';
import { fetchVisits, setVisitsEndDate, setSelectedVisit, setVisitsStartDate } from 'store/slices/visitsSlice';

const VisitorSearch: React.FC = () => {
    const dispatch: AppDispatch = useDispatch();
    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);

    const [isVisitorsDrawerOpen, setIsVisitorsDrawerOpen] = useState<boolean>(false);
    const [isProfileDrawerOpen, setIsProfileDrawerOpen] = useState(false);

    const fetchVisitsData = async () => {
        await dispatch(setVisitsStartDate(undefined));
        await dispatch(setVisitsEndDate(undefined));
        await dispatch(fetchVisits({ orgId }));
    };

    useEffect(() => {
        fetchVisitsData();
        // eslint-disable-next-line
    }, [orgId]);

    const handleDrawerClose = () => {
        setIsVisitorsDrawerOpen(false);
        dispatch(setSelectedVisit(null));
    };

    return (
        <OpPage title="Visitor Search" tooltip="" subtitle="">
            <VisitorSearchTable setDrawerVisible={setIsVisitorsDrawerOpen} />
            {(isVisitorsDrawerOpen) && (
                <VisitorsDrawer
                    open={isVisitorsDrawerOpen}
                    isProfileDrawerOpen={isProfileDrawerOpen}
                    onClose={handleDrawerClose}
                    setIsProfileDrawerOpen={setIsProfileDrawerOpen}
                />
            )}
        </OpPage>
    );
}

export default VisitorSearch;
