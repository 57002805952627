import React, { useCallback, useEffect, useState } from 'react';
import { List, notification } from 'antd';
import { RootState } from 'store/store';
import { useSelector } from 'react-redux';
import { deleteRequest, getRequest } from 'api/apiClient';
import { OpTabs } from 'components/customAntd/DLS/OpTabs/OpTabs';
import { OpButton } from 'components/customAntd/DLS/OpButton/OpButton';
import { NOTIFICATION_ERROR } from 'constants/messages';
import { useConfirmModal } from 'utils/customHooks/useConfirmModal';
import Loader from 'components/customAntd/Loader';

interface IntegrationConfigureProps {
    setIsCheckrConfigureOpen: (open: boolean) => void;
    setIsCheckrInstalled: (open: boolean) => void;
}

const CheckrConfigure: React.FC<IntegrationConfigureProps> = ({ setIsCheckrConfigureOpen, setIsCheckrInstalled }) => {
    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);
    const [activeKey, setActiveKey] = useState<string>('connection');
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [clientId, setClientId] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);

    const confirmModal = useConfirmModal();

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const checkrData = await getRequest(`/orgs/${orgId}/integrationCheckrTrust`);
                setClientId(checkrData.data[0].clientId);
            } catch (error) {
                console.log("Failed to fetch data.");
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, [orgId]);

    const handleUninstall = useCallback(async () => {
        confirmModal({
            title: ('Uninstall Integration'),
            content: ('Uninstall and disconnect current integration?'),
            okText: ('Yes'),
            cancelText: ('No'),
            onOk: async () => {
                setIsSubmitting(true);
                try {
                    await deleteRequest(`/orgs/${orgId}/integrationCheckrTrust`);
                    notification.success({
                        message: 'Success',
                        description: 'Checkr Trust uninstalled successfully.',
                        placement: 'bottomRight',
                    });
                    setIsCheckrConfigureOpen(false); // Go back to the main menu
                    setIsCheckrInstalled(false);
                } catch (error) {
                    notification.error({
                        message: NOTIFICATION_ERROR,
                        description: 'An error occurred during the submission.',
                        placement: 'bottomRight',
                    });
                    console.error("Form submission failed:", error);
                } finally {
                    setIsSubmitting(false);
                }
            },
        });
    }, [confirmModal, orgId, setIsCheckrConfigureOpen, setIsCheckrInstalled]);

    const tabItems = [
        {
            key: 'connection',
            label: 'Connection',
            children: (
                <>
                    <List
                        header={<span>AUTHENTICATION</span>}
                        dataSource={[
                            { label: 'Connected', name: 'connected', clientId: clientId },
                        ]}
                        bordered
                        renderItem={item => (
                            <List.Item style={{ width: '100%' }}>
                                <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                    <span style={{ fontWeight: 'bold' }}>{item.label}:</span>
                                    <span style={{ marginLeft: '8px' }}>{item.clientId}</span>
                                </div>
                            </List.Item>
                        )}
                    />
                    <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '16px' }}>
                        <OpButton
                            type="primary"
                            danger
                            onClick={handleUninstall}
                            style={{
                                width: '120px',
                                padding: '4px 0',
                            }}
                        >
                            Uninstall
                        </OpButton>
                    </div>
                </>

            )
        }
    ];

    return (
        <>
            {(loading || isSubmitting) ? (
                <Loader />
            ) : (
                <>
                    <OpTabs activeKey={activeKey} onChange={setActiveKey} items={tabItems} />
                </>

            )}
        </>
    );
};

export default CheckrConfigure;