import React, { useEffect, useState } from 'react';
import { Modal } from 'antd';
import { OpButton } from 'components/customAntd/DLS/OpButton/OpButton';
import { OpSpace } from 'components/customAntd/DLS/OpSpace/OpSpace';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store/store';
import { VisitorWorkflowConfig } from 'types/visitorWorkflowTypes';
import { getRequest } from 'api/apiClient';
import Loader from 'components/customAntd/Loader';

interface SignInMethodModalProps {
    open: boolean;
    onClose: () => void;
    openSignInModal: () => void;
    openScanIDModal: () => void;
    openScanQRCodeModal: () => void;
    locationIdRef: number;
}

const SignInMethodModal: React.FC<SignInMethodModalProps> = ({ open, onClose, openSignInModal, openScanIDModal, openScanQRCodeModal, locationIdRef }) => {
    const dispatch: AppDispatch = useDispatch();
    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);

    const [loading, setLoading] = useState<boolean>(false);

    const { invitationConfig } = useSelector((state: RootState) => state.visitorInvitation);
    const [signInWorkflowConfig, setSignInWorkflowConfig] = useState<VisitorWorkflowConfig>();

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const locationWorkflowResponse = await getRequest(`/orgs/${orgId}/sites/${locationIdRef}/workflows`);
                const signInWorkflowConfigResponse = await getRequest(`/orgs/${orgId}/visitorWorkflowConfig/${locationWorkflowResponse.data[0].SignInWorkflowId}`);
                setSignInWorkflowConfig(signInWorkflowConfigResponse.data[0]);
            } catch (error) {
                console.log("Failed to fetch data.");
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, [dispatch, orgId, locationIdRef]);

    return (
        <Modal
            title="Choose a Method to Sign In"
            open={open}
            onOk={onClose}
            onCancel={onClose}
            centered
            footer={null}
        >
            <OpSpace direction="vertical" size="large" style={{ display: 'flex', paddingTop: '16px' }}>
                {loading ? (<Loader />) : (
                    <>
                        <OpButton
                            type="default"
                            style={{ width: '100%', backgroundColor: 'rgba(19,151,213,0.1)' }}
                            onClick={() => {
                                openSignInModal();
                                onClose();
                            }}
                        >
                            SELECT OR ENTER VISITOR
                        </OpButton>
                        {((signInWorkflowConfig?.scan === 1) && (signInWorkflowConfig?.scanScanner === 1)) && (
                            <OpButton
                                type="default"
                                style={{ width: '100%', backgroundColor: 'rgba(19,151,213,0.1)' }}
                                onClick={() => {
                                    openScanIDModal();
                                    onClose();
                                }}
                            >
                                SCAN ID
                            </OpButton>
                        )}
                        {(invitationConfig?.allowPreRegistration === 1) && (
                            <OpButton
                                type="default"
                                style={{ width: '100%', backgroundColor: 'rgba(19,151,213,0.1)' }}
                                onClick={() => {
                                    openScanQRCodeModal();
                                    onClose();
                                }}
                            >
                                SCAN QR CODE
                            </OpButton>
                        )}
                    </>
                )}
            </OpSpace>
        </Modal>
    );
};

export default SignInMethodModal;
