import { notification } from 'antd';
import { postRequest } from 'api/apiClient';
import { OpButton } from 'components/customAntd/DLS/OpButton/OpButton';
import { IOnSubmitArgs, OpForm } from 'components/customAntd/DLS/OpForm/OpForm';
import { OpSection } from 'components/customAntd/DLS/OpSection/OpSection';
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { useConfirmModal } from 'utils/customHooks/useConfirmModal';

interface CheckrInstallProps {
    setIsCheckrInstallOpen: (open: boolean) => void;
    setIsCheckrInstalled: (open: boolean) => void;
}

const CheckrInstall: React.FC<CheckrInstallProps> = ({ setIsCheckrInstallOpen, setIsCheckrInstalled }) => {
    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);
    const [form] = OpForm.useForm();
    const confirmModal = useConfirmModal();

    const handleSubmit = (async ({ values }: IOnSubmitArgs) => {
        try {
            await postRequest(`/orgs/${orgId}/integrationCheckrTrust`, values);

            notification.success({
                message: 'Success',
                description: 'Checkr Trust integration was created successfully.',
                placement: 'bottomRight',
            });
            setIsCheckrInstalled(true);
            setIsCheckrInstallOpen(false);
        } catch (error) {
            notification.error({
                message: 'Connection Failed',
                description: 'An error occurred during the submission.',
                placement: 'bottomRight'
            });
        }
    });

    const handleCancel = () => {
        confirmModal({
            title: ('Cancel Checkr Installation'),
            content: ('Are you sure you want to cancel the Checkr Trust installation?'),
            okText: ('Yes'),
            cancelText: ('No'),
            onOk: () => {
                setIsCheckrInstallOpen(false); // Go back to the main menu
            },
        });
    };
    return (
        <>
            <OpSection>
                <OpForm
                    form={form}
                    onSubmit={handleSubmit}
                    initialValues={{}}
                    defaultButtons={false}
                    hasError={false}
                >
                    <OpForm.Input
                        name="clientId"
                        label="Client ID"
                        rules={[{ required: true, message: 'Please enter your client ID.' }]}
                        placeholder="Enter your client ID"
                    />
                    <OpForm.Input
                        name="clientSecret"
                        label="Client Secret"
                        rules={[{ required: true, message: 'Please enter your client secret.' }]}
                        placeholder="Enter your client secret"
                    />
                </OpForm>
            </OpSection>
            <OpButton type="primary" htmlType="submit" onClick={form.submit}>
                Done
            </OpButton>
            <OpButton style={{ margin: '0 8px' }} onClick={handleCancel}>
                Cancel
            </OpButton>
        </>
    );
};

export default CheckrInstall;