import React from 'react';
import { OpModal } from 'components/customAntd/DLS/OpModal/OpModal';
import { List, Typography } from 'antd';
import dayjs from 'dayjs';
import { OpButton } from 'components/customAntd/DLS/OpButton/OpButton';

const { Text, Title } = Typography;

interface ExternalRedFlagDetailsModalProps {
    open: boolean;
    onClose: () => void;
    record: any;
}

const ExternalRedFlagDetailsModal: React.FC<ExternalRedFlagDetailsModalProps> = ({
    open,
    onClose,
    record
}) => {
    const formatDate = (dateStr: string) => {
        if (!dateStr) return null;
        try {
            return dateStr.length === 8
                ? dayjs(dateStr, 'YYYYMMDD').format('YYYY-MM-DD')
                : dateStr;
        } catch {
            return null;
        }
    };

    const isValidValue = (value: any) => value !== null && value !== undefined && value !== '';


    const profileData = [
        { label: 'First Name', value: record?.person?.first_name },
        { label: 'Last Name', value: record?.person?.last_name },
        { label: 'Middle Name', value: record?.person?.middle_name },
        { label: 'Gender', value: record?.person?.gender },
        { label: 'Date of Birth', value: formatDate(record?.person?.dob) },
    ].filter(item => isValidValue(item.value));

    const recordDetails = [
        { label: 'Category', value: record?.category },
        { label: 'Date', value: formatDate(record?.cases?.[0]?.arrest_date) },
        { label: 'Type', value: record?.cases?.[0]?.charges?.[0]?.type },
        { label: 'Source', value: record?.source?.name },
        { label: 'Source County', value: record?.source?.county },
        { label: 'Source State', value: record?.source?.state },
    ].filter(item => isValidValue(item.value));

    return (
        <OpModal
            title="External Red Flag Details"
            open={open}
            footer={[
                <OpButton key="close" type="primary" onClick={onClose}>
                    Close
                </OpButton>,
            ]}
            onCancel={onClose}
            width={700}
            okText="Close"
        >
            <div style={{ maxHeight: '60vh', overflowY: 'auto', overflowX: 'hidden' }}>
                {profileData.length > 0 && (
                    <>
                        <Title level={5} style={{ marginBottom: 16 }}>Personal Information</Title>
                        <List
                            bordered
                            dataSource={profileData}
                            renderItem={(item) => (
                                <List.Item>
                                    <Text strong>{item.label}:</Text> {item.value}
                                </List.Item>
                            )}
                        />
                    </>
                )}

                {recordDetails.length > 0 && (
                    <>
                        <Title level={5} style={{ margin: '24px 0 16px' }}>Record Details</Title>
                        <List
                            bordered
                            dataSource={recordDetails}
                            renderItem={(item) => (
                                <List.Item>
                                    <Text strong>{item.label}:</Text> {item.value}
                                </List.Item>
                            )}
                        />
                    </>
                )}

                {
                    record?.person?.photo_urls?.length > 0 && (
                        <>
                            <Title level={5} style={{ margin: '24px 0 16px' }}>Photo URLs</Title>
                            <List
                                bordered
                                dataSource={record?.person?.photo_urls}
                                renderItem={(url: string) => (
                                    <List.Item>
                                        <a href={url} target="_blank" rel="noopener noreferrer">{url}</a>
                                    </List.Item>
                                )}

                            />
                        </>
                    )}
            </div >
        </OpModal >
    );
};

export default ExternalRedFlagDetailsModal;