import React, { useState } from 'react';
import { Button, Typography, notification } from 'antd';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import SignUpForm from './SignUpForm';
import './SignUp.scss';

const apiUrl = process.env.REACT_APP_BACKEND_URL;

const SignUp: React.FC = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [signedUp, setSignedUp] = useState(false);

    const handleLearnMoreClick = () => {
        window.open("https://invisit.us", "_blank");
    };

    const onFinish = async (values: { firstname: string, lastname: string, email: string, name: string }) => {
        setLoading(true);
        try {
            await axios.post(`${apiUrl}/orgs`, {
                name: values.name,
                email: values.email,
                firstname: values.firstname,
                lastname: values.lastname,
                timezone: Intl.DateTimeFormat().resolvedOptions().timeZone, // get timezone from broswer
            });

            await axios.post(`${apiUrl}/auth/resetPassword`, {
                email: values.email,
            });

            setSignedUp(true);
        } catch (error) {
            notification.error({
                message: 'Error',
                description: 'There was an error processing your sign-up. Please try again later.',
                placement: 'bottomRight',
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="auth-wrapper">
            <div className="auth-wrapper__left">
                <div className="auth-wrapper__left-content">
                    <div className="auth-wrapper__left-content__text">
                        <div className="auth-wrapper__left-content__headline">
                        Easily manage and monitor your visitors with InVisit
                        </div>
                        <div className="auth-wrapper__left-content__body">
                        Track visitor activity in real-time anywhere, giving you accurate insights on who is on-site and ensuring you’re always informed on the flow of your visitors.
                        </div>
                        <Button
                            onClick={handleLearnMoreClick}
                            type="primary"
                            className="button"
                            style={{ width: "110px" }}
                        >
                            Learn more
                        </Button>
                    </div>
                </div>
            </div>
            <div className="auth-wrapper__right">
                <div className="auth-wrapper__right-content">
                    <div className="auth-wrapper__right-logo">
                        <img src="/images/invisit_full.png" alt="Logo" />
                    </div>
                    <div className="signup">
                        {signedUp ? (
                            <div style={{ textAlign: 'center' }}>
                                <Typography.Title level={2}>Thank you for signing up!</Typography.Title>
                                <div>
                                    <Typography.Text>
                                        <p>An email has been sent to you.</p><p>Please follow the directions in your email to set your password and access your InVisit account.</p>
                                    </Typography.Text>
                                </div>
                                <div style={{ textAlign: 'center', marginTop: '50px' }}>
                                    <Button
                                        onClick={() => navigate('/login')}
                                        type="link"
                                        className="button"
                                        style={{ fontWeight: 'bold' }}
                                    >
                                        Return to Login
                                    </Button>
                                </div>
                            </div>
                        ) : (
                            <SignUpForm onFinish={onFinish} loading={loading} />
                        )}
                    </div>
                    {!signedUp && (
                        <div style={{ textAlign: 'center', marginTop: '50px' }}>
                            <Button
                                onClick={() => navigate('/login')}
                                type="link"
                                className="button"
                                style={{ fontWeight: 'bold' }}
                            >
                                Return to Login
                            </Button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default SignUp;
