import React, { useState, useCallback, useRef, useEffect } from 'react';
import { Modal, Spin, Alert, notification } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useEvent } from 'react-use';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store/store';
import { setSelectedVisit } from 'store/slices/visitsSlice';
import { VisitorSearch } from 'types/visitorSearchTypes';
import { getRequest, postRequest } from 'api/apiClient';
import axios from 'axios';
import dayjs from 'dayjs';

interface ScanQrCodeProps {
    open: boolean;
    visitorsToday: VisitorSearch[];
    onClose: () => void;
    openVisitorsDrawer: () => void;
}

const apiUrl = process.env.REACT_APP_BACKEND_URL;

const ScanQRCodeModal: React.FC<ScanQrCodeProps> = ({
    open,
    visitorsToday,
    onClose,
    openVisitorsDrawer,
}) => {
    const dispatch: AppDispatch = useDispatch();
    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);
    const scannedDataRef = useRef('');
    const [scanningInProgress, setScanningInProgress] = useState(false);
    const [isKeyPressed, setIsKeyPressed] = useState(false);

    const timerRef = useRef<NodeJS.Timeout | null>(null); // Timer to detect the end of scanning

    useEvent('keydown', scanningInProgress ? (event: Event) => handleKeyDown(event as KeyboardEvent) : null, document);

    useEffect(() => {
        if (open) {
            setScanningInProgress(true);
        } else {
            setScanningInProgress(false);
            setIsKeyPressed(false);
            scannedDataRef.current = '';
            if (timerRef.current) {
                clearTimeout(timerRef.current);
                timerRef.current = null;
            }
        }
    }, [open]);

    const handleScannedData = useCallback(async (data: string) => {
        console.log('Scanned Data:', data);

        let tokenToUse = data;
        let qrCodeId = null;

        if (data.startsWith('S-')) {
            try {
                const response = await getRequest(`/orgs/${orgId}/qrcode?code=${data}`);
                if (response?.data?.length > 0 && response.data[0].visitorToken) {
                    const qrData = response.data[0];

                    if (qrData.expiresUtc) {
                        const expirationTime = dayjs(qrData.expiresUtc).utc(true).local();
                        console.log('expirationTime', expirationTime);
                        if (expirationTime.isBefore(dayjs())) {
                            notification.error({
                                message: 'QR Code Expired',
                                description: 'This QR code has expired.',
                                placement: 'bottomRight',
                            });
                            return;
                        }
                    }

                    tokenToUse = qrData.visitorToken;
                    qrCodeId = qrData.id;
                } else {
                    notification.error({
                        message: 'Invalid QR Code',
                        description: 'Could not retrieve the visitor token. Please try again.',
                        placement: 'bottomRight',
                    });
                    return;
                }
            } catch (error) {
                notification.error({
                    message: 'Invalid QR Code',
                    description: 'Could not retrieve the visitor token. Please try again.',
                    placement: 'bottomRight',
                });
                console.error('Request failed:', error);
                return;
            }
        }

        try {
            const response = await fetch(`${apiUrl}/orgs/${orgId}/search/registration?token=${tokenToUse}`, {
                method: 'GET',
                headers: {
                    Authorization: `${localStorage.getItem('authToken')}`,
                    Accept: 'application/json',
                },
            });

            if (response.ok) {
                const registrationTokenResponse = await axios.post(`${apiUrl}/auth/registrationTokens/${tokenToUse}/validate`);
                const selectedVisitor = visitorsToday.find(v =>
                    Number(v.id) === Number(registrationTokenResponse.data.data.data.visitorId)
                );
                if (selectedVisitor === undefined) {
                    notification.error({
                        message: 'Invalid QR Code',
                        description: 'The QR code you are trying to use is invalid or has expired.',
                        placement: 'bottomRight',
                    });
                } else {
                    const selectedVisit = await getRequest(`/orgs/${orgId}/visitor/${selectedVisitor.visitId}`);
                    dispatch(setSelectedVisit(selectedVisit.data[0]));
                    openVisitorsDrawer();

                    try {
                        await postRequest(`/orgs/${orgId}/visitor/${selectedVisitor.visitId}/qrScanLog`, {
                            visitorId: selectedVisitor.id,
                            source: "Web Control Center",
                            qrCodeId: qrCodeId,
                            qrValue: data,
                        });
                    } catch (error) {
                        notification.error({
                            message: 'QR Code Scan Log Failed',
                            description: 'Failed to log the QR scan.',
                            placement: 'bottomRight',
                        });
                        console.error('QR scan log request failed:', error);
                    }

                }
            } else {
                notification.error({
                    message: 'Invalid QR Code',
                    description: 'Could not scan the QR code. Please try again.',
                    placement: 'bottomRight',
                });
            }
        } catch (error) {
            notification.error({
                message: 'Invalid QR Code',
                description: 'Could not scan the QR code. Please try again.',
                placement: 'bottomRight',
            });
            console.error('Request failed:', error);
        }
    }, [dispatch, openVisitorsDrawer, orgId, visitorsToday]);


    const handleKeyDown = useCallback((event: KeyboardEvent) => {
        setIsKeyPressed(true);

        if (event.key.length === 1) {
            scannedDataRef.current += event.key;
        }

        if (timerRef.current) {
            clearTimeout(timerRef.current);
        }

        // console.log("scannedDataRef", scannedDataRef);
        timerRef.current = setTimeout(() => {
            setScanningInProgress(false);
            setIsKeyPressed(false);
            handleScannedData(scannedDataRef.current);
            scannedDataRef.current = '';
            onClose();
        }, 1000);
    }, [onClose, handleScannedData]);

    return (
        <Modal
            title="Scan QR Code to Sign In Visitor"
            open={open}
            onOk={onClose}
            onCancel={() => {
                scannedDataRef.current = '';
                setScanningInProgress(false);
                setIsKeyPressed(false);
                if (timerRef.current) {
                    clearTimeout(timerRef.current);
                    timerRef.current = null;
                }
                onClose();
            }}
            okText="Confirm"
            cancelText="Cancel"
            centered
            style={{ textAlign: 'center' }}
            footer={null}
        >
            {scanningInProgress ? <Spin indicator={<LoadingOutlined spin />} size="default" style={{ padding: '20px' }} /> : undefined}
            {isKeyPressed ? <Alert message="Processing..." type="success" /> : <Alert message="Waiting to Scan QR Code" type="info" />}
        </Modal>
    );
};

export default ScanQRCodeModal;
