import { OpCol } from "components/customAntd/DLS/OpCol/OpCol";
import { IOnSubmitArgs, OpForm } from "components/customAntd/DLS/OpForm/OpForm";
import { OpRow } from "components/customAntd/DLS/OpRow/OpRow";
import { DATE_TIME_AM_PM_FORMAT } from "constants/dates";
import dayjs from 'dayjs';
import { formatFullName } from "utils/utils";
import { getStatusColor, getStatusNameById } from "utils/visitorsHelper";
import { OpCard } from "components/customAntd/DLS/OpCard/OpCard";
import { useState } from "react";
import { InfoCircleOutlined, UserOutlined } from "@ant-design/icons";
import { OpModal } from "components/customAntd/DLS/OpModal/OpModal";
import { OpButton } from "components/customAntd/DLS/OpButton/OpButton";
import { useSelector } from "react-redux";
import { RootState } from "store/store";
import STATUS from "constants/status";
import { useConfirmModal } from "utils/customHooks/useConfirmModal";
import { postRequest } from "api/apiClient";
import { List, notification } from "antd";
import { DenyDetails } from "types/denyTypes";
import { Kiosk } from "types/kioskTypes";
import { OpDivider } from "components/customAntd/DLS/OpDivider/OpDivider";

interface ProfileTabProps {
    visitorPhotoUrl: string | null;
    denyDetails?: DenyDetails;
    connectedKiosks: Kiosk[];
}

const ProfileTab: React.FC<ProfileTabProps> = ({ visitorPhotoUrl, denyDetails, connectedKiosks }) => {
    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);
    const { selectedVisit, selectedVisitor } = useSelector((state: RootState) => state.visits);
    const { invitationConfig } = useSelector((state: RootState) => state.visitorInvitation);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [printBadgeForm] = OpForm.useForm();

    const [isVisitorPhotoModalOpen, setIsVisitorPhotoModalVisible] = useState(false);
    const [badgePrintModalOpen, setBadgePrintModalOpen] = useState(false);
    const [isDeniedEntryModalOpen, setIsDeniedEntryModalOpen] = useState(false);

    const confirmModal = useConfirmModal();

    const initialValues = {
        fullName: formatFullName(selectedVisitor?.firstName!, selectedVisitor?.middleName!, selectedVisitor?.lastName!),
        status: getStatusNameById(selectedVisitor?.status!, selectedVisit?.scheduleStart!),
        email: selectedVisitor?.email,
        mobilePhone: selectedVisitor?.mobilePhone,
        signIn: selectedVisitor?.signIn ? dayjs(selectedVisitor.signIn).format(DATE_TIME_AM_PM_FORMAT) : undefined,
        signOut: selectedVisitor?.signOut ? dayjs(selectedVisitor.signOut).format(DATE_TIME_AM_PM_FORMAT) : undefined,
    };

    const resendInvitation = () => {
        confirmModal({
            title: ('Resend Invitation'),
            content: ('Resend this invitation?'),
            okText: ('Yes'),
            cancelText: ('No'),
            onOk: () => {
                try {
                    postRequest(`/orgs/${orgId}/visitor/${selectedVisit?.id}/invitationEmail`, {
                        visitorId: selectedVisitor?.id
                    });
                    notification.success({
                        message: 'Success',
                        description: 'The invitation link has been successfully sent to the visitor’s email.',
                        placement: 'bottomRight',
                    });
                } catch {
                    notification.error({
                        message: 'Error',
                        description: 'An error occurred while sending the invitation. Please try again.',
                        placement: 'bottomRight',
                    });
                }
            },
        });
    };

    const handleStatusClick = () => {
        if (selectedVisitor?.status === STATUS.DENIED_ENTRY.id) {
            setIsDeniedEntryModalOpen(true);
        }
    };

    const handlePrintBadge = async ({ values }: IOnSubmitArgs<any>) => {
        const selectedKiosk = values.kioskSelect;

        if (!selectedKiosk) {
            notification.error({
                message: 'Error',
                description: 'Please select a kiosk to print the badge.',
                placement: 'bottomRight',
            });
            return;
        }

        setIsSubmitting(true); // Disable button to prevent multiple clicks

        try {
            await postRequest(
                `/orgs/${orgId}/visitor/${selectedVisit?.id}/visitors/${selectedVisitor?.id}/printBadge`,
                { deviceId: selectedKiosk }
            );

            notification.success({
                message: 'Success',
                description: 'Badge print started.',
                placement: 'bottomRight',
            });

            setBadgePrintModalOpen(false);
        } catch (error) {
            console.error("Failed to print badge:", error);
            notification.error({
                message: 'Error',
                description: 'Failed to start badge printing. Please try again.',
                placement: 'bottomRight',
            });
        } finally {
            setIsSubmitting(false); // Re-enable the button
        }
    };

    return (
        <>
            <OpForm
                onSubmit={() => console.log()}
                hasError={false}
                defaultButtons={false}
                initialValues={initialValues}
                isReadOnly={true}
            >
                <OpRow gutter={24}>
                    <OpCol span={12}>
                        <OpForm.Input readOnly label="Full Name" name="fullName" />
                        <OpRow gutter={16}>
                            <OpCol span={selectedVisitor?.status === STATUS.DENIED_ENTRY.id ? 21 : 24}>
                                <OpForm.Input
                                    readOnly
                                    label="Status"
                                    name="status"
                                    style={{
                                        backgroundColor: getStatusColor(getStatusNameById(selectedVisitor?.status!, selectedVisit?.scheduleStart!)),
                                        color: 'white',
                                        WebkitTextFillColor: 'white',
                                        opacity: 1,
                                    }}
                                />
                            </OpCol>
                            {selectedVisitor?.status === STATUS.DENIED_ENTRY.id && (
                                <OpCol span={3}>
                                    <OpButton
                                        style={{ width: "30px", marginTop: '30px' }}
                                        icon={<InfoCircleOutlined />}
                                        onClick={handleStatusClick}
                                    />
                                </OpCol>
                            )}
                        </OpRow>

                        <OpForm.Input readOnly label="Mobile Phone" name="mobilePhone" />
                    </OpCol>
                    <OpCol span={12}>
                        <OpCard
                            hoverable={!!visitorPhotoUrl}
                            style={{
                                width: '100%',
                                height: '280px',
                                borderRadius: '30px',
                                overflow: 'hidden',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                            onClick={() => {
                                if (visitorPhotoUrl) {
                                    setIsVisitorPhotoModalVisible(true);
                                }
                            }}
                        >
                            {visitorPhotoUrl ? (
                                <img src={visitorPhotoUrl}
                                    alt='Visitor'
                                    style={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        width: '100%',
                                        height: '100%',
                                        objectFit: 'cover',
                                        borderRadius: '30px',
                                    }}
                                    onError={(e) => {
                                    }}
                                />
                            ) : (
                                <UserOutlined style={{ fontSize: '64px', color: '#ccc' }} />
                            )}
                        </OpCard>
                    </OpCol>
                </OpRow>
                <OpRow gutter={16}>
                    <OpCol span={18}>
                        <OpForm.Input readOnly label="Email" name="email" />
                    </OpCol>
                    <OpCol span={6}>
                        <OpButton
                            style={{
                                width: "100%", marginTop: '30px', fontSize: 'clamp(12px, 2vw, 14px)'
                            }}
                            onClick={resendInvitation}
                            disabled={
                                (selectedVisitor?.status !== STATUS.PENDING.id) ||
                                    (!initialValues.email) ||
                                    (!invitationConfig?.sendEmail) ||
                                    (selectedVisit?.scheduleStart && selectedVisit?.scheduleEnd &&
                                        (!dayjs().isBetween(selectedVisit.scheduleStart, selectedVisit.scheduleEnd, null, '[]') &&
                                            dayjs(selectedVisit.scheduleStart).isBefore(dayjs(), 'day')))
                                    ? true
                                    : false
                            }

                        >
                            Resend Invitation
                        </OpButton>
                    </OpCol>
                </OpRow>
                <OpRow gutter={16}>
                    <OpCol span={12}>
                        <OpForm.Input readOnly label="Sign In Time" name="signIn" />
                    </OpCol>
                    <OpCol span={12}>
                        <OpForm.Input readOnly label="Sign Out Time" name="signOut" />
                    </OpCol>
                </OpRow>
                {(connectedKiosks.length > 0 && selectedVisitor?.status !== STATUS.DENIED_ENTRY.id) && (
                    <OpButton
                        onClick={() => setBadgePrintModalOpen(true)}
                        style={{ marginTop: '10px', width: '30%' }}
                    >
                        Print Badge
                    </OpButton>
                )}
            </OpForm >

            {(badgePrintModalOpen) && (
                <OpModal
                    title="Select Kiosk to Print Badge"
                    open={badgePrintModalOpen}
                    onOk={() => { printBadgeForm.submit() }}
                    onCancel={() => setBadgePrintModalOpen(false)}
                    okButtonProps={{ disabled: isSubmitting }}
                    cancelButtonProps={{ disabled: isSubmitting }}
                >
                    <OpDivider />

                    <OpForm
                        form={printBadgeForm}
                        initialValues={{ kioskSelect: connectedKiosks.length > 0 ? connectedKiosks[0].id : undefined }}
                        onSubmit={handlePrintBadge}
                        hasError={false}
                        defaultButtons={false}
                    >
                        <OpForm.Select
                            name="kioskSelect"
                            options={connectedKiosks.map(kiosk => ({ label: kiosk.label, value: kiosk.id }))}
                        />
                    </OpForm>
                </OpModal>
            )}

            {(isVisitorPhotoModalOpen) && (
                <OpModal
                    open={isVisitorPhotoModalOpen}
                    title="Visitor Photo"
                    centered
                    onCancel={() => setIsVisitorPhotoModalVisible(false)}  // This handles closing when "X" is clicked
                    onClose={() => setIsVisitorPhotoModalVisible(false)}   // This handles closing with custom buttons
                    footer={[
                        <OpButton key="close" type="primary" onClick={() => setIsVisitorPhotoModalVisible(false)}>
                            Close
                        </OpButton>
                    ]}
                >
                    {visitorPhotoUrl && <img src={visitorPhotoUrl} alt="Visitor" style={{ width: '100%' }} />}
                </OpModal>
            )}

            {/* Denied Entry Details Modal */}
            {
                isDeniedEntryModalOpen && (
                    <OpModal
                        open={isDeniedEntryModalOpen}
                        title="Denied Entry Details"
                        centered
                        onCancel={() => setIsDeniedEntryModalOpen(false)}
                        footer={[
                            <OpButton key="close" type="primary" onClick={() => setIsDeniedEntryModalOpen(false)}>
                                Close
                            </OpButton>,
                        ]}
                    >
                        <List
                            bordered
                            dataSource={[
                                { title: "Reason for Denial:", value: denyDetails?.reason?.name || "---" },
                                { title: "Effective Date:", value: denyDetails?.deniedAt ? dayjs(denyDetails?.deniedAt).utc(true).local().format(DATE_TIME_AM_PM_FORMAT) : "---" },
                            ]}
                            renderItem={(item) => (
                                <List.Item>
                                    <strong>{item.title}</strong> <span>{item.value}</span>
                                </List.Item>
                            )}
                        />
                    </OpModal>
                )
            }

        </>
    );
};

export default ProfileTab;
