import React, { useState, useEffect, useRef } from "react";
import { Spin, notification } from "antd";
import { OpDrawer } from "components/customAntd/DLS/OpDrawer/OpDrawer";
import { OpForm } from "components/customAntd/DLS/OpForm/OpForm";
import { OpTabs } from "components/customAntd/DLS/OpTabs/OpTabs";
import KioskContent from "./tabs/KiosksContent";
import OptionsContent from "./tabs/OptionsContent";
import AppearanceContent from "./tabs/AppearanceContent";
import ConnectionContent from "./tabs/ConnectionContent";
import { useConfirmModal } from "utils/customHooks/useConfirmModal";
import { MultiFormButtons } from "components/customAntd/MultiFormButtons";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "store/store";
import { LoadingOutlined } from '@ant-design/icons';
import { hasPermission } from "utils/utils";
import { DRAWER_WIDTH } from "constants/ui";
import { logUserActivity } from "store/slices/userActivitySlice";
import { getRequest } from "api/apiClient";
import { Location } from "types/locationTypes";
import { fetchLocations } from "store/slices/locationsSlice";

interface KiosksDrawerProps {
    open: boolean;
    onClose: () => void;
}

const KiosksDrawer: React.FC<KiosksDrawerProps> = ({ open, onClose }) => {
    const dispatch: AppDispatch = useDispatch();
    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);
    const globalUserId = useSelector((state: RootState) => state.users.globalUser?.id);
    const { locations } = useSelector((state: RootState) => state.locations);
    const { selectedKiosk, describeKioskLoading } = useSelector((state: RootState) => state.kiosks);

    const [activeKey, setActiveKey] = useState<string>('kiosk');

    const [kioskForm] = OpForm.useForm();
    const [optionsForm] = OpForm.useForm();
    const [appearanceForm] = OpForm.useForm();

    const tokenScopeList = useSelector((state: RootState) => state.auth.auth.data[0]?.tokenScopeList || []);
    const hasKioskWrite = hasPermission(tokenScopeList, orgId, 'o', 'kiosk:w');

    const defaultLocationId = useRef<number | undefined>();
    const [kioskLocations, setKioskLocations] = useState<Location[]>([]);
    const [isMultiLocationsOn, setIsMultiLocationsOn] = useState(false);
    const [isMultiLocationsOnInitially, setIsMultiLocationsOnInitially] = useState(false);

    // Add state for tracking if fields are touched
    const [isFieldsTouched, setIsFieldsTouched] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (orgId && locations.length === 0) {
                    dispatch(fetchLocations({ orgId, status: 1 }));
                }
                defaultLocationId.current = selectedKiosk?.site.id;

                const kioskLocations = await getRequest(`/orgs/${orgId}/kiosk/${selectedKiosk?.id}/sites`);
                setKioskLocations(kioskLocations.data);
                if (kioskLocations.data.length > 0) {
                    setIsMultiLocationsOn(true);
                    setIsMultiLocationsOnInitially(true);
                    optionsForm.setFieldsValue({ allowMultiLocation: true });
                } else {
                    setIsMultiLocationsOn(false);
                    setIsMultiLocationsOnInitially(false);
                    optionsForm.setFieldsValue({ allowMultiLocation: false });
                }

            } catch (error) {
                console.log("Failed to fetch locations.");
            }
        };
        fetchData();
    }, [dispatch, locations.length, orgId, selectedKiosk?.id, optionsForm, selectedKiosk?.site.id]);

    useEffect(() => {
        if (open) {
            setActiveKey('kiosk');
        }
    }, [open]);

    const handleSubmitAllForms = async () => {
        const isKioskFormTouched = kioskForm.isFieldsTouched();
        const isOptionsFormTouched = optionsForm.isFieldsTouched();
        const isAppearanceFormTouched = appearanceForm.isFieldsTouched();

        try {
            if (isKioskFormTouched) {
                await kioskForm.validateFields();
                await kioskForm.submit();
            }
            if (isOptionsFormTouched || isFieldsTouched) {
                await optionsForm.validateFields();
                await optionsForm.submit();
            }
            if (isAppearanceFormTouched) {
                await appearanceForm.validateFields();
                await appearanceForm.submit();
            }
            if (isKioskFormTouched || isOptionsFormTouched || isAppearanceFormTouched || isFieldsTouched) {
                dispatch(logUserActivity({ orgId, userActivity: { userId: globalUserId!, activityId: "55", details: kioskForm.getFieldsValue().name || '' } }));
                notification.success({
                    message: 'Success',
                    description: 'Kiosk updated successfully.',
                    placement: 'bottomRight',
                });
            }
            onClose(); // Close the drawer after successful update
        } catch (error) {
            notification.error({
                message: 'Error',
                description: 'Failed to save kiosk.',
                placement: 'bottomRight',
            });
        }
    };

    const items = [
        {
            key: 'kiosk',
            label: 'Kiosk',
            children: <KioskContent kioskForm={kioskForm} optionsForm={optionsForm} setDefaultLocationId={(id) => (defaultLocationId.current = id)} />,
        },
        {
            key: 'options',
            label: 'Options',
            children: <OptionsContent optionsForm={optionsForm} defaultLocationId={defaultLocationId.current} locations={locations} kioskLocations={kioskLocations} isMultiLocationsOn={isMultiLocationsOn} isMultiLocationsOnInitially={isMultiLocationsOnInitially} setIsMultiLocationsOn={setIsMultiLocationsOn} setIsFieldsTouched={setIsFieldsTouched} />,
        },
        {
            key: 'appearance',
            label: 'Appearance',
            children: <AppearanceContent appearanceForm={appearanceForm} />,
        },
        {
            key: 'connection',
            label: 'Connection',
            children: <ConnectionContent />,
        }
    ];

    const confirmModal = useConfirmModal();

    const finalOnClose = (e: React.MouseEvent | React.KeyboardEvent) => {
        if (kioskForm.isFieldsTouched() || optionsForm.isFieldsTouched() || appearanceForm.isFieldsTouched() || isFieldsTouched) {
            confirmModal({
                title: 'Confirm discard?',
                content: 'You have unsaved changes. Are you sure you wish to discard them?',
                okText: 'Yes',
                cancelText: 'No',
                onOk: () => {
                    kioskForm.resetFields();
                    optionsForm.resetFields();
                    appearanceForm.resetFields();
                    onClose?.();
                },
            });
        } else {
            onClose?.();
        }
    };

    return (
        <OpDrawer
            title={describeKioskLoading ? undefined : (`${selectedKiosk?.label}`)}
            width={DRAWER_WIDTH}
            open={open}
            onClose={finalOnClose}
            footer={
                !describeKioskLoading && hasKioskWrite && (
                    <MultiFormButtons
                        forms={[kioskForm, optionsForm, appearanceForm]}
                        submitButtonLabel="Save"
                        onSubmitClick={handleSubmitAllForms}
                        isFieldsTouched={isFieldsTouched}
                        isMultiLocationsOnInitially={isMultiLocationsOnInitially}
                        setIsMultiLocationsOn={setIsMultiLocationsOn}
                        setIsFieldsTouched={setIsFieldsTouched}
                    />
                )
            }
        >
            {describeKioskLoading ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                    <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
                </div>
            ) : (
                <OpTabs
                    activeKey={activeKey}
                    onChange={(newActiveKey: string) => {
                        setActiveKey(newActiveKey);
                    }}
                    items={items}
                />
            )}
        </OpDrawer>
    );
};

export default KiosksDrawer;
