export const replaceEmptyWithNull: (value: any) => any = (value: any) => {
  if (['', undefined, null].includes(value)) return null;

  if (Array.isArray(value)) {
    return value.map((item: any) => {
      if (typeof item === 'object' && item !== null) {
        return replaceEmptyWithNull(item); 
      }
      return item === '' ? null : item;
    });
  }

  if (typeof value === 'object' && value !== null) {
    const newObj: { [key: string]: any } = {};
    for (const key in value) {
      if (Object.prototype.hasOwnProperty.call(value, key)) {
        newObj[key] = replaceEmptyWithNull(value[key]);
      }
    }
    return newObj;
  }

  return value === '' ? null : value;
};