import React from 'react';
import { SvgWrapper, SvgWrapperProps } from 'components/customAntd/inVision - Platinum Assets/svgs/SvgWrapper';

interface LocationOutlinedSvgProps extends Omit<SvgWrapperProps, 'children'> {}

export const LocationOutlinedSvg = ({
  ...svgProps
}: LocationOutlinedSvgProps) => (
  <SvgWrapper viewBox="0 0 18 18" {...svgProps}>
    <path d="M9 0C12.584 0 15.5 2.916 15.5 6.5C15.5 12.663 9.572 17.674 9.32 17.884C9.228 17.961 9.114 18 9 18C8.886 18 8.772 17.961 8.68 17.884C8.428 17.674 2.5 12.663 2.5 6.5C2.5 2.916 5.416 0 9 0ZM9 1C5.967 1 3.5 3.467 3.5 6.5C3.5 11.413 7.78 15.707 9 16.831C10.219 15.706 14.5 11.405 14.5 6.5C14.5 3.467 12.033 1 9 1ZM9 3C10.93 3 12.5 4.57 12.5 6.5C12.5 8.43 10.93 10 9 10C7.07 10 5.5 8.43 5.5 6.5C5.5 4.57 7.07 3 9 3ZM9 4C7.622 4 6.5 5.122 6.5 6.5C6.5 7.878 7.622 9 9 9C10.378 9 11.5 7.878 11.5 6.5C11.5 5.122 10.378 4 9 4Z" />
  </SvgWrapper>
);
