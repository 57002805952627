import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'antd';
import { OpSpace } from 'components/customAntd/DLS/OpSpace/OpSpace';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store/store';
import { OpSelect } from 'components/customAntd/DLS/OpSelect/OpSelect';
import Loader from 'components/customAntd/Loader';
import { fetchLocations } from 'store/slices/locationsSlice';
import { Location } from 'types/locationTypes';

interface SelectLocationModalProps {
    open: boolean;
    onClose: () => void;
    handleLocationSelect: (locationId: number) => void;
}

const SelectLocationModal: React.FC<SelectLocationModalProps> = ({ open, onClose, handleLocationSelect }) => {
    const dispatch: AppDispatch = useDispatch();
    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);
    const globalLocationId = useSelector((state: RootState) => state.locations.globalLocation?.id);
    const { locations } = useSelector((state: RootState) => state.locations);

    const [loading, setLoading] = useState<boolean>(false);
    const [selectedLocation, setSelectedLocation] = useState<number | null>(null);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                if (orgId && locations.length === 0) {
                    dispatch(fetchLocations({ orgId, status: 1 }));
                }
                // Assign the first location if globalLocationId is -1
                if (globalLocationId === -1 && locations.length > 0) {
                    setSelectedLocation(locations[0].id); // Default to the first available location
                } else {
                    setSelectedLocation(globalLocationId || null); // Use globalLocationId if it's valid
                }
            } catch (error) {
                console.log("Failed to fetch data.");
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, [dispatch, orgId, locations, globalLocationId]);

    const handleOk = () => {
        if (selectedLocation !== null) {
            handleLocationSelect(selectedLocation); // Execute location select on OK
        }
        onClose();
    };

    return (
        <Modal
            title="Select a Location"
            open={open}
            onOk={handleOk}
            onCancel={onClose}
            centered
            footer={[
                <Button key="cancel" onClick={onClose}>
                    Cancel
                </Button>,
                <Button key="ok" type="primary" onClick={handleOk}>
                    OK
                </Button>,
            ]}
        >
            <OpSpace direction="vertical" size="large" style={{ display: 'flex', paddingTop: '16px' }}>
                {loading ? (
                    <Loader />
                ) : (
                    <OpSelect
                        value={selectedLocation !== null ? String(selectedLocation) : undefined} // Convert number to string
                        options={locations.map((location: Location) => ({
                            label: location.name,
                            value: String(location.id), // Convert number to string
                        }))}
                        onChange={(value) => setSelectedLocation(Number(value))} // Convert string to number
                        showSearch
                        loading={loading}
                        style={{ width: '100%' }}
                    />
                )}
            </OpSpace>
        </Modal>
    );
};

export default SelectLocationModal;
