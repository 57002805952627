import { ReactNode } from 'react';
import { OpInfoTooltip } from 'components/customAntd/DLS/OpInfoTooltip/OpInfoTooltip';
import './OpPage.scss';

interface OpPageProps {
  children: ReactNode | ReactNode[];
  className?: string;
  title?: string | null;
  tooltip?: string | null;
  subtitle?: string | null;
  contentRight?: ReactNode;
  testId?: string;
}

export const OpPage = ({
  children,
  className = '',
  title,
  tooltip,
  subtitle,
  contentRight,
  testId = 'op-page',
}: OpPageProps) => (
  <div className={`op-page ${className}`.trim()} data-testid={testId}>
    {(title || tooltip || subtitle || contentRight) && (
      <div className="op-page__header">
        {/* Title + Tooltip + Subtitle */}
        <div className="op-page__header-left">
          <div className="op-page__title-row">
            {title && <h1 className="op-page__title">{title}</h1>}
            {tooltip && <OpInfoTooltip title={tooltip} />}
          </div>
          {subtitle && <div className="op-page__subtitle">{subtitle}</div>}
        </div>

        {/* Right Content (Moves Below in Small Screens) */}
        {contentRight && <div className="op-page__content-right">{contentRight}</div>}
      </div>
    )}
    {children}
  </div>
);
