import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';
import dayjs from 'dayjs';
import { VisitorSearch } from 'types/visitorSearchTypes';

interface VisitorsTrendChartProps {
    visitors: VisitorSearch[];
}

const VisitorsTrendChart: React.FC<VisitorsTrendChartProps> = ({ visitors }) => {
    const labels = [...Array(7)].map((_, i) => dayjs().subtract(i, 'days').format('M/D')).reverse();

    const dataCounts = labels.map(label => {
        return visitors.filter(visitor => dayjs(visitor.timestamp).format('M/D') === label).length;
    });

    const data = {
        labels,
        datasets: [
            {
                label: 'Visitors',
                data: dataCounts,
                backgroundColor: 'rgba(54, 162, 235, 0.2)',
                borderColor: 'rgba(54, 162, 235, 1)',
                borderWidth: 1,
            },
        ],
    };

    const [labelColor, setLabelColor] = useState(getComputedStyle(document.documentElement).getPropertyValue('--colorTextBase'));
    const [gridColor, setGridColor] = useState(getComputedStyle(document.documentElement).getPropertyValue('--colorBorderSecondary'));

    useEffect(() => {
        const handleThemeChange = () => {
            setLabelColor(getComputedStyle(document.documentElement).getPropertyValue('--colorTextBase'));
            setGridColor(getComputedStyle(document.documentElement).getPropertyValue('--colorBorderSecondary'));
        };

        // Listen for changes to the theme
        window.addEventListener('themechange', handleThemeChange);

        // Initial set
        handleThemeChange();

        return () => {
            window.removeEventListener('themechange', handleThemeChange);
        };
    }, []);

    const options = {
        maintainAspectRatio: false,
        scales: {
            y: {
                beginAtZero: true,
                ticks: {
                    stepSize: 1,
                    color: labelColor,
                },
                grid: {
                    color: gridColor,
                },
            },
            x: {
                ticks: {
                    color: labelColor,
                },
                grid: {
                    color: gridColor,
                },
            },
        },
        plugins: {
            legend: {
                display: false,
            },
        },
    };

    return (
        <div style={{ position: 'relative', width: '100%', height: '280px' }}>
            <Bar data={data} options={options} />
        </div>
    );
}

export default VisitorsTrendChart;
