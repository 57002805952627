import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { OpPage } from 'components/customAntd/OpPage/OpPage';
import LocationsDrawer from './LocationsDrawer';
import LocationsTable from './LocationsTable';
import { LOCATION_TOOLTIP } from 'constants/tooltip';
import { AppDispatch, RootState } from 'store/store';
import { Location } from 'types/locationTypes';
import { fetchLocations } from 'store/slices/locationsSlice';


const Locations: React.FC = () => {
    const dispatch: AppDispatch = useDispatch();

    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);
    const [drawerVisible, setDrawerVisible] = useState(false);
    const [selectedLocation, setSelectedLocation] = useState<Location | null>(null);

    // Fetch locations
    useEffect(() => {
        dispatch(fetchLocations({ orgId, status: 1 }));
    }, [dispatch, orgId]);

    const handleEditClick = (location: Location) => {
        setSelectedLocation(location);
        setDrawerVisible(true);
    };

    const handleAddClick = () => {
        setSelectedLocation(null);
        setDrawerVisible(true);
    };

    const handleDrawerClose = () => {
        setDrawerVisible(false);
        setSelectedLocation(null);
    };

    return (
        <OpPage title="Locations" tooltip={LOCATION_TOOLTIP}>
            <LocationsTable
                handleEditClick={handleEditClick}
                handleAddClick={handleAddClick}
            />
            <LocationsDrawer
                open={drawerVisible}
                onClose={handleDrawerClose}
                selectedLocation={selectedLocation}
            />
        </OpPage>
    );
}

export default Locations;
