import React, { useState, useCallback, useRef, useEffect } from 'react';
import { Modal, Spin, Alert, notification } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useEvent } from 'react-use';
import idBarcode from 'images/idBack.png';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store/store';
import { formatFullName } from 'utils/utils';
import STATUS from 'constants/status';
import dayjs from 'dayjs';
import { setSelectedVisit } from 'store/slices/visitsSlice';
import { VisitorSearch } from 'types/visitorSearchTypes';
import { getRequest } from 'api/apiClient';

interface ScanIdComponentProps {
    open: boolean;
    visitorsToday: VisitorSearch[];
    onClose: () => void;
    openVisitorsDrawer: () => void;
    openSelectVisitorsModal: () => void;
    openSignInManuallyDrawer: () => void;
    setVisitorNameFilter: (filter: { firstName: string; middleName: string; lastName: string; } | undefined) => void;
    setNewVisitorName: (filter: { firstName: string; middleName: string; lastName: string; dob?: string } | undefined) => void;
}

interface OptionType {
    value: string;
    label: string;
    key: string;
}

const ScanIDModal: React.FC<ScanIdComponentProps> = ({
    open,
    visitorsToday,
    onClose,
    openVisitorsDrawer,
    openSelectVisitorsModal,
    openSignInManuallyDrawer,
    setVisitorNameFilter,
    setNewVisitorName,
}) => {
    const dispatch: AppDispatch = useDispatch();
    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);

    const scannedDataRef = useRef('');
    const [scanningInProgress, setScanningInProgress] = useState(false);
    const [isKeyPressed, setIsKeyPressed] = useState(false);

    const timerRef = useRef<NodeJS.Timeout | null>(null); // Timer to detect the end of scanning

    useEvent('keydown', scanningInProgress ? (event: Event) => handleKeyDown(event as KeyboardEvent) : null, document);

    useEffect(() => {
        if (open) {
            setScanningInProgress(true);
        } else {
            setScanningInProgress(false);
            setIsKeyPressed(false);
            scannedDataRef.current = '';
            if (timerRef.current) {
                clearTimeout(timerRef.current);
                timerRef.current = null;
            }
        }
    }, [open]);

    const parseDriverLicense = useCallback((data: string) => {
        const elementIds = [
            'DCA', 'DCB', 'DCD', 'DBA', 'DCS', 'DAC', 'DAD', 'DBD', 'DBB', 'DBC', 'DAY', 'DAU', 'DAG', 'DAI', 'DAJ', 'DAK', 'DAQ', 'DCF', 'DCG', 'DDE', 'DDF', 'DDG',
        ];

        const extractField = (data: string, field: string) => {
            const regex = new RegExp(`${field}(.*?)(?=${elementIds.map((id) => `(?:${id})`).join('|')}|$)`);
            const match = data.match(regex);
            return match ? match[1]?.trim() : 'NONE';
        };

        const lastName = extractField(data, 'DCS');
        const firstName = extractField(data, 'DAC');
        const middleName = extractField(data, 'DAD');
        const dobRaw = extractField(data, 'DBB');
        const dob = dobRaw !== 'NONE'
            ? dayjs(dobRaw, 'MMDDYYYY').format('YYYY-MM-DD')
            : 'NONE';

        return {
            lastName,
            firstName,
            middleName,
            dob
        };
    }, []);

    const handleVisitorOptions = useCallback(async (parsedNames: { firstName: string; middleName: string; lastName: string }) => {
        const filteredVisits = visitorsToday.filter(
            (visitor) =>
                dayjs(visitor.timestamp).isSame(dayjs(), 'day') && visitor.status === STATUS.PENDING.id
        );

        const newOptions: OptionType[] = filteredVisits
            .filter((visitor) => {
                const firstNameMatch = parsedNames.firstName === 'NONE' || visitor.firstName?.toLowerCase() === parsedNames.firstName.toLowerCase();
                const lastNameMatch = parsedNames.lastName === 'NONE' || visitor.lastName?.toLowerCase() === parsedNames.lastName.toLowerCase();
                return firstNameMatch && lastNameMatch;
            })
            .map((visitor) => ({
                value: `${visitor.visitId}-${visitor.id}`,
                label: formatFullName(visitor.firstName, visitor.middleName, visitor.lastName),
                key: `${visitor.visitId}-${visitor.id}`,
            }));
        if (newOptions.length === 0) {
            notification.warning({
                message: 'No Matched Visitor',
                description: 'No matching pre-registered visitor was found.',
                placement: 'bottomRight',
            });
            setNewVisitorName(parsedNames);
            openSignInManuallyDrawer();
        } else if (newOptions.length === 1) {
            const [visitId] = newOptions[0].value.split('-');
            const selectedVisit = await getRequest(`/orgs/${orgId}/visitor/${visitId}`);
            dispatch(setSelectedVisit(selectedVisit.data[0]));
            openVisitorsDrawer();
        } else if (newOptions.length > 1) {
            openSelectVisitorsModal();
        }
    }, [orgId, visitorsToday, dispatch, openVisitorsDrawer, openSelectVisitorsModal, openSignInManuallyDrawer, setNewVisitorName]);

    const handleScannedData = useCallback((data: string) => {
        console.log('Scanned Data:', data);

        if (data.startsWith('@ANSI') || data.startsWith('@AAMVA')) {
            const cleanedData = data.replace(/Shift/g, '');
            const extractedData = parseDriverLicense(cleanedData);
            if (extractedData.lastName !== 'NONE' || extractedData.firstName !== 'NONE' || extractedData.middleName !== 'NONE') {
                console.log(`Last Name: ${extractedData.lastName}`);
                console.log(`First Name: ${extractedData.firstName}`);
                console.log(`Middle Name: ${extractedData.middleName}`);
                setVisitorNameFilter(extractedData);
                handleVisitorOptions(extractedData);
            } else {
                notification.error({
                    message: 'Error',
                    description: 'Could not scan the driver license. Please try again.',
                    placement: 'bottomRight',
                });
            }
        } else {
            notification.error({
                message: 'Invalid Barcode',
                description: 'The scanned data does not match a valid driver license format.',
                placement: 'bottomRight',
            });
        }
    }, [parseDriverLicense, handleVisitorOptions, setVisitorNameFilter]);

    const handleKeyDown = useCallback((event: KeyboardEvent) => {
        setIsKeyPressed(true);

        if (event.key.length === 1) {
            scannedDataRef.current += event.key;
        }

        // 1초 동안 추가 입력이 없으면 스캔 종료
        if (timerRef.current) {
            clearTimeout(timerRef.current);
        }

        timerRef.current = setTimeout(() => {
            setScanningInProgress(false);
            setIsKeyPressed(false);
            handleScannedData(scannedDataRef.current);
            scannedDataRef.current = '';
            onClose();
        }, 1000);
    }, [onClose, handleScannedData]);

    return (
        <Modal
            title="Scan ID to Sign In Visitor"
            open={open}
            onOk={onClose}
            onCancel={() => {
                scannedDataRef.current = '';
                setScanningInProgress(false);
                setIsKeyPressed(false);
                if (timerRef.current) {
                    clearTimeout(timerRef.current);
                    timerRef.current = null;
                }
                onClose();
            }}
            okText="Confirm"
            cancelText="Cancel"
            centered
            style={{ textAlign: 'center' }}
            footer={null}
        >
            <p>
                <img src={idBarcode} alt="Barcode ID" style={{ width: '250px' }} />
            </p>
            {scanningInProgress ? <Spin indicator={<LoadingOutlined spin />} size="default" style={{ padding: '20px' }} /> : undefined}
            {isKeyPressed ? <Alert message="Processing..." type="success" /> : <Alert message="Waiting to Scan ID" type="info" />}
        </Modal>
    );
};

export default ScanIDModal;