import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ThemeState {
    isDarkMode: boolean;
    isMatchSystemMode: boolean;
}

const initialState: ThemeState = {
    isDarkMode: true,
    isMatchSystemMode: false,
};

const themeSlice = createSlice({
    name: 'theme',
    initialState,
    reducers: {
        setDarkMode(state, action: PayloadAction<boolean>) {
            state.isDarkMode = action.payload;
        },
        setMatchSystemMode(state, action: PayloadAction<boolean>) {
            state.isMatchSystemMode = action.payload;
        },
    },
});

export const { setDarkMode, setMatchSystemMode } = themeSlice.actions;

export default themeSlice.reducer;
