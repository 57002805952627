import React from 'react';
import { Layout } from 'antd';
import AppRoutes from "./AppRoutes";

const { Content } = Layout;

const PageContent: React.FC = () => {
    return (
        <Content className="op-content-container">
            <AppRoutes />
        </Content>
    );
}

export default PageContent;
