import React, { useEffect, useRef, useState } from 'react';
import { Layout, Spin } from 'antd';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import './GuestPass.scss';
import { OpButton } from 'components/customAntd/DLS/OpButton/OpButton';
import { formatFullName } from 'utils/utils';
import PinModal from './PinModal';
import { DATE_TIME_FORMAT } from 'constants/dates';

const { Content } = Layout;
const apiUrl = process.env.REACT_APP_BACKEND_URL;
const TOKEN_REFRESH_INTERVAL = 10 * 60 * 500; // Refresh token every 5 minutes

interface Entry {
    id: number;
    name: string;
    isOpened: boolean; // To track if it's opened locally
    isFailed: boolean;
}

const GuestPass: React.FC = () => {
    const [isValid, setIsValid] = useState<boolean>(false);
    const [guestPass, setGuestPass] = useState<any>({});
    const [integration, setIntegration] = useState<any>({});
    const [msiAltaEntries, setMsiAltaEntries] = useState<Entry[]>([]);
    const [visitors, setVisitors] = useState<any>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [buttonLoading, setButtonLoading] = useState<{ [key: number]: boolean }>({}); // Track loading for each button
    const [currentEntryId, setCurrentEntryId] = useState<number | null>(null);
    const [pinModalOpen, setPinModalOpen] = useState<boolean>(false);
    const { token } = useParams();
    const integrationRef = useRef(integration);
    const guestPassRef = useRef(guestPass);
    const visitorsRef = useRef(visitors);

    useEffect(() => {
        integrationRef.current = integration;
        guestPassRef.current = guestPass;
        visitorsRef.current = visitors;
    }, [integration, guestPass, visitors])

    useEffect(() => {
        const fetchGuestPass = async () => {
            try {
                setIsLoading(true);
                if (token) {
                    const response = await axios.get(`${apiUrl}/guestPass/${token}`);
                    const guestPassData = response.data.data[0].guestPass[0];
                    const integrationData = response.data.data[0].integration[0];
                    const entries = guestPassData.msiAltaEntryId;

                    setGuestPass(guestPassData);
                    setIntegration(integrationData);
                    setVisitors(guestPassData.visitor);

                    const entryList: Entry[] = Array.from(
                        new Map<number, Entry>(
                            entries.map((entry: any) => [
                                entry.id,
                                { id: entry.id, name: entry.name, isOpened: false, isFailed: false }
                            ])
                        ).values()
                    );

                    setMsiAltaEntries(entryList);

                    if (guestPassData.startUtc && guestPassData.endUtc) {
                        const now = dayjs();
                        setIsValid(
                            now.isAfter(dayjs(guestPassData.startUtc).utc(true).local()) &&
                            now.isBefore(dayjs(guestPassData.endUtc).utc(true).local())
                        );
                    } else {
                        setIsValid(false);
                    }
                }
            } catch (error) {
                console.error('Error fetching guest pass:', error);
                setIsValid(false);
            } finally {
                setIsLoading(false);
            }
        };

        fetchGuestPass();

        const intervalId = setInterval(() => {
            fetchGuestPass();
        }, TOKEN_REFRESH_INTERVAL);

        return () => clearInterval(intervalId);
    }, [token]);

    const formatVisitorNames = () => {
        if (visitors.length === 0) return '';
        const firstVisitorName = formatFullName(visitors[0].firstName, visitors[0].middleName, visitors[0].lastName);

        if (visitors.length === 1) {
            return `Visitor: ${firstVisitorName}`;
        } else {
            const additionalVisitorsCount = visitors.length - 1;
            return `Visitor: ${firstVisitorName} (+${additionalVisitorsCount})`;
        }
    };

    // Handler to trigger the open entry and update the status
    const executeOpenEntry = async (entryId: number) => {
        setButtonLoading((prev) => ({ ...prev, [entryId]: true }));

        try {
            const response = await axios.get(`${apiUrl}/guestPass/${token}`);
            const guestPassData = response.data.data[0].guestPass[0];
            const integrationData = response.data.data[0].integration[0];

            setGuestPass(guestPassData);
            setIntegration(integrationData);
            guestPassRef.current = guestPassData;
            integrationRef.current = integrationData;

            if (guestPassData.startUtc && guestPassData.endUtc) {
                const now = dayjs();
                setIsValid(
                    now.isAfter(dayjs(guestPassData.startUtc).utc(true).local()) &&
                    now.isBefore(dayjs(guestPassData.endUtc).utc(true).local())
                );
            } else {
                setIsValid(false);
            }
        } catch (error) {
            console.error('Error refreshing guest pass data:', error);
            setIsValid(false);
        }

        if (!isValid) {
            console.error("Guest pass is expired or invalid.");
            setMsiAltaEntries((prevEntries) =>
                prevEntries.map((entry) =>
                    entry.id === entryId ? { ...entry, isFailed: true } : entry
                )
            );
            setButtonLoading((prev) => ({ ...prev, [entryId]: false }));
            return;
        }

        let ipAddress = 'UNKNOWN';

        try {
            const ipResponse = await Promise.race([
                axios.get<{ ip: string }>('https://api.ipify.org?format=json'),
                new Promise((_, reject) => setTimeout(() => reject(new Error('Timeout')), 5000)),
            ]);
            ipAddress = (ipResponse as any).data.ip;
        } catch (ipError) {
            console.error('Failed to fetch IP address:', ipError);
        }

        let success = false;
        let attempt = 0;
        const maxAttempts = 2;
        let currentToken = integrationRef.current.msiAltaToken;

        while (attempt < maxAttempts && !success) {
            try {
                const response = await axios.post(
                    `https://api.openpath.com/orgs/${integrationRef.current.msiAltaOrgId}/users/${integrationRef.current.msiAltaUserId}/credentials/${guestPassRef.current.msiAltaCredentialId}/cloudKeyEntryUnlock`,
                    {
                        entryId: entryId,
                        description: formatVisitorNames(),
                    },
                    {
                        headers: {
                            Authorization: currentToken
                        }
                    }
                );

                if ([200, 204].includes(response.status)) {
                    success = true;
                }
            } catch (error: any) {
                if (error.response?.status === 401 && attempt === 0) {
                    try {
                        const refreshResponse = await axios.get(`${apiUrl}/guestPass/${token}`);
                        const newIntegration = refreshResponse.data.data[0].integration[0];
                        setIntegration(newIntegration);
                        integrationRef.current = newIntegration;
                        currentToken = newIntegration.msiAltaToken;
                        attempt++;
                    } catch (refreshError) {
                        console.error('Failed to refresh token:', refreshError);
                        break;
                    }
                } else {
                    break;
                }
            }
        }

        // Log result and update UI state
        try {
            await axios.post(`${apiUrl}/guestPass/${token}/log`, {
                entryId: entryId,
                ipAddress: ipAddress,
                success: success ? 1 : 0
            });
        } catch (logError) {
            success = false;
            console.error('Failed to log unlock attempt:', logError);
        }

        // Update entry state
        setMsiAltaEntries((prevEntries) =>
            prevEntries.map((entry) =>
                entry.id === entryId
                    ? { ...entry, isOpened: success, isFailed: !success }
                    : entry
            )
        );

        // Reset state after 3 seconds
        setTimeout(() => {
            setMsiAltaEntries((prevEntries) =>
                prevEntries.map((entry) =>
                    entry.id === entryId
                        ? { ...entry, isOpened: false, isFailed: false }
                        : entry
                )
            );
        }, 3000);

        // Handle auto sign-in
        if (success && guestPassRef.current.autoSignIn === 1 && visitorsRef.current.length > 0) {
            try {
                await axios.patch(`${apiUrl}/guestPass/${token}/signin`, {
                    signIn: dayjs().format(DATE_TIME_FORMAT),
                });
            } catch (signInError) {
                console.error("Failed to auto sign-in visitor:", signInError);
            }
        }

        // Final error state update if all attempts failed
        if (!success) {
            setMsiAltaEntries((prevEntries) =>
                prevEntries.map((entry) =>
                    entry.id === entryId ? { ...entry, isFailed: true } : entry
                )
            );
        }

        setButtonLoading((prev) => ({ ...prev, [entryId]: false }));
    };

    const handleOpenEntry = (entryId: number) => {
        if (guestPass.PIN !== null && guestPass.PIN !== "") {
            setCurrentEntryId(entryId);
            setPinModalOpen(true);
        } else {
            executeOpenEntry(entryId);
        }
    };

    const validatePin = async (pin: string): Promise<boolean> => {
        try {
            if (guestPass.PIN === pin && currentEntryId !== null) {
                setPinModalOpen(false);
                await executeOpenEntry(currentEntryId);
                return true;
            } else {
                return false;
            }
        } catch (error) {
            console.error('Error validating PIN:', error);
            return false;
        }
    };

    return (
        <>
            <Layout className="guest-pass-layout">
                <Content className="guest-pass-content">
                    <img src="/images/invisit_main.png" alt="Logo" className="logo" />
                    {isLoading ? (
                        <div className="loading-container">
                            <Spin />
                        </div>
                    ) : isValid ? (
                        <>
                            <div className="guest-pass-header">
                                <p>Hello! You’ve arrived at this page because someone has shared a Guest Access link with you, allowing you to unlock an entry, controlled by Avigilon Alta, by clicking the button(s) below.</p>

                                <p><strong>Guest Pass Expiration: {dayjs(guestPass.endUtc).utc(true).local().format('YYYY-MM-DD h:mm A')}</strong></p>

                                <p>Please note that all unlock activity will be logged and visible to the administrators of the InVisit account and the Avigilon Alta account that owns the entry.</p>
                            </div>

                            {/* Render the list of buttons */}
                            <div className="entry-buttons">
                                {msiAltaEntries.map((entry) => (
                                    <OpButton
                                        key={entry.id}
                                        onClick={() => handleOpenEntry(entry.id)}
                                        loading={buttonLoading[entry.id]}
                                        className={entry.isOpened ? 'opened' : entry.isFailed ? 'failed' : ''}
                                    >
                                        {buttonLoading[entry.id]
                                            ? 'Requesting...'
                                            : entry.isOpened
                                                ? `OPENED: ${entry.name}`
                                                : entry.isFailed
                                                    ? `FAILED: ${entry.name}`
                                                    : `OPEN: ${entry.name}`}
                                    </OpButton>
                                ))}
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="message-box">
                                <span>Invalid or expired link.</span>
                            </div>
                            <div className="expiration-message">
                                <span>Please make sure you are within the valid period to access the entries.</span>
                            </div>
                        </>
                    )}
                </Content>

                <footer className="guest-pass-footer">
                    &copy; {new Date().getFullYear()} Invisit, LLC
                </footer>
            </Layout>

            {(pinModalOpen) && (
                <PinModal
                    open={pinModalOpen}
                    onClose={() => setPinModalOpen(false)}
                    onSubmit={validatePin}
                />
            )}
        </>
    );
};

export default GuestPass;
