import { OpDrawer } from "components/customAntd/DLS/OpDrawer/OpDrawer";
import { OpForm } from "components/customAntd/DLS/OpForm/OpForm";
import { OpTabs } from "components/customAntd/DLS/OpTabs/OpTabs";
import { MultiFormButtons } from "components/customAntd/MultiFormButtons";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState, AppDispatch } from "store/store";
import { Spin, notification } from "antd";
import { useConfirmModal } from "utils/customHooks/useConfirmModal"
import { DRAWER_WIDTH } from "constants/ui";
import { clearSelectedVisitorWorkflow } from "store/slices/visitorWorkflowsSlice";
import WorkflowContent from "./tabs/WorkflowContent";
import FieldsContent from "./tabs/FieldsContent";
import AgreementsContent from "./tabs/AgreementsContent";
import { LoadingOutlined } from '@ant-design/icons';
import { hasPermission } from "utils/utils";
import { logUserActivity } from "store/slices/userActivitySlice";

interface AttendantSignInDrawerProps {
    open: boolean;
    onClose: () => void;
}

const KioskSignInDrawer: React.FC<AttendantSignInDrawerProps> = ({ open, onClose }) => {
    const dispatch: AppDispatch = useDispatch();
    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);
    const globalUserId = useSelector((state: RootState) => state.users.globalUser?.id);

    const { selectedVisitorWorkflow, describeVisitorWorkflowLoading } = useSelector((state: RootState) => state.visitorWorkflows);

    const [activeKey, setActiveKey] = useState<string>('workflow');

    const [workflowForm] = OpForm.useForm();
    const [fieldsForm] = OpForm.useForm();
    const [agreementsForm] = OpForm.useForm();
    const tokenScopeList = useSelector((state: RootState) => state.auth.auth.data[0]?.tokenScopeList || []);
    const hasSigninconfWrite = hasPermission(tokenScopeList, orgId, 'o', 'signinconf:w');

    const confirmModal = useConfirmModal();

    useEffect(() => {
        if (open) {
            setActiveKey('workflow');
            // Reset forms whenever the drawer opens to ensure they start fresh
            workflowForm.resetFields();
            fieldsForm.resetFields();
            agreementsForm.resetFields();
        }
    }, [open, agreementsForm, workflowForm, fieldsForm]);

    // Called when the save button is clicked
    const handleSubmitAllForms = async () => {
        try {
            const workflowValues = await workflowForm.validateFields();
            const fieldsValues = await fieldsForm.validateFields();
            const agreementsValues = await agreementsForm.validateFields();

            if (workflowValues && fieldsValues && agreementsValues) {
                if (workflowForm.isFieldsTouched()) {
                    await workflowForm.submit();
                }
                if (fieldsForm.isFieldsTouched()) {
                    await fieldsForm.submit();
                }
                if (agreementsForm.isFieldsTouched()) {
                    await agreementsForm.submit();
                }
                if (workflowForm.isFieldsTouched() || fieldsForm.isFieldsTouched() || agreementsForm.isFieldsTouched()) {
                    dispatch(logUserActivity({ orgId, userActivity: { userId: globalUserId!, activityId: "75", details: workflowForm.getFieldsValue().name || '' } }));
                    notification.success({
                        message: 'Success',
                        description: 'Workflow updated successfully.',
                        placement: 'bottomRight',
                    });
                }
                onClose(); // Close the drawer after successful submit
            }
        } catch (error) {
            notification.error({
                message: 'Error',
                description: 'Failed to save workflow.',
                placement: 'bottomRight',
            });
        }
    };

    // Called when we close the drawer by clicking cancel button or touching the main page
    const finalOnClose = (e: React.MouseEvent | React.KeyboardEvent) => {
        if (workflowForm.isFieldsTouched() || fieldsForm.isFieldsTouched() || agreementsForm.isFieldsTouched()) {
            confirmModal({
                title: 'Confirm discard?',
                content: 'You have unsaved changes. Are you sure you wish to discard them?',
                okText: 'Yes',
                cancelText: 'No',
                onOk: () => {
                    workflowForm.resetFields();
                    fieldsForm.resetFields();
                    agreementsForm.resetFields();
                    dispatch(clearSelectedVisitorWorkflow());
                    onClose();
                },
            });
        } else {
            dispatch(clearSelectedVisitorWorkflow());
            onClose();
        }
    };

    const tabItems = [
        {
            key: 'workflow',
            label: 'Workflow',
            children: <WorkflowContent form={workflowForm} />,
        },
        {
            key: 'fields',
            label: 'Fields',
            children: <FieldsContent form={fieldsForm} />,
        },
        {
            key: 'agreements',
            label: 'Agreements',
            children: <AgreementsContent form={agreementsForm} />,
        },
    ];

    return (
        <OpDrawer
            title={describeVisitorWorkflowLoading ? undefined : (selectedVisitorWorkflow ? `${selectedVisitorWorkflow.name}` : 'Add New Kiosk In Workflow')}
            width={DRAWER_WIDTH}
            open={open}
            onClose={finalOnClose}
            footer={
                !describeVisitorWorkflowLoading && hasSigninconfWrite && (
                    <MultiFormButtons
                        forms={[workflowForm, fieldsForm, agreementsForm]}
                        submitButtonLabel="Save"
                        onSubmitClick={handleSubmitAllForms}
                        isSubmitButtonLoading={describeVisitorWorkflowLoading}
                    />
                )
            }
        >
            {describeVisitorWorkflowLoading ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                    <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
                </div>
            ) : (
                <OpTabs
                    activeKey={activeKey}
                    onChange={(newActiveKey: string) => {
                        setActiveKey(newActiveKey);
                    }}
                    items={tabItems}
                />
            )}
        </OpDrawer>
    );
};

export default KioskSignInDrawer;