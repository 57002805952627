import { getRequest } from 'api/apiClient';
import axios from 'axios';
import { OpPage } from 'components/customAntd/OpPage/OpPage';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import AvigilonReadersTable from './AvigilonReadersTable';
import AvigilonReadersDrawer from './AvigilonReadersDrawer';


const AvigilonReaders: React.FC = () => {
    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);

    const [drawerVisible, setDrawerVisible] = useState(false);
    const [acus, setAcus] = useState<any>([]);
    const [entryIdOptions, setEntryIdOptions] = useState<{ value: number; label: string }[]>([]);
    const [integrationData, setIntegrationData] = useState<any>(null);
    const [selectedAcu, setSelectedAcu] = useState<any>(null);
    const [selectedReaderOptions, setSelectedReaderOptions] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(false);


    useEffect(() => {
        const fetchAcus = async () => {
            setLoading(true);
            try {
                const integrationData = await getRequest(`/orgs/${orgId}/integrationMsiAlta`);
                setIntegrationData(integrationData.data[0]);

                const acus = await axios.get(`https://api.openpath.com/orgs/${integrationData.data[0].msiAltaOrgId}/acus`, {
                    headers: {
                        Authorization: `${integrationData.data[0].msiAltaToken}`,
                    },
                });

                const filteredAcus = acus.data.data.filter((acu: any) => acu.acuModel && (acu.acuModel.id === 9 || acu.acuModel.id === 10));
                setAcus(filteredAcus);

                const listAllEntriesResponse = await getRequest(`/orgs/${orgId}/integrationMsiAltaEntry`);
                const defaultEntryList = listAllEntriesResponse.data
                    .map((defaultEntry: any) => ({
                        value: defaultEntry.id,
                        label: defaultEntry.name,
                    }));
                setEntryIdOptions(defaultEntryList);
            } catch (error) {
                console.log("Failed to fetch data.");
            } finally {
                setLoading(false);
            }
        };
        fetchAcus();
    }, [orgId]);

    const handleEditClick = async (acu: any) => {
        setSelectedAcu(acu);
        try {
            const readerOptions = await getRequest(`/orgs/${orgId}/msialtareader`, {
                acuId: acu.id
            });
            setSelectedReaderOptions(readerOptions.data[0]);
            setDrawerVisible(true);
        } catch {
            console.log('Failed to fetch reader options.')
        }
    };

    const handleDrawerClose = () => {
        setDrawerVisible(false);
        setSelectedAcu(null);
    };

    return (
        <OpPage title="Avigilon Readers" >
            <AvigilonReadersTable
                acus={acus}
                setAcus={setAcus}
                handleEditClick={handleEditClick}
                loading={loading}
            />
            <AvigilonReadersDrawer
                open={drawerVisible}
                onClose={handleDrawerClose}
                entryIdOptions={entryIdOptions}
                integrationData={integrationData}
                selectedAcu={selectedAcu}
                selectedReaderOptions={selectedReaderOptions}
                setAcus={setAcus}
            />
        </OpPage>
    );
}

export default AvigilonReaders;
