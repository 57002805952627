import { createSlice } from '@reduxjs/toolkit';

interface AppIntegrationState {
    updateIntegration: boolean; // Used to trigger integration updates
}

const initialState: AppIntegrationState = {
    updateIntegration: false,
};

const appIntegrationSlice = createSlice({
    name: 'appIntegration',
    initialState,
    reducers: {
        triggerAppIntegrationUpdate(state) {
            state.updateIntegration = !state.updateIntegration; // Toggles the value to notify listeners
        },
        resetAppIntegrationUpdate(state) {
            state.updateIntegration = false; // Resets the state to default
        },
    },
});

export const { triggerAppIntegrationUpdate, resetAppIntegrationUpdate } = appIntegrationSlice.actions;
export default appIntegrationSlice.reducer;
