import { useState, useEffect, useCallback } from 'react';
import { Layout } from 'antd';
import AppHeader from './appHeader/AppHeader';
import SideMenu from './sideMenu/SideMenu';
import PageContent from './pageContent/PageContent';
import ActionFloatButtons from './pageContent/ActionFloatButtons/ActionFloatButtons';

const AppLayout = () => {
    const [showSideMenu, setShowSideMenu] = useState(true);
    const [isMobile, setIsMobile] = useState(false);

    const checkMobile = useCallback(() => {
        const mobile = window.innerWidth <= 768;
        setIsMobile(mobile);

        if (!mobile && !showSideMenu) {
            setShowSideMenu(true);
        }
    }, [showSideMenu]);

    useEffect(() => {
        checkMobile();
        window.addEventListener('resize', checkMobile);
        return () => window.removeEventListener('resize', checkMobile);
    }, [checkMobile]);

    return (
        <Layout className="op-app">
            {(showSideMenu || !isMobile) && (
                <SideMenu
                    onClose={() => setShowSideMenu(false)}
                    isMobile={isMobile}
                />
            )}
            <Layout style={{
                transition: 'margin 0.3s'
            }}>
                <AppHeader
                    showSideMenu={showSideMenu}
                    onToggleMenu={() => setShowSideMenu(!showSideMenu)}
                    isMobile={isMobile}
                />
                <PageContent />
                <ActionFloatButtons />
            </Layout>
        </Layout>
    );
};

export default AppLayout;