import React, { useRef } from 'react';
import { OpForm } from 'components/customAntd/DLS/OpForm/OpForm';
import { OpModal } from 'components/customAntd/DLS/OpModal/OpModal';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store/store';
import { notification, Spin } from 'antd';
import { VisitorWorkflowFields } from 'types/visitorWorkflowTypes';
import { searchVisitors, searchVisitorsToday } from 'store/slices/visitorSearchSlice';
import dayjs from 'dayjs';
import { LoadingOutlined } from '@ant-design/icons';

interface ModalFormProps {
    open: boolean;
    isInviting: boolean;
    isCheckrInstalled: boolean;
    signInWorkflowFields?: VisitorWorkflowFields;
    visitor?: any | null;
    onOk: (data: any) => void;
    onClose: () => void;
}

const VisitorListModal: React.FC<ModalFormProps> = ({ open, isInviting, isCheckrInstalled, signInWorkflowFields, visitor, onOk, onClose }) => {
    const dispatch: AppDispatch = useDispatch();
    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);
    const lastSearchParams = useSelector((state: RootState) => state.visitorSearch.lastSearchParams);
    const globalLocationId = useSelector((state: RootState) => state.locations.globalLocation?.id);

    const { invitationConfig } = useSelector((state: RootState) => state.visitorInvitation);

    const workflowFields = signInWorkflowFields;
    const isSubmitting = useRef(false);
    const [form] = OpForm.useForm();

    const handleSubmit = async () => {
        try {
            isSubmitting.current = true;
            const values = await form.validateFields();
            const trimmedValues = Object.fromEntries(
                Object.entries(values).map(([key, value]) => [
                    key,
                    typeof value === 'string' ? value.trim() : value,
                ])
            );

            await onOk(trimmedValues);
            await dispatch(searchVisitorsToday({ orgId }));

            if (Object.keys(lastSearchParams).length !== 0) {
                await dispatch(searchVisitors({
                    ...lastSearchParams,
                    orgId,
                    siteId: lastSearchParams.siteId || globalLocationId!
                }));
            }

            form.resetFields();
            onClose();
        } catch (errorInfo) {
            notification.error({
                message: 'Validation Error',
                description: 'Please check all required fields',
                placement: 'bottomRight',
            });
        } finally {
            isSubmitting.current = false;
        }
    };

    return (
        <OpModal
            open={open}
            title={visitor ? 'Edit Visitor' : 'Add Visitor'}
            okText="Save"
            cancelText="Cancel"
            onCancel={() => {
                form.resetFields();
                onClose();
            }}
            onOk={handleSubmit}
            centered
            closable={!isSubmitting.current}
            maskClosable={!isSubmitting.current}
            footer={isSubmitting.current ? null : undefined}
        >
            {isSubmitting.current ? (
                <div style={{ textAlign: 'center', padding: '20px' }}>
                    <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
                    <div style={{ marginTop: 10, fontSize: 14, color: 'var(--colorInfoText)' }}>Loading</div>
                </div>
            ) : (
                <OpForm
                    form={form}
                    onSubmit={() => { console.log("") }}
                    // onSubmit={async ({ values }) => {
                    //     const trimmedValues = Object.fromEntries(
                    //         Object.entries(values).map(([key, value]) => [
                    //             key,
                    //             typeof value === 'string' ? value.trim() : value,
                    //         ])
                    //     );
                    //     await onOk(trimmedValues);
                    //     await dispatch(searchVisitorsToday({ orgId }));
                    //     if (Object.keys(lastSearchParams).length !== 0) {
                    //         await dispatch(searchVisitors({
                    //             ...lastSearchParams,
                    //             orgId,
                    //             siteId: lastSearchParams.siteId || globalLocationId!
                    //         }));
                    //     }
                    //     form.resetFields();
                    // }}
                    hasError={false}
                    defaultButtons={false}
                    initialValues={visitor ? visitor : undefined}
                >
                    <OpForm.Input
                        label="First Name" name="firstName"
                        rules={[{ required: true, message: 'Please enter first name.' }]}
                    />
                    <OpForm.Input
                        label="Last Name" name="lastName"
                        rules={[{ required: true, message: 'Please enter last name.' }]}
                    />

                    {(!isInviting && workflowFields?.middleName.included === 1) && (
                        <OpForm.Input
                            label="Middle Name" name="middleName"
                            rules={[
                                {
                                    required: (!isInviting && workflowFields.middleName.required === 1),
                                    message: 'Please enter middle name.'
                                }
                            ]}
                        />
                    )}
                    {((isInviting && invitationConfig?.email.included === 1) || (!isInviting && workflowFields?.email.included === 1)) && (
                        <OpForm.Input
                            label="Email"
                            name="email"
                            rules={[
                                {
                                    required: (isInviting && invitationConfig?.email.required === 1) ||
                                        (!isInviting && workflowFields?.email.required === 1),
                                    message: 'Please enter email.'
                                },
                                {
                                    type: 'email',
                                    message: 'Please enter a valid email address.'
                                }
                            ]}
                        />
                    )}

                    {(isCheckrInstalled) && (
                        <OpForm.DatePicker
                            label="Date of Birth"
                            name="dob"
                            format="YYYY-MM-DD"
                            disabledDate={(current) => current && current > dayjs().endOf('day')}
                        />
                    )}

                    {((isInviting && invitationConfig?.visitPhone.included === 1) || (!isInviting && workflowFields?.visitPhone.included === 1)) && (
                        <OpForm.Input
                            label="Mobile Phone" name="mobilePhone"
                            rules={[
                                {
                                    required: (isInviting && invitationConfig?.visitPhone.required === 1) ||
                                        (!isInviting && workflowFields?.visitPhone.required === 1),
                                    message: 'Please enter mobile phone.'
                                }
                            ]}
                        />
                    )}
                </OpForm>
            )}
        </OpModal>
    );
};

export default VisitorListModal;
