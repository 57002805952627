// HourlyDetailsModal.tsx
import React from 'react';
import dayjs from 'dayjs';
import { OpModal } from 'components/customAntd/DLS/OpModal/OpModal';
import { OpTable } from 'components/customAntd/DLS/OpTable/OpTable';
import { Space } from 'antd';
import STATUS from 'constants/status';

interface HourlyDetailsModalProps {
    visible: boolean;
    onCancel: () => void;
    visits: any[];
    selectedHour: number | null;
    initialStartDate: string;
    initialEndDate: string;
}

export const HourlyDetailsModal: React.FC<HourlyDetailsModalProps> = ({
    visible,
    onCancel,
    visits,
    selectedHour,
    initialStartDate,
    initialEndDate,
}) => {
    const modalColumns = [
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
        },
        {
            title: 'Visitors',
            dataIndex: 'count',
            key: 'count',
        },
    ];

    const getDailyVisitorTotals = () => {
        if (selectedHour === null) return [];

        const start = dayjs(initialStartDate).startOf('day');
        const end = dayjs(initialEndDate).endOf('day');
        const today = dayjs().startOf('day');
        const dailyCounts = new Map<string, number>();

        // Initialize all dates in range with 0
        let currentDate = start;
        while (currentDate.isBefore(end) || currentDate.isSame(end)) {
            const dateStr = currentDate.format('YYYY-MM-DD');
            if (currentDate.isBefore(today, 'day')) {
                dailyCounts.set(dateStr, 0);
            }
            currentDate = currentDate.add(1, 'day');
        }

        // Count visitors for the selected hour
        visits.forEach(visit => {
            visit.visitors.forEach((visitor: any) => {
                if (visitor.signIn && (visitor.status === STATUS.SIGNED_IN.id || visitor.status === STATUS.SIGNED_OUT.id)) {
                    const visitDate = dayjs(visitor.signIn);
                    const visitHour = visitDate.hour();
                    const visitDateStr = visitDate.format('YYYY-MM-DD');

                    if (visitHour === selectedHour && visitDate.isBetween(start, end, null, '[]')) {
                        dailyCounts.set(visitDateStr, (dailyCounts.get(visitDateStr) || 0) + 1);
                    }
                }
            });
        });

        return Array.from(dailyCounts.entries()).map(([date, count]) => ({
            key: date,
            date,
            count
        }))
            .sort((a, b) => dayjs(b.date).valueOf() - dayjs(a.date).valueOf());
    };

    const modalFooter = () => {
        const data = getDailyVisitorTotals();
        const total = data.reduce((sum, item) => sum + item.count, 0);
        const days = dayjs(initialEndDate).diff(initialStartDate, 'day') + 1;
        const average = days > 0 ? (total / days).toFixed(1) : 0;

        return (
            <Space style={{ padding: '8px 16px', fontWeight: 'bold' }}>
                <span>Daily Average</span>
                <span>{average}</span>
            </Space>
        );
    };

    return (
        <OpModal
            title={`${dayjs().hour(selectedHour || 0).format('h A')} - ${dayjs().hour((selectedHour || 0) + 1).format('h A')}`}
            open={visible}
            onCancel={onCancel}
            width={600}
            footer={null}
        >
            <div style={{ maxHeight: '70vh', overflowY: 'auto' }}>
                <OpTable
                    dataSource={getDailyVisitorTotals()}
                    columns={modalColumns}
                    pagination={false}
                    rowKey="date"
                    allowGlobalSearch={false}
                    allowExport={false}
                    allowShowHideColumns={false}
                    footer={modalFooter}
                />
            </div>
        </OpModal>
    );
};