import React from 'react';
import { SvgWrapper, SvgWrapperProps } from 'components/customAntd/inVision - Platinum Assets/svgs/SvgWrapper';

interface AdminFilledSvgProps extends Omit<SvgWrapperProps, 'children'> {}

export const AdminFilledSvg = ({ ...svgProps }: AdminFilledSvgProps) => (
  <SvgWrapper viewBox="0 0 19 18" {...svgProps}>
    <path d="M8.00194 0C10.7629 0 13.0019 2.239 13.0019 5C13.0019 7.761 10.7629 10 8.00194 10C5.24094 10 3.00194 7.761 3.00194 5C3.00194 2.239 5.24094 0 8.00194 0Z" />
    <path d="M9 14C9 14.702 9.12057 15.3759 9.34212 16.002H0.502244C0.224244 16.002 0.000244141 15.777 0.000244141 15.5V13.5C0.000244141 11.319 1.55924 9.498 3.62124 9.087C4.71724 10.261 6.27324 11 8.00224 11C8.71153 11 9.39184 10.8755 10.0233 10.6475C9.3772 11.6047 9 12.7583 9 14Z" />
    <path d="M18.3122 14.8839L17.7262 14.5449C17.7592 14.3679 17.7812 14.1869 17.7812 13.9999C17.7812 13.8129 17.7592 13.6319 17.7262 13.4549L18.3122 13.1159C18.5522 12.9779 18.6332 12.6719 18.4952 12.4329L17.9952 11.5669C17.8572 11.3279 17.5522 11.2459 17.3122 11.3839L16.7182 11.7269C16.4432 11.4919 16.1292 11.3069 15.7812 11.1839V10.4999C15.7812 10.2239 15.5572 9.99988 15.2812 9.99988H14.2812C14.0052 9.99988 13.7812 10.2239 13.7812 10.4999V11.1839C13.4332 11.3069 13.1192 11.4919 12.8442 11.7269L12.2502 11.3839C12.0102 11.2459 11.7052 11.3279 11.5672 11.5669L11.0672 12.4329C10.9292 12.6719 11.0102 12.9779 11.2502 13.1159L11.8362 13.4549C11.8032 13.6319 11.7812 13.8129 11.7812 13.9999C11.7812 14.1869 11.8032 14.3679 11.8362 14.5449L11.2502 14.8839C11.0102 15.0219 10.9292 15.3279 11.0672 15.5669L11.5672 16.4329C11.7052 16.6719 12.0102 16.7539 12.2502 16.6159L12.8442 16.2729C13.1192 16.5079 13.4332 16.6929 13.7812 16.8159V17.4999C13.7812 17.7759 14.0052 17.9999 14.2812 17.9999H15.2812C15.5572 17.9999 15.7812 17.7759 15.7812 17.4999V16.8159C16.1292 16.6929 16.4432 16.5079 16.7182 16.2729L17.3122 16.6159C17.5522 16.7539 17.8572 16.6719 17.9952 16.4329L18.4952 15.5669C18.6332 15.3279 18.5522 15.0219 18.3122 14.8839ZM14.7812 14.9999C14.2292 14.9999 13.7812 14.5519 13.7812 13.9999C13.7812 13.4479 14.2292 12.9999 14.7812 12.9999C15.3332 12.9999 15.7812 13.4479 15.7812 13.9999C15.7812 14.5519 15.3332 14.9999 14.7812 14.9999Z" />
  </SvgWrapper>
);
